import { groupByKey } from "../../../../utils/group";
import { HOUSTON_BRANCHES } from "../../../../utils/helper/constants";

export const createHierarchy = (data: any, hierarchy: any) => {
  const result: any = [];
  const buildLevel = (data: any, level: any) => {
    if (level >= hierarchy.length) return;
    const levelKey = hierarchy[level];
    const grouped: any = {};

    data.forEach((item: any) => {
      const key = item[levelKey];
      if (!grouped[key]) grouped[key] = [];
      grouped[key].push(item);
    });
    const definedKeys = Object.keys(grouped).filter((key) => key !== "null");
    const nullKeys = Object.keys(grouped).filter((key) => key === "null");
    if (levelKey === "District") {
      nullKeys.forEach((key) => {
        result.push({ name: key, location: levelKey });

        buildLevel(grouped[key], level + 1);
      });
      definedKeys.forEach((key) => {
        result.push({ name: key, location: levelKey });
        buildLevel(grouped[key], level + 1);
      });
    } else {
      definedKeys.forEach((key) => {
        result.push({ name: key, location: levelKey });
        buildLevel(grouped[key], level + 1);
      });
      nullKeys.forEach((key) => {
        result.push({ name: key, location: levelKey });

        buildLevel(grouped[key], level + 1);
      });
    }
  };
  buildLevel(data, 0);
  return result;
};

export const getAccessibleLocations = (
  data: any,
  accessibleBranches: string[],
  accessibleDistrict: string[],
  accessibleRegion: string[],
  accessibleSubarea: string[],
  accessibleArea: string[],
  accessibleCompany: string[]
) => {
  let accessibleLocations: string[] = [];

  if (accessibleCompany.length > 0 && accessibleCompany.includes("ALL")) {
    accessibleLocations = ["All"];
  } else {
    accessibleLocations.push(...accessibleBranches);

    // Group data by District
    if (accessibleDistrict && accessibleDistrict.length > 0) {
      const groupedDistrict: any = groupByKey(data, "District");
      accessibleDistrict.forEach((district: string) => {
        const districtData = groupedDistrict[district];
        if (districtData && district !== "null") {
          const branches: string[] = districtData?.map(
            (data: any) => data.Branch
          );
          accessibleLocations.push(district, ...branches); // Add districts, and branches
        }
      });
    }
    // Group data by Region
    if (accessibleRegion && accessibleRegion.length > 0) {
      const groupedRegion: any = groupByKey(data, "Region");
      accessibleRegion.forEach((region: string) => {
        const regionData = groupedRegion[region];
        const districts: string[] = regionData?.map(
          (data: any) => data.District
        );
        const branches: string[] = regionData?.map((data: any) => data.Branch);
        accessibleLocations.push(region, ...districts, ...branches); // Add region, districts, and branches
      });
    }

    // Group data by Subarea
    if (accessibleSubarea && accessibleSubarea.length > 0) {
      const groupedSubarea: any = groupByKey(data, "Sub Area name");
      accessibleSubarea.forEach((subarea: string) => {
        const subareaData = groupedSubarea[subarea] || [];
        const regions: string[] = subareaData.map((data: any) => data.Region);
        const districts: string[] = subareaData.map(
          (data: any) => data.District
        );
        const branches: string[] = subareaData.map((data: any) => data.Branch);
        accessibleLocations.push(
          subarea,
          ...regions,
          ...districts,
          ...branches
        );
      });
    }
    // Group data by area
    if (accessibleArea && accessibleArea.length > 0) {
      const groupedArea: any = groupByKey(data, "Areaname");
      accessibleArea.forEach((area: string) => {
        const areaData = groupedArea[area] || [];
        const subareas: string[] = areaData.map(
          (data: any) => data["Sub Area name"]
        );
        const regions: string[] = areaData.map((data: any) => data.Region);
        const districts: string[] = areaData.map((data: any) => data.District);
        const branches: string[] = areaData.map((data: any) => data.Branch);
        accessibleLocations.push(
          area,
          ...subareas,
          ...regions,
          ...districts,
          ...branches
        );
      });
    }
  }

  return Array.from(new Set(accessibleLocations));
};

export const getBranchAlignment = (branch: {
  name: string;
  location: string;
}) => {
  return HOUSTON_BRANCHES.includes(branch.name)
    ? "font-normal pl-4"
    : branch.name === "QCSA"
    ? "font-normal pl-6"
    : branch.location === "Branch"
    ? "font-normal pl-10"
    : branch.location === "District"
    ? "pl-9"
    : branch.location === "Region"
    ? "pl-6"
    : branch.location === "Sub Area name"
    ? "pl-4"
    : branch.location === "Areaname"
    ? "pl-2"
    : "";
};

export const formatExportData = (data: any, headers: any) => {
  return data.map((item: any) => {
    let formattedItem: any = {};
    if (item["items"] && item["items"].includes(":")) {
      headers.forEach((header: string) => {
        if (header === "") {
          formattedItem[header] = item["items"];
        } else {
          formattedItem[header] = null;
        }
      });
      return formattedItem;
    }
    headers.forEach((header: string) => {
      if (item.hasOwnProperty(header)) {
        formattedItem[header] =
          isNaN(item[header]) || !isFinite(item[header]) ? 0 : item[header];
      } else if (header.includes("QTR")) {
        const quarterKey = header.replace("QTR", "Quarter");
        formattedItem[header] = item[quarterKey] || 0;
      } else if (header === "") {
        formattedItem[header] = item["items"] || null;
      } else {
        formattedItem[header] = null;
      }
    });
    return formattedItem;
  });
};

export const formatChartData = (rawData: any, key: string) => {
  const data = groupByKey(rawData, key);
  let formattedChartData = {};
  Object.keys(data).forEach((v) => {
    formattedChartData = {
      ...formattedChartData,
      [v]: groupByKey(data[v], "calendaryear"),
    };
  });
  return formattedChartData;
};
