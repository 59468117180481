/*
 * Copyright (C) 2025 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import * as actions from "./actionTypes";

export const initialState = {
  districtsList: [],
  subareasList: [],
  areasList: [],
  companyList: [],
};

interface IAction {
  type: String;
  payload: any;
}

export const cgsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case actions.SET_CGS_DISTRICTS_LIST:
      return {
        ...state,
        districtsList: action.payload,
      };
    case actions.SET_CGS_SUBAREAS_LIST:
      return {
        ...state,
        subareasList: action.payload,
      };
    case actions.SET_CGS_AREAS_LIST:
      return {
        ...state,
        areasList: action.payload,
      };
    case actions.SET_CGS_COMPANY_LIST:
      return {
        ...state,
        companyList: action.payload,
      };
    default:
      return state;
  }
};
