/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect, useState } from "react";
import { TopClientsFilter } from "../components/modules/topClients/TopClientsFilter";
import { TopClientsTable } from "../components/modules/topClients/TopClientsTable";
import { Loader } from "../components/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../store/Filter/actions";
import {
  APIFetchMonthlyTopClientsWip,
  APIFetchQuarterlyTopClientsWip,
  APIFetchRolling12MonthsTopClientsWip,
  APIFetchRolling30DaysTopClientsWip,
  APIFetchRolling3MonthsTopClientsWip,
  APIFetchRolling90DaysTopClientsWip,
  APIFetchWeeklyTopClientsWip,
  APIFetchYTDThisYearTopClientsWip,
} from "../api/top-clients-wip";
import HomeButton from "../components/common/HomeButton";

const initialstate={
  timeline:"Weekly",
  area:"All",
  subarea:"All",
  region:"All",
  district:"All",
  branch:"All",
  dataMode:"GrossProfit",
  acquisition:false,
  mergeClient:false,
  referenceNote:"All"
}

export const TopClients = (props: any) => {
  const dispatch = useDispatch();
  const currentYear = useSelector(
    (state: any) => state.filterReducer.currentYear
  ).toString();
  const [selectedTimeline, setSelectedTimeline] = useState(initialstate.timeline);
  const [selectedYear, setSelectedYear] = useState<string>(currentYear);
  const [selectedWeek, setSelectedWeek] = useState<any>();
  const [selectedMonth, setSelectedMonth] = useState<any>();
  const [selectedQuarter, setSelectedQuarter] = useState<any>();
  const [selectedArea, setSelectedArea] = useState(initialstate.area);
  const [selectedSubarea, setSelectedSubarea] = useState(initialstate.subarea);
  const [selectedRegion, setSelectedRegion] = useState(initialstate.region);
  const [selectedDistrict, setSelectedDistrict] = useState(initialstate.district);
  const [selectedBranch, setSelectedBranch] = useState(initialstate.branch);
  const [selectedDatamode, setSelectedDatamode] = useState(initialstate.dataMode);
  const [topClientsData, setTopClientsData] = useState<any>([]);
  const [selectedYearMonth, setSelectedYearMonth] = useState<any>({});
  const [exportToggle, setExportToggle] = useState();
  const [acquisitionClient, setAcquisitionClient] = useState(initialstate.acquisition);
  const [mergeClient, setMergeClient] = useState(initialstate.mergeClient);
  const [selectedReferenceNote, setSelectedReferenceNote] =
    useState<string>(initialstate.referenceNote);
  const [selectedLevel, _setSelectedLevel] = useState(
    props.adaOnly ? "ADA" : "Company"
  );

  const getSelectedLocation = () => {
    if (mergeClient) return "";
    const locations = [selectedBranch, selectedDistrict, selectedRegion, selectedSubarea, selectedArea];
    return locations.find((location) => location !== "All") || "All";
  };

  const getSelectedReferenceNote = () => {
    return acquisitionClient ? selectedReferenceNote : "";
  };

  const apiMap:any = {
    "Weekly": APIFetchWeeklyTopClientsWip,
    "Monthly": APIFetchMonthlyTopClientsWip,
    "Quarterly": APIFetchQuarterlyTopClientsWip,
    "Rolling30Days": APIFetchRolling30DaysTopClientsWip,
    "LastYearRolling30Days": APIFetchRolling30DaysTopClientsWip,
    "Rolling90Days": APIFetchRolling90DaysTopClientsWip,
    "LastYearRolling90Days": APIFetchRolling90DaysTopClientsWip,
    "YTD": APIFetchYTDThisYearTopClientsWip,
    "YTDLastYear": APIFetchYTDThisYearTopClientsWip,
    "Rolling3Months": APIFetchRolling3MonthsTopClientsWip,
    "Rolling12Months": APIFetchRolling12MonthsTopClientsWip,
    "PreviousRolling3Months": APIFetchRolling3MonthsTopClientsWip,
    "PreviousRolling12Months": APIFetchRolling12MonthsTopClientsWip,
    "PreviousRolling24Months": APIFetchRolling12MonthsTopClientsWip,
  };

  const commonParams = [
    getSelectedReferenceNote(),
    getSelectedLocation(),
    selectedLevel,
    mergeClient
  ];
  
  const getParams = (timeline: string) => {
    switch (timeline) {
      case "Weekly":
      case "Rolling30Days":
      case "LastYearRolling30Days":
      case "Rolling90Days":
      case "LastYearRolling90Days":
      case "YTD":
      case "YTDLastYear":
        return selectedWeek ? [selectedWeek, ...commonParams] : null;
      case "Monthly":
        return selectedMonth ? [selectedYear + selectedMonth, ...commonParams] : null;
      case "Quarterly":
        return selectedQuarter ? [selectedYear + selectedQuarter, ...commonParams] : null;
      default:
        return selectedYearMonth[selectedTimeline]
          ? [
              selectedYearMonth[selectedTimeline].year , selectedYearMonth[selectedTimeline].month,
              ...commonParams
            ]
          : null;
    }
  };

  const fetchTopClientsData = async () => {
    if (!selectedTimeline || !selectedYear) return;
    const fetchData = async (apiCall: Function, params: any) => {
      dispatch(setLoading(true));
      const { data } = await apiCall(...params);
      data.forEach((d: any) => {
        d.GPRankSlot = d.GPPreviousRank - d.GPThisRank;
        d.RevRankSlot = d.RevPreviousRank - d.RevThisRank;
      });
      setTopClientsData([...data]);
      dispatch(setLoading(false));
    };

    const apiCall = apiMap[selectedTimeline];
    const params = getParams(selectedTimeline);
    if (apiCall && params) {
      await fetchData(apiCall, params);
    }
  };

  useEffect(() => {
    setSelectedYear(
      selectedTimeline === "YTDLastYear" ||
        selectedTimeline === "LastYearRolling30Days" ||
        selectedTimeline === "LastYearRolling90Days"
        ? (+currentYear - 1).toString()
        : currentYear
    );
  }, [selectedTimeline]);

  useEffect(() => {
    fetchTopClientsData();
  }, [
    selectedArea,
    selectedSubarea,
    selectedRegion,
    selectedDistrict,
    selectedBranch,
    selectedYear,
    selectedWeek,
    selectedMonth,
    selectedQuarter,
    selectedYearMonth,
    acquisitionClient,
    selectedReferenceNote,
    selectedTimeline,
    selectedLevel,
    mergeClient,
  ]);

  useEffect(() => {
    setSelectedReferenceNote("All");
  }, [acquisitionClient]);

  const resetFilters = () => {
    setSelectedTimeline(initialstate.timeline);
    setSelectedYear(currentYear);
    setSelectedArea(initialstate.area);
    setSelectedSubarea(initialstate.subarea);
    setSelectedRegion(initialstate.region);
    setSelectedDistrict(initialstate.district);
    setSelectedBranch(initialstate.branch);
    setSelectedDatamode(initialstate.dataMode);
    setAcquisitionClient(initialstate.acquisition);
    setMergeClient(initialstate.mergeClient);
    setSelectedReferenceNote(initialstate.referenceNote);
  };

  return (
    <div className="flex flex-col overflow-hidden h-[100dvh] gap-2 p-5 pt-6">
      <Loader />
      <HomeButton adaOnly={props.adaOnly} resetFilters={resetFilters} />
      <TopClientsFilter
        selectedLevel={selectedLevel}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        selectedWeek={selectedWeek}
        setSelectedWeek={setSelectedWeek}
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        selectedQuarter={selectedQuarter}
        setSelectedQuarter={setSelectedQuarter}
        selectedArea={selectedArea}
        setSelectedArea={setSelectedArea}
        selectedSubarea={selectedSubarea}
        setSelectedSubarea={setSelectedSubarea}
        selectedRegion={selectedRegion}
        setSelectedRegion={setSelectedRegion}
        selectedDistrict={selectedDistrict}
        setSelectedDistrict={setSelectedDistrict}
        selectedBranch={selectedBranch}
        setSelectedBranch={setSelectedBranch}
        selectedTimeline={selectedTimeline}
        setSelectedTimeline={setSelectedTimeline}
        selectedDatamode={selectedDatamode}
        setSelectedDatamode={setSelectedDatamode}
        selectedYearMonth={selectedYearMonth}
        setSelectedYearMonth={setSelectedYearMonth}
        exportData={setExportToggle}
        wip={props.wip}
        acquisitionClient={acquisitionClient}
        setAcquisitionClient={setAcquisitionClient}
        mergeClient={mergeClient}
        setMergeClient={setMergeClient}
        selectedReferenceNote={selectedReferenceNote}
        setSelectedReferenceNote={setSelectedReferenceNote}
      />
      <TopClientsTable
        data={topClientsData}
        selectedDatamode={selectedDatamode}
        selectedTimeline={selectedTimeline}
        exportData={exportToggle}
        wip={props.wip}
        selectedLevel={selectedLevel}
        mergeClient={mergeClient}
      />
    </div>
  );
};
