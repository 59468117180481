import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { Popover, Tooltip } from "@mantine/core";
import ClientDetails from "./ClientDetails";
import { IBucketData } from "../../../types/salesPipeline";

const BucketVisual = (props: {
  bucketData: any;
  selectedDateRange: { startdate: string; enddate: string };
}) => {
  const { bucketData, selectedDateRange } = props;
  const svgRef = useRef<SVGSVGElement | null>(null);
  const totalProspectComment = bucketData?.length ?? 0;
  // const firstHalfCount = Math.floor(totalProspectComment / 2);
  // const secondHalfCount = totalProspectComment - firstHalfCount;

  useEffect(() => {
    if (!svgRef.current) return;

    const width = 250;
    const height = 160;
    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height);
    svg.selectAll("*").remove();

    // Draw Bucket
    const bucketPath = `
    M 40,20
    L 210,20
    L 170,150
    L 80,150
    z
  `;

    svg
      .append("path")
      .attr("d", bucketPath)
      .attr("fill", "steelblue")
      .attr("stroke", "black")
      .attr("stroke-width", 2);

    // Add a rounded mouth using an ellipse
    svg
      .append("ellipse")
      .attr("cx", 125) // Centered at the middle of the bucket
      .attr("cy", 20) // Positioned at the mouth of the bucket
      .attr("rx", 85) // Width of the mouth
      .attr("ry", 15) // Height of the rounded effect
      .attr("fill", "#aaddff") // Different color for distinction
      .attr("stroke", "black")
      .attr("stroke-width", 2);

    // Add text inside the bucket
    svg
      .append("text")
      .attr("x", 125) // Horizontal center of the bucket
      .attr("y", 80) // Vertical position inside the bucket (adjust as needed)
      .attr("text-anchor", "middle") // Center the text horizontally
      .attr("font-size", "13px")
      .attr("font-weight", "bold")
      .attr("fill", "white")
      .text(`Sales Prospect`);

    // Leak hole
    svg
      .append("circle")
      .attr("cx", 180)
      .attr("cy", 100)
      .attr("r", 4)
      .attr("fill", "black");
    // svg
    //   .append("circle")
    //   .attr("cx", 70)
    //   .attr("cy", 100)
    //   .attr("r", 4)
    //   .attr("fill", "black");
  }, []);

  return (
    <div className="h-[55%] lg:flex-1  flex flex-col gap-2 min-w-[20%] 2xl:min-w-[16%]">
      <div className="flex justify-center">
        <span className="bg-[#446FAD] text-white text-xs xl:text-sm py-1 px-4 font-semibold rounded-lg shadow-lg ">
          Previous Client / Prospect Activity
        </span>
      </div>
      <div className=" bg-white h-full rounded-lg shadow-lg  py-1 2xl:py-6 pb-4 px-1  w-full flex flex-col justify-center items-center gap-2 2xl:gap-6 ">
        <div className=" rounded-sm  2xl:text-[0.75rem] text-[0.6rem] min-w-fit flex justify-center items-center">
          <span className="border-[1px] border-stone-400 py-1 px-1 2xl:px-3">
            {selectedDateRange.startdate} to {selectedDateRange.enddate}
          </span>
        </div>
        <div className="relative">
          <svg ref={svgRef}></svg>
          {/* Right side water flow */}
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            className="absolute -bottom-2 -right-4"
          >
            {bucketData?.length !== 0 && (
              <>
                <circle cx="0" cy="0" r="6" fill="#446FAD">
                  <animateMotion repeatCount="indefinite" dur="2s">
                    <mpath href="#dropletPathRight" />
                  </animateMotion>
                </circle>
                <circle cx="0" cy="0" r="4" fill="#446FAD">
                  <animateMotion repeatCount="indefinite" dur="1s">
                    <mpath href="#dropletPathRight" />
                  </animateMotion>
                </circle>
              </>
            )}

            <path
              id="dropletPathRight"
              d="M 20 32 Q 40 58, 42 78 T 52 108"
              fill="none"
            />
          </svg>

          {/* Left side water flow */}

          {/* <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            className="absolute -bottom-2 -left-4"
          >
            {bucketData?.length !== 0 && (
              <>
                <circle cx="0" cy="0" r="6" fill="#446FAD">
                  <animateMotion repeatCount="indefinite" dur="2s">
                    <mpath href="#dropletPathLeft" />
                  </animateMotion>
                </circle>
                <circle cx="0" cy="0" r="4" fill="#446FAD">
                  <animateMotion repeatCount="indefinite" dur="1s">
                    <mpath href="#dropletPathLeft" />
                  </animateMotion>
                </circle>
              </>
            )}

            <path
              id="dropletPathLeft"
              d="M 80 32 Q 62 58, 57 78 T 52 108"
              fill="none"
            />
          </svg> */}
        </div>
        <div className="w-[200px] xl:w-[230px] flex justify-between items-center font-bold text-sm">
          <div></div>
          <PopoverButton
            index={0}
            count={totalProspectComment}
            data={bucketData}
          />

          {/* <PopoverButton
            index={1}
            count={secondHalfCount}
            data={bucketData?.slice(firstHalfCount)}
          /> */}
        </div>
      </div>
    </div>
  );
};

const PopoverButton = (props: {
  index: number;
  count: number;
  data: IBucketData[];
}) => {
  const { index, count, data } = props;
  const [openedPopup, setOpenedPopup] = useState<number | null>(null);

  return (
    <Tooltip
      multiline
      w={250}
      style={{
        fontSize: "12px",
        fontWeight: 500,
        color: "black",
      }}
      position="top"
      color="#f5f5f5"
      label={
        <>
          Total number of clients in Sales Prospect stage that have been
          invoiced in the past but not in previous 12 months{" "}
        </>
      }
      withArrow
      arrowSize={7}
      transitionProps={{
        transition: "scale",
        duration: 400,
      }}
    >
      <div
        className=" flex justify-center items-center  h-8 xl:w-16 w-12  cursor-pointer"
        onClick={() =>
          setOpenedPopup(
            openedPopup === index || data?.length === 0 ? null : index
          )
        }
      >
        <Popover
          width={500}
          position="bottom-start"
          withArrow
          shadow="md"
          onClose={() => setOpenedPopup(null)}
        >
          <Popover.Target>
            <button
              className={`w-full h-full rounded-lg shadow-md border-[1px] ${
                openedPopup === index
                  ? "bg-stone-200"
                  : "hover:bg-stone-200 bg-white"
              }`}
            >
              {count}
            </button>
          </Popover.Target>
          {data?.length !== 0 && (
            <Popover.Dropdown>
              <ClientDetails clientDetails={data} tableFillColor="#6a5179" />
            </Popover.Dropdown>
          )}
        </Popover>
      </div>
    </Tooltip>
  );
};

export default BucketVisual;
