import { sortByKeyDailyNumbers } from "./cellFormatter";

export const getIcon = (label: string, sortConfig: any) => {
  return sortConfig?.key !== label
    ? "unfold_more"
    : sortConfig?.direction === "asc"
    ? "arrow_drop_up"
    : "arrow_drop_down";
};

export const requestSort = (
  key: string,
  direction: string,
  tableData: any[] | null
) => {
  if (tableData) {
    let sorted: any[];
    if (direction === "asc") {
      sorted = sortByKeyDailyNumbers(tableData, key, false);
    } else {
      sorted = sortByKeyDailyNumbers(tableData, key, true);
    }
    return sorted;
  }
};
