import {
  Cell,
  Funnel,
  FunnelChart,
  LabelList,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import {
  ISalesFunnelRes,
  IStageAccountRes,
  IStageWiseAccTrack,
} from "../../../types/salesPipeline";
import { useEffect, useState } from "react";
import { groupByKey } from "../../../utils/group";
import AccountTracker from "./AccountTracker";
import { sortByKeyDailyNumbers } from "../../../utils/helper/cellFormatter";
import { ColorPalette, Timeline_Filters } from "./utils/constants";
import { Switch } from "@mantine/core";
import { mapStageNameToNumber, mapStageNumberToName } from "./utils/helper";

const SalesFunnel = (props: {
  funnelData: ISalesFunnelRes[] | null;
  selectedTimeline: string | null;
  setSelectedTimeline: (data: string | null) => void;
  selectedDateRange: any;
  stageAccounts: IStageAccountRes[] | null;
  invoicingClients: IStageAccountRes[] | null;
  loading: boolean;
}) => {
  const {
    funnelData,
    selectedTimeline,
    setSelectedTimeline,
    selectedDateRange,
    stageAccounts,
    invoicingClients,
    loading,
  } = props;
  const [selectedStage, setSelectedStage] = useState<number>(0);
  const [stageWiseAcc, setStageWiseAcc] = useState<IStageWiseAccTrack[]>([]);
  const [openedPopup, setOpenedPopup] = useState<number | null>(null);
  const [adjustedFunnelData, setAdjustedFunnelData] = useState<
    ISalesFunnelRes[] | null
  >(funnelData);
  const [showNotInvoiced, setShowNotInvoiced] = useState<boolean>(false);
  const STAGENUMBER_MAP = mapStageNameToNumber(funnelData);

  useEffect(() => {
    if (funnelData) {
      //Add a fake count for shape and fill for color in the funnel
      const maxCount = 100;
      const adjustedData = funnelData?.map((data, index) => ({
        ...data,
        fakeCount: maxCount * ((funnelData.length - index) / funnelData.length),
        fill: ColorPalette[data.CommentStages] || "#800015",
      }));
      //Filter funnel data on hide invoiced
      let filtered;
      if (!showNotInvoiced) {
        filtered = adjustedData.filter(
          (d: any) => d.CommentStages !== "CMS Not Invoiced"
        );
      } else {
        filtered = adjustedData;
      }
      setAdjustedFunnelData(filtered);
    }
  }, [showNotInvoiced, funnelData]);

  //Format data for accounts tracking popup
  useEffect(() => {
    if (stageAccounts) {
      const filtered = stageAccounts.filter(
        (data: any) => data.StartStage === selectedStage
      );
      const groupedData = groupByKey(filtered, "NextStage");
      const accountsTrack = Object.keys(groupedData).map((key: string) => {
        const data = groupedData[key];
        const totalClientCount =
          adjustedFunnelData?.find(
            (data: any) => data.StageOrder === selectedStage
          )?.TotalClientCount ?? 0;
        const totalAccounts = data.length;
        return {
          numberOfAccounts: totalAccounts,
          nextStage: key,
          data: data,
          percentage: (totalAccounts / totalClientCount) * 100,
        };
      });
      const sortedData = sortByKeyDailyNumbers(
        accountsTrack,
        "nextStage",
        false
      );
      //Filter popup data to hide cms not invoiced
      let filteredStageAcc;
      if (!showNotInvoiced) {
        filteredStageAcc = sortedData.filter(
          (d: any) =>
            Number(d.nextStage) !== STAGENUMBER_MAP["CMS Not Invoiced"]
        );
      } else {
        filteredStageAcc = sortedData;
      }
      setStageWiseAcc(filteredStageAcc);
    }
  }, [stageAccounts, selectedStage, showNotInvoiced]);

  //Close popup on clicking outside the funnel
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const funnelElement = document.getElementById("funnel");
      const popupElement = document.getElementById("popup");
      if (
        selectedStage !== 0 &&
        funnelElement &&
        popupElement &&
        !funnelElement.contains(event.target as Node) &&
        !popupElement.contains(event.target as Node)
      ) {
        setSelectedStage(0);
        setOpenedPopup(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedStage]);

  const handleCellClick = (entry: any) => {
    const stageOrder = entry.StageOrder;
    setSelectedStage(selectedStage === stageOrder ? 0 : stageOrder);
  };

  const getTimelineDate = () => {
    const { startDate, endDate } =
      Timeline_Filters.find((data: any) => data.label === selectedTimeline)
        ?.date ?? {};

    return selectedTimeline
      ? `${startDate} to ${endDate}`
      : `${selectedDateRange.startdate} to ${selectedDateRange.enddate}`;
  };

  return (
    <div className="h-[50%] lg:flex-1 lg:h-full flex flex-col gap-2 min-w-[38%]">
      <div className="flex justify-center">
        <span className="bg-[#446FAD] text-white text-xs xl:text-sm py-1 px-10 font-semibold rounded-lg shadow-lg ">
          Sales Funnel
        </span>
      </div>
      <div className=" bg-white h-full rounded-lg shadow-lg 2xl:py-4 py-1 px-2  w-full ">
        <div className="flex  justify-between text-[0.7rem] font-semibold gap-4 items-end">
          <div className="flex  gap-1 items-start justify-between flex-1">
            <div className="flex  items-center gap-1">
              {Timeline_Filters.map((timeline: any, index: number) => {
                return (
                  <div
                    key={index}
                    className={`border-[1px] py-1 px-2 2xl:px-4 rounded-md shadow-sm   cursor-pointer ${
                      selectedTimeline === timeline.label
                        ? "bg-[#627fa4] text-white"
                        : "bg-white hover:bg-stone-100"
                    }`}
                    onClick={() =>
                      setSelectedTimeline(
                        selectedTimeline !== timeline.label
                          ? timeline.label
                          : null
                      )
                    }
                  >
                    {timeline.label}
                  </div>
                );
              })}
            </div>
            <Switch
              size="xs"
              className="scale-75 2xl:scale-100 "
              label="Show CMS Not Invoiced"
              checked={showNotInvoiced}
              onChange={(event: any) =>
                setShowNotInvoiced(event.currentTarget.checked)
              }
            />
          </div>
        </div>

        {funnelData && !loading ? (
          funnelData.length > 0 ? (
            <div className="h-[95%] w-full flex items-center ">
              <div className="flex flex-col justify-center h-[90%] lg:h-[85%]">
                {adjustedFunnelData?.map((data, index) => (
                  <div
                    key={`label-${index}`}
                    className="text-[10px] lg:text-[12px] font-semibold ml-4 flex-1 flex items-center"
                  >
                    {data.CommentStages}
                  </div>
                ))}
              </div>
              <div
                className={`relative ${
                  adjustedFunnelData && adjustedFunnelData.length === 1
                    ? "w-[50%] h-[200px]"
                    : "h-[90%] lg:h-[85%] w-[70%]"
                }`}
              >
                <div className=" rounded-sm  2xl:text-[0.75rem] text-[0.6rem] min-w-fit flex justify-center items-center -mt-7 mb-3">
                  <span className="border-[1px] border-stone-400 py-1 px-1 2xl:px-3">
                    {getTimelineDate()}
                  </span>
                </div>

                <ResponsiveContainer>
                  <FunnelChart id="funnel">
                    <Funnel
                      dataKey="TotalClientCount"
                      data={adjustedFunnelData ?? []}
                      nameKey="CommentStages"
                      animationDuration={0}
                    >
                      {adjustedFunnelData &&
                        adjustedFunnelData.map((entry, index) => {
                          return (
                            <Cell
                              className="cursor-pointer"
                              key={`cell-${index}`}
                              stroke={
                                selectedStage === entry.StageOrder
                                  ? "#000000"
                                  : "#ddd"
                              }
                              strokeWidth={
                                selectedStage === entry.StageOrder ? 2 : 1
                              }
                              onClick={() => handleCellClick(entry)}
                            />
                          );
                        })}

                      <LabelList
                        className="text-[13px] cursor-pointer"
                        dataKey="TotalClientCount"
                        position="left"
                        offset={16}
                        fill="#000"
                        stroke="#000"
                        strokeWidth={0.5}
                      />
                    </Funnel>
                    <Tooltip
                      formatter={(value, name) => [`${name}: ${value}`]}
                      contentStyle={{
                        fontSize: "11px",
                        padding: "1px 10px",
                        border: "1px solid #ccc",
                      }}
                    />
                  </FunnelChart>
                </ResponsiveContainer>

                {adjustedFunnelData && adjustedFunnelData.length > 0 && (
                  <div className="flex justify-center  2xl:mt-2 text-xs">
                    Number of Accounts
                  </div>
                )}
              </div>
              <AccountTracker
                selectedStage={selectedStage}
                adjustedFunnelData={adjustedFunnelData}
                stageWiseAcc={stageWiseAcc}
                invoicingClients={invoicingClients}
                openedPopup={openedPopup}
                setOpenedPopup={setOpenedPopup}
              />
            </div>
          ) : (
            <div className="bg-white flex justify-center  w-full  text-xs text-slate-600 pt-20">
              No data for selected filters
            </div>
          )
        ) : (
          <div className=" bg-white  flex justify-center  w-full  text-xs text-slate-600 pt-20">
            Loading Funnel...
          </div>
        )}
      </div>
    </div>
  );
};

export default SalesFunnel;
