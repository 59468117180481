/*
 * Copyright (C) 2024 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import SalesActivitySelector from "../components/modules/salesActivity/SalesActivitySelector";
import { useEffect, useState } from "react";
import SalesActivityCharts from "../components/modules/salesActivity/SalesActivityCharts";
import {
  APIFetchBarChartDataAreaLevel,
  APIFetchBarChartDataBranchLevel,
  APIFetchBarChartDataClientLevel,
  APIFetchBarChartDataCompanyLevel,
  APIFetchBarChartDataDistrictLevel,
  APIFetchBarChartDataRegionLevel,
  APIFetchBarChartDataSubareaLevel,
  APIFetchFunnelChartData,
} from "../api/sales-activity";
import { groupByKey } from "../utils/group";
import { useDispatch } from "react-redux";
import { setLoading } from "../store/Filter/actions";
import { Loader } from "../components/common/Loader";
import HomeButton from "../components/common/HomeButton";

const SalesActivity = () => {
  const [branchesList, setBranchesList] = useState([] as any);
  const [areaList, setAreaList] = useState([] as any);
  const [subAreaList, setSubAreaList] = useState([] as any);
  const [regionsList, setRegionsList] = useState([] as any);
  const [districtList, setDistrictList] = useState([] as any);
  const [hierarchy, setHierarchy] = useState({} as any);
  const [level, setLevel] = useState("Company");
  const [selectedLevelBranch, setSelectedLevelBranch] = useState("Company");
  const [subAreaSelected, setSubAreaSelected] = useState("");
  const [areaSelected, setAreaSelected] = useState();
  const [regionSelected, setRegionSelected] = useState<{
    Location: string;
    Title: string;
  }>();
  const [districtSelected, setDistrictSelected] = useState(null);

  const [totalType, setTotalType] = useState("Company");
  const [selectedBranchName, setSelectedBranchName] = useState<any>("All");
  const [data, setData] = useState([]);
  const [funnelChartData, setFunnelChartData] = useState([]);
  const [clientNames, setClientNames] = useState<string[]>([]);
  const [selectedClientName, setSelectedClientName] = useState("All");
  const dispatch = useDispatch();
  // const [selectedDateRange, setSelectedDateRange] = useState({
  //   startdate: moment(new Date()).add("-29", "days").format("yyyy-MM-DD"),
  //   enddate: moment(new Date()).format("yyyy-MM-DD"),
  // });
  const [selectedDateRange, setSelectedDateRange] = useState({
    startdate: null,
    enddate: null,
  });

  useEffect(() => {
    document.title = "Sales Activity";
  }, []);

  useEffect(() => {
    APIFetchFunnelChartData(
      selectedBranchName === "All"
        ? selectedLevelBranch === "Company"
          ? "All"
          : selectedLevelBranch
        : selectedBranchName
    ).then((res: any) => setFunnelChartData(res.data));
  }, [selectedLevelBranch, selectedBranchName]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading(true));
      let res;

      try {
        if (selectedBranchName && selectedBranchName !== "All") {
          if (selectedClientName === "All") {
            res = await APIFetchBarChartDataBranchLevel(selectedBranchName);
            setData(res.data);
            setClientNames(Object.keys(groupByKey(res.data, "clientname")));
          } else {
            res = await APIFetchBarChartDataClientLevel(selectedClientName);
            setData(res.data);
          }
        } else {
          switch (level) {
            case "District":
              if (districtSelected) {
                res = await APIFetchBarChartDataDistrictLevel(districtSelected);
              }
              break;
            case "Region":
              if (regionSelected) {
                res = await APIFetchBarChartDataRegionLevel(regionSelected);
              }
              break;
            case "Subarea":
              if (subAreaSelected) {
                res = await APIFetchBarChartDataSubareaLevel(subAreaSelected);
              }
              break;
            case "Area":
              if (areaSelected) {
                res = await APIFetchBarChartDataAreaLevel(areaSelected);
              }
              break;
            default:
              res = await APIFetchBarChartDataCompanyLevel();
              break;
          }
          if (res?.data) {
            setData(res.data);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        dispatch(setLoading(false));
      }
    };
    fetchData();
  }, [
    selectedBranchName,
    selectedClientName,
    districtSelected,
    regionSelected,
    subAreaSelected,
    areaSelected,
    level,
  ]);

  const filteredDataByDate =
    selectedDateRange.startdate === null && selectedDateRange.enddate === null
      ? data
      : data.filter(
          (d: any) =>
            d.insertdate >= (selectedDateRange.startdate ?? "") &&
            d.insertdate <= (selectedDateRange.enddate ?? "")
        );

  const barchartTitle =
    selectedClientName === "All"
      ? "Total Comments by Branch"
      : "Total Comments by Client";

  let totalCounts: [] = [];
  totalCounts = Object.values(filteredDataByDate)
    .flat()
    .reduce((counts: any, entry: any) => {
      counts[entry.commenttype] =
        (counts[entry.commenttype] || 0) + entry.commenttypecount;
      return counts;
    }, {});

  return (
    <main className="min-h-[100vh] min-w-[100vw] w-[100%]">
      <section className="w-[100%] h-[100%] flex">
        <div className="h-full flex-grow">
          <div className=" flex flex-col gap-2 ">
            <HomeButton />
            <div className="absolute font-semibold text-[#5f98bc] text-md 2xl:text-lg pl-16 pt-2 2xl:pt-1">
              Sales Activity
            </div>
            <div className="flex gap-8">
              <div className="bg-white flex flex-col w-[16vw] lg:w-[9vw] cursor-pointer overflow-y-auto ml-2 border-[1px] border-slate-300 mt-[20vh] sm:max-2xl:mt-[10vh] 2xl:mt-[5vh] h-[95vh] 2xl:h-[95vh]">
                <div className=" bg-[#d3e9f7] pl-2 text-[0.6rem] py-[0.1rem] sticky z-10 top-0 text-center font-semibold border-b-2">
                  Select Branch
                </div>

                {branchesList &&
                  branchesList?.sort().map((branch: any) => (
                    <div
                      className={`border-b-[1px] text-[0.6rem] py-[0.1rem] px-2 font-medium ${
                        selectedBranchName === branch
                          ? "bg-[#80b5d6] text-white"
                          : "hover:bg-[#eff8fe]"
                      }`}
                      onClick={() => {
                        setSelectedBranchName(branch);
                        setSelectedClientName("All");
                      }}
                    >
                      {branch}
                    </div>
                  ))}
              </div>
              <div className="flex flex-col flex-1 pr-8 mt-1 w-[80vw]">
                <SalesActivitySelector
                  level={level}
                  setLevel={setLevel}
                  hierarchy={hierarchy}
                  areaList={areaList}
                  setAreaList={setAreaList}
                  subAreaList={subAreaList}
                  setSubAreaList={setSubAreaList}
                  regionsList={regionsList}
                  setRegionsList={setRegionsList}
                  districtList={districtList}
                  setDistrictList={setDistrictList}
                  selectedLevelBranch={selectedLevelBranch}
                  setSelectedLevelBranch={setSelectedLevelBranch}
                  areaSelected={areaSelected}
                  setAreaSelected={setAreaSelected}
                  subAreaSelected={subAreaSelected}
                  setSubAreaSelected={setSubAreaSelected}
                  regionSelected={regionSelected}
                  setRegionSelected={setRegionSelected}
                  setDistrictSelected={setDistrictSelected}
                  branchesList={branchesList}
                  setBranchesList={setBranchesList}
                  setHierarchy={setHierarchy}
                  setTotalType={setTotalType}
                  totalType={totalType}
                  setSelectedBranchName={setSelectedBranchName}
                  setSelectedClientName={setSelectedClientName}
                  setSelectedDate={setSelectedDateRange}
                />
                <SalesActivityCharts
                  selectedBranchName={selectedBranchName}
                  setSelectedBranchName={setSelectedBranchName}
                  branchesList={branchesList}
                  clientNames={clientNames}
                  selectedClientName={selectedClientName}
                  setSelectedClientName={setSelectedClientName}
                  barchartData={totalCounts}
                  barchartTitle={barchartTitle}
                  funnelChartData={funnelChartData}
                  selectedLevelBranch={selectedLevelBranch}
                  selectedDateRange={selectedDateRange}
                  setSelectedDate={setSelectedDateRange}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Loader />
    </main>
  );
};

export default SalesActivity;
