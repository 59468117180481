import { clsx } from "@mantine/core";
import React from "react";

export function Button({
  className = "",
  active,
  children,
  ...others
}: React.ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
  active?: boolean;
  children: React.ReactNode;
}) {
  return (
    <button
      className={clsx([
        "  rounded-sm px-2 py-1 min-w-16 text-white h-7 font-medium",
        active ? "bg-azure-blue" : "bg-tertiary hover:bg-tertiary/85",
        className,
      ])}
      {...others}
    >
      {children}
    </button>
  );
}
