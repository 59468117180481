/*
 * Copyright (C) 2025 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { GetRequest } from "../plugins/https";

export const APIFetchLatestMonthYear= () => {
  return GetRequest(`/income-model/month-year`);
};
export const APIFetchData = (year:string,month:string,location:string) => {
  return GetRequest(`/income-model/data`,{params:{year,month,location}});
};
