import { Table } from "@mantine/core";
import {
  formatAmount,
  sortByKeyDailyNumbers,
} from "../../../utils/helper/cellFormatter";
import { IBucketData, IStageAccountRes } from "../../../types/salesPipeline";

const ClientDetails = (props: {
  clientDetails: IStageAccountRes[] | IBucketData[] | null;
  tableFillColor: string;
  isInvoicing?: boolean;
}) => {
  const { clientDetails, isInvoicing, tableFillColor } = props;
  const sortedClients = sortByKeyDailyNumbers(clientDetails, "Branch");

  return (
    <div
      className={`border-[1px] overflow-auto ${
        isInvoicing ? "max-h-[160px]" : " max-h-[200px]"
      }`}
    >
      {isInvoicing ? (
        <Table
          fontSize={11}
          verticalSpacing={1}
          horizontalSpacing={2}
          withColumnBorders
        >
          <thead
            className="sticky top-0 "
            style={{ backgroundColor: tableFillColor }}
          >
            <tr>
              <th className=" !text-white ">Client Name</th>
              <th className=" !text-white min-w-20 !text-center">
                Gross Profit
              </th>
            </tr>
          </thead>
          <tbody>
            {clientDetails &&
              clientDetails.map((data: any, index: number) => {
                return (
                  <tr className="font-semibold" key={index}>
                    <td className="text-left">{data.ClientName}</td>
                    <td className="!text-center">
                      {formatAmount((data?.GrossProfit || data?.GP) ?? 0)}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      ) : (
        <Table
          fontSize={12}
          verticalSpacing={1}
          horizontalSpacing={2}
          withColumnBorders
        >
          <thead
            className="sticky top-0 "
            style={{ backgroundColor: tableFillColor }}
          >
            <tr>
              <th className=" !text-white !p-1">Branch</th>
              <th className=" !text-white !p-1">Client Name</th>
              <th className=" !text-white !p-1">Manager</th>
            </tr>
          </thead>
          <tbody>
            {sortedClients.map((data: IStageAccountRes, index: number) => {
              return (
                <tr
                  className="last:border-b-[1px] font-medium hover:bg-stone-100"
                  key={index}
                >
                  <td className="w-24">{data.Branch}</td>
                  <td>{data.ClientName}</td>
                  <td className="w-28">{data.Manager}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default ClientDetails;
