import { ISalesActvRes } from "../../../../types/salesPipeline";

export const handleGroupAndSumData = (
  data: {}[] | null,
  groupBy: string,
  sumKeys: string[]
) => {
  if (!data) return null;
  return data.reduce(
    (acc: Record<string, any>, element: Record<string, any>) => {
      const key = `${element[groupBy]}`;
      if (!acc[key]) {
        acc[key] = {
          data: [],
          [groupBy]: element[groupBy],
        };
        sumKeys.forEach((sumKey) => {
          acc[key][`Total${sumKey}`] = 0;
        });
      }
      sumKeys.forEach((sumKey) => {
        acc[key][`Total${sumKey}`] += element[sumKey] || 0;
      });
      acc[key].data.push(element);
      return acc;
    },
    {}
  );
};

export const getAggregatedData = (data: ISalesActvRes[]) => {
  if (!data) return null;

  //Branch wise grouping
  const branches = handleGroupAndSumData(data, "Branch", ["CommentCount"]);

  //Job title wise grouping
  if (branches) {
    Object.values(branches).forEach((branch: any) => {
      branch.jobTitle = handleGroupAndSumData(branch.data, "JobTitle", [
        "CommentCount",
      ]);

      //Comment stage wise grouping
      Object.values(branch.jobTitle).forEach((jobTitle: any) => {
        let groupedComments: any = handleGroupAndSumData(
          jobTitle.data,
          "CommentStages",
          ["CommentCount"]
        );
        jobTitle.comments = Object.fromEntries(
          Object.entries(groupedComments).sort(
            ([, a]: any, [, b]: any) =>
              a.data[0].StageOrder - b.data[0].StageOrder //Sort Comments stage order wise
          )
        );

        // Client wise grouping
        Object.values(jobTitle.comments).forEach((comment: any) => {
          let groupedClients: any = handleGroupAndSumData(
            comment.data,
            "ClientName",
            ["CommentCount"]
          );
          comment.clients = Object.fromEntries(
            Object.entries(groupedClients).sort(
              ([, a]: any, [, b]: any) =>
                a.data[0].ClientName.localeCompare(b.data[0].ClientName) //Sort Client name wise
            )
          );
        });
      });
    });
  }

  return branches;
};

export const mapStageNumberToName = (data: any) => {
  return data?.reduce((acc: any, item: any) => {
    acc[item.StageOrder] = item.CommentStages;
    return acc;
  }, {} as Record<number, string>);
};

export const mapStageNameToNumber = (data: any) => {
  return data?.reduce((acc: any, item: any) => {
    acc[item.CommentStages] = item.StageOrder;
    return acc;
  }, {} as Record<number, string>);
};
