/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { CompanySummaryCharts } from "../components/modules/companySummary/CompanySummaryCharts";
import "../assets/scss/CompanySummary.scss";
import { CompanyBranchCharts } from "../components/modules/companySummary/CompanyBranchCharts";
import { CompanySummarySelector } from "../components/modules/companySummary/CompanySummarySelector";
import { useEffect, useState } from "react";
import {
  APIAreaTotalData,
  APICompanyTotalData,
  APICompanyTotalRevenue,
  APIBranchTotalData,
  APIRegionTotalData,
  APISubareaTotalData,
  FetchClientsGPRankByLocation,
  FetchClientsRevenueRankByLocation,
  fetchClientDetailsByLocation,
  GoalAPICompanyTotalData,
  GoalAPIAreaTotalData,
  GoalAPISubareaTotalData,
  GoalAPIRegionTotalData,
  GoalAPIBranchTotalData,
  APIDistrictTotalData,
  APIADABranchTotalData,
  APIADATotalData,
  FetchClientsRevenueRankByADALocation,
  FetchClientsGPRankByADALocation,
  fetchClientDetailsByADALocation,
  APICompanyWithoutPDQData,
  APISplitBranchTotalData,
  GoalAPIDistrictTotalData,
  GoalAPICompanyWithoutPDQ,
  APIGetMergedBranches,
  GoalAPISplitBranchData,
} from "../api/company-summary";
import { groupByKey } from "../utils/group";
import {
  getAllCalendarWeeks,
  getCalendarWeekLabels,
  setLoading,
} from "../store/Filter/actions";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../components/common/Loader";
import { sortByKey } from "../utils/helper/cellFormatter";
import { loadPipSummaryData } from "../store/PipSummary/actions";
import {
  setEnableTooltip,
  setShowBranchClientsRanking,
  setShowClientsRanking,
  setShowPipSummaryLines,
} from "../store/CompanySummary/actions";
import HomeButton from "../components/common/HomeButton";
import { APIChartHeaders } from "../api/goal-setting";
import { useLabelFormatter } from "../utils/hooks/useLabelFormatter";
import { DATAMODE, LEVEL } from "../components/modules/companySummary/utils/constants";

const initialState = {
  selectedBranch: LEVEL.COMPANY,
  level: LEVEL.COMPANY,
  dataMode: "Revenue",
  clientsDataMode: "Revenue",
  subAreaSelected: null,
  areaSelected: "",
  totalType: LEVEL.COMPANY,
  sortValue: "TWRank",
  sameMaxValue: false,
  sameClientRatio:false,
  clientsNumber:"10",
  showHouston:true
};

export const CompanySummaryReport = (props: any) => {
  const labelFormatter = useLabelFormatter();
  const dispatch = useDispatch();
  const initialLabels = [
    labelFormatter("Current Year"),
    labelFormatter("Last Year"),
    labelFormatter("Previous Year"),
    labelFormatter("Actual Goal"),
    labelFormatter("12 Months"),
  ];
  const [selectedBranch, setSelectedBranch] = useState<string>(
    props.adaOnly ? "ADA_Branch" : LEVEL.COMPANY
  );
  const currentYear = useSelector(
    (state: any) => state.filterReducer.currentYear
  ).toString();
  const [level, setLevel] = useState<string>(props.adaOnly ? LEVEL.ADA : LEVEL.COMPANY);
  const [totalData, setTotalData] = useState({});
  const [totalDataWithoutPDQ, setTotalDataWithoutPDQ] = useState({});
  const [areaTotalData, setAreaTotalData] = useState({});
  const [districtTotalData, setDistrictTotalData] = useState({});
  const [regionTotalData, setRegionTotalData] = useState({});
  const [subareaTotalData, setSubareaTotalData] = useState({});
  const [branchesData, setBranchesData] = useState({});
  const [splitBranchesData, setSpliBranchesData] = useState({});
  const [adaTotalData, setAdaTotalData] = useState({});
  const [adaBranchData, setAdaBranchData] = useState({});
  const [mergedBranches, setMergedBranches] = useState({});
  const [hierarchy, setHierarchy] = useState({} as any);
  const [areaList, setAreaList] = useState([] as any);
  const [subAreaList, setSubAreaList] = useState([] as any);
  const [regionsList, setRegionsList] = useState([] as any);
  const [districtList, setDistrictList] = useState([] as any);
  const [branchesList, setBranchesList] = useState([] as any);
  const [areaSelected, setAreaSelected] = useState(initialState.areaSelected);
  const [subAreaSelected, setSubAreaSelected] = useState<string | null>(initialState.subAreaSelected);
  const [chartInfo, setChartInfo] = useState({ totalRev: [], growthPerc: [] });
  const [totalType, setTotalType] = useState(
    props.adaOnly ? "ADASTAFF, Inc." : LEVEL.COMPANY
  );
  const [dataMode, setDataMode] = useState(initialState.dataMode);
  const [sortValue, setSortValue] = useState(initialState.sortValue);
  const [labels, setLabels] = useState([...initialLabels] as any);
  const [clientsData, setClientsData] = useState<any>();
  const [clientsDetailData, setClientsDetailData] = useState<any>();
  const [clientsDataMode, setClientsDataMode] = useState(initialState.clientsDataMode);
  const [clientNumber, setClientNumber] = useState(initialState.clientsNumber);
  const [clientYear, setClientYear] = useState(currentYear);
  const [sameMaxValue, setSameMaxValue] = useState(initialState.sameMaxValue);
  const [showGoalData, _setShowGoalData] = useState(props.goal ?? false);
  const [sameClientRatio, setSameClientRatio] = useState<any>(initialState.sameClientRatio);
  const [showHouston, setShowHouston] = useState<boolean>(initialState.showHouston);
  const [totalRevenues, setTotalRevenues] = useState({
    company: [],
    companyWithoutPDQ: [],
    district: [],
    region: [],
    area: [],
    branch: [],
    split_branch: [],
    subarea: [],
    ada: [],
    ada_branch: [],
  });
  const [orders, setOrders] = useState({
    company: [],
    region: [],
    district: [],
    area: [],
    branch: [],
  });

  const showClientsRanking = useSelector(
    (state: any) => state.companySummaryReducer.showClientsRanking
  );
  const showBranchClientsRanking = useSelector(
    (state: any) => state.companySummaryReducer.showBranchClientsRanking
  );
  const showPipSummaryLines = useSelector(
    (state: any) => state.companySummaryReducer.showPipSummaryLines
  );
  const milestonesPipData = useSelector(
    (state: any) => state.pipSummaryReducer.milestonesData
  );
  const regionsPipData = useSelector(
    (state: any) => state.pipSummaryReducer.regionsData
  );
  const districtsPipData = useSelector(
    (state: any) => state.pipSummaryReducer.districtsData
  );
  const branchesPipData = useSelector(
    (state: any) => state.pipSummaryReducer.branchesData
  );

  useEffect(() => {
    document.title = props.executive
      ? "Executive Company Summary"
      : "Company Summary Report";
  }, []);

  //totals data
  const getCompanyTotal = async () => {
    const res = showGoalData
      ? await GoalAPICompanyTotalData()
      : await APICompanyTotalData();
    const data = groupByKey(res.data, "Types");
    setLabels(Object.keys(data) ?? []);
    setTotalData(data);
  };

  const getCompanyWithoutPDQ = async () => {
    const res = showGoalData
      ? await GoalAPICompanyWithoutPDQ()
      : await APICompanyWithoutPDQData();
    const data = groupByKey(res.data, "Types");
    setLabels(Object.keys(data) ?? []);
    setTotalDataWithoutPDQ(data);
  };

  const getAreaTotals = async () => {
    const res = showGoalData
      ? await GoalAPIAreaTotalData()
      : await APIAreaTotalData();
    const data = groupByKey(res.data, "areaname");
    let areas = {};
    Object.keys(data).forEach((v) => {
      areas = {
        ...areas,
        [v]: groupByKey(data[v], "Types"),
      };
    });
    setAreaTotalData(areas);
  };

  const getSubareaTotals = async () => {
    const res = showGoalData
      ? await GoalAPISubareaTotalData()
      : await APISubareaTotalData();
    const data = groupByKey(res.data, "Location");
    let regions = {};
    Object.keys(data).forEach((v) => {
      regions = {
        ...regions,
        [v]: groupByKey(data[v], "Types"),
      };
    });
    setSubareaTotalData(regions);
  };

  const getRegionTotals = async () => {
    const res = showGoalData
      ? await GoalAPIRegionTotalData()
      : await APIRegionTotalData();
    const data = groupByKey(res.data, "Location");
    let regions = {};
    Object.keys(data).forEach((v) => {
      regions = {
        ...regions,
        [v]: groupByKey(data[v], "Types"),
      };
    });
    setRegionTotalData(regions);
  };

  const getDistrictTotals = async () => {
    const res = showGoalData
      ? await GoalAPIDistrictTotalData()
      : await APIDistrictTotalData();
    const data = groupByKey(res.data, "Location");
    let districts = {};
    Object.keys(data).forEach((v) => {
      districts = {
        ...districts,
        [v]: groupByKey(data[v], "Types"),
      };
    });
    setDistrictTotalData(districts);
  };

  // branches data
  const getTotalBranchesData = async () => {
    const res = showGoalData
      ? await GoalAPIBranchTotalData()
      : await APIBranchTotalData();
    const data = groupByKey(res.data, "Location");
    let branches = {};
    Object.keys(data).forEach((v) => {
      branches = {
        ...branches,
        [v]: groupByKey(data[v], "Types"),
      };
    });
    setBranchesData(branches);
  };

  const getSplitBranchesData = async () => {
    const res = showGoalData
      ? await GoalAPISplitBranchData()
      : await APISplitBranchTotalData();
    const data = groupByKey(res.data, "MergedBranch");
    let branches: any = {};
    Object.keys(data).forEach((mergedBranch) => {
      branches[mergedBranch] = {};
      const metricsData = groupByKey(data[mergedBranch], "Metrics");
      Object.keys(metricsData).forEach((metric) => {
        branches[mergedBranch][metric] = {};
        const locationData = groupByKey(metricsData[metric], "Location");
        Object.keys(locationData).forEach((location) => {
          branches[mergedBranch][metric][location] = groupByKey(
            locationData[location],
            "Types"
          );
        });
      });
    });
    setSpliBranchesData(branches);
  };

  const getMergedBranches = async () => {
    const res = await APIGetMergedBranches();
    const data = groupByKey(res.data, "MergedTo");
    setMergedBranches(Object.keys(data));
  };

  //ADA
  const getADABranchData = async () => {
    const res = await APIADABranchTotalData();
    const data = groupByKey(res.data, "Location");
    let ada = {};
    Object.keys(data).forEach((v) => {
      ada = {
        ...ada,
        [v]: groupByKey(data[v], "Types"),
      };
    });
    setAdaBranchData(ada);
  };

  const getADATotalData = async () => {
    const res = await APIADATotalData();
    const data = groupByKey(res.data, "Location");
    let ada = {};
    Object.keys(data).forEach((v) => {
      ada = {
        ...ada,
        [v]: groupByKey(data[v], "Types"),
      };
    });
    setAdaTotalData(ada);
  };

  const getCompanyTotalData = async (option: string) => {
    dispatch(setLoading(true));
    setSelectedBranch(option);
    await getTotalBranchesData();
    dispatch(setLoading(false));
  };

  //Chart headers
  const getTotalRevenues = async () => {
    const res: any = await APICompanyTotalRevenue();
    const temp = {
      ...totalRevenues,
      company: res.data.company[0],
      companyWithoutPDQ: res.data?.companyWithoutPDQ?.[0],
      region: groupByKey(res.data.region, "Region"),
      area: groupByKey(res.data.area, "AreaName"),
      district: groupByKey(res.data.district, "District"),
      branch: groupByKey(res.data.branch, "Branch"),
      split_branch: groupByKey(res.data.split_branch, "Branch"),
      subarea: groupByKey(res.data.subarea, "Sub Area Name"),
      ada: res.data.ada,
      ada_branch: groupByKey(res.data.ada_branch, "Branch"),
    };
    const tempOrder: any = {
      branch: res.data.branch.map((v: any) => v.Branch),
      region: res.data.region.map((v: any) => v.Region),
      area: res.data.area.map((v: any) => v.AreaName),
      district: res.data.district.map((v: any) => v.District),
      subarea: res.data.subarea.map((v: any) => v["Sub Area Name"]),
      ada_branch: res.data.ada_branch.map((v: any) => v.Branch),
    };
    setTotalRevenues(temp);
    setOrders(tempOrder);
  };

  //2024 total revenue and growth %
  const getChartHeadersFromGoal = async () => {
    const res: any = await APIChartHeaders();
    setChartInfo({
      totalRev: res.totalRevenue,
      growthPerc: res.growthPerc,
    });
  };

  const getWeekDates = async () => {
    dispatch(
      await getAllCalendarWeeks((+currentYear - 2).toString(), currentYear)
    );
    dispatch(await getCalendarWeekLabels(currentYear));
  };

  // initiation
  useEffect(() => {
    if (!props.adaOnly) {
      loadAllData().then(() => {});
    }
  }, [showGoalData]);

  const loadAllData = async () => {
    dispatch(setLoading(true));
    await Promise.all([
      getCompanyTotal(),
      getCompanyWithoutPDQ(),
      getAreaTotals(),
      getSubareaTotals(),
      getRegionTotals(),
      getDistrictTotals(),
      getTotalBranchesData(),
      getSplitBranchesData(),
    ]);
    dispatch(setLoading(false));
  };

  useEffect(() => {
    dispatch(setLoading(true));
    (async () =>
      await Promise.all([
        getWeekDates(),
        getTotalRevenues(),
        getADABranchData(),
        getADATotalData(),
        getMergedBranches(),
        getChartHeadersFromGoal(),
      ]))();
    dispatch(setLoading(false));
  }, []);

  //Top Clients
  useEffect(() => {
    setClientNumber(initialState.clientsNumber);
  }, [showBranchClientsRanking, showClientsRanking]);

  useEffect(() => {
    if (dataMode === DATAMODE.NETINCOME || dataMode === DATAMODE.OPERATINGINCOME) {
      setClientsDataMode(initialState.clientsDataMode);
    } else {
      setClientsDataMode(dataMode);
    }
  }, [dataMode]);

  const fetchClientData = async () => {
    try {
      dispatch(setLoading(true));
      const fetchRankFunction =
        clientsDataMode === DATAMODE.REVENUE
          ? level === LEVEL.ADA
            ? FetchClientsRevenueRankByADALocation
            : FetchClientsRevenueRankByLocation
          : level === LEVEL.ADA
          ? FetchClientsGPRankByADALocation
          : FetchClientsGPRankByLocation;
      const response = await fetchRankFunction(
        selectedBranch,
        clientNumber,
        clientYear,
        level !== LEVEL.ADA ? level : ""
      );
      setClientsData(response.data);

      const clientDetailsRequests = response.data.map((d: any) =>
        level === LEVEL.ADA
          ? fetchClientDetailsByADALocation(d.ClientName, clientYear, selectedBranch)
          : fetchClientDetailsByLocation(d.ClientName, clientYear, selectedBranch, level)
      );
      const clientDetailsResponses = await Promise.all(clientDetailsRequests);
      const clientDetails = response.data.reduce((acc: any, d: any, i: number) => {
        acc[d.ClientName] = groupByKey(
          sortByKey(clientDetailsResponses[i].data, {
            type: "CalendarYear",
            isReverse: true,
          }),
          "CalendarYear"
        );
        return acc;
      }, {});
      setClientsDetailData(clientDetails);
      dispatch(setLoading(false));
    } catch (err) {
      console.error(err);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (!showClientsRanking && !showBranchClientsRanking) return;
    fetchClientData();
  }, [showClientsRanking, showBranchClientsRanking, clientNumber, clientsDataMode, clientYear]);
  

  useEffect(() => {
    if (showPipSummaryLines && props.executive) {
      dispatch(loadPipSummaryData(props.executive, []));
    }
  }, [showPipSummaryLines]);

  const handleBackButton = () => {
    if (showClientsRanking || showBranchClientsRanking) {
      setSelectedBranch(
        level !== "Branch"
          ? selectedBranch
          : props.adaOnly
          ? "ADA_Branch"
          : LEVEL.COMPANY
      );
      setLevel(level !== "Branch" ? level : props.adaOnly ? LEVEL.ADA : LEVEL.COMPANY);
      dispatch(setShowClientsRanking(false));
      dispatch(setShowBranchClientsRanking(false));
    } else {
      dispatch(setShowPipSummaryLines(false));
      dispatch(setEnableTooltip(true));
      setSelectedBranch(initialState.selectedBranch);
      setAreaSelected(initialState.areaSelected);
      setSubAreaSelected(initialState.subAreaSelected);
      setLevel(initialState.level);
      setTotalType(initialState.totalType);
      setDataMode(initialState.dataMode);
      setSortValue(initialState.sortValue);
      setSameMaxValue(initialState.sameMaxValue);
    }
  };

  return (
    <main className={"cs-summary-screen"}>
      <section className="cs-summary-section flex">
        <div className="main-section flex-grow flex flex-col gap-5   bg-[#e2efed] lg:ml-0">
          <div className={"cs-header"}>
            <HomeButton
              adaOnly={props.adaOnly}
              resetFilters={handleBackButton}
            />
            <div className="flex align-center cs-title-area">
              <div className="cs-title text-center">
                {props.executive
                  ? "Executive Company Summary"
                  : "Company Summary"}
              </div>
            </div>
            <CompanySummarySelector
              selectedBranch={selectedBranch}
              setSelectedBranch={setSelectedBranch}
              getCompanyTotal={getCompanyTotalData}
              setSortValue={setSortValue}
              sortValue={sortValue}
              dataMode={dataMode}
              setDataMode={setDataMode}
              setLevel={setLevel}
              level={level}
              hierarchy={hierarchy}
              setHierarchy={setHierarchy}
              districtList={districtList}
              setDistrictList={setDistrictList}
              regionsList={regionsList}
              setRegionsList={setRegionsList}
              areaList={areaList}
              setAreaList={setAreaList}
              areaSelected={areaSelected}
              setTotalType={setTotalType}
              totalType={totalType}
              setAreaSelected={setAreaSelected}
              subAreaList={subAreaList}
              setSubAreaList={setSubAreaList}
              subAreaSelected={subAreaSelected}
              setSubAreaSelected={setSubAreaSelected}
              branchesList={branchesList}
              setBranchesList={setBranchesList}
              clientsDataMode={clientsDataMode}
              setClientsDataMode={setClientsDataMode}
              clientNumber={clientNumber}
              setClientNumber={setClientNumber}
              clientYear={clientYear}
              setClientYear={setClientYear}
              sameMaxValue={sameMaxValue}
              setSameMaxValue={setSameMaxValue}
              showHouston={showHouston}
              setShowHouston={setShowHouston}
              executive={props.executive}
              wip={props.wip}
              sameClientRatio={sameClientRatio}
              setSameClientRatio={setSameClientRatio}
              adaOnly={props.adaOnly}
              isAdaAccess={props.isAdaAccess}
            />
          </div>

          <CompanySummaryCharts
            areaTotalData={areaTotalData}
            regionTotalData={regionTotalData}
            districtTotalData={districtTotalData}
            subareaTotalData={subareaTotalData}
            adaTotalData={adaTotalData}
            areaList={areaList}
            subAreaList={subAreaList}
            sortValue={sortValue}
            totalType={totalType}
            setTotalType={setTotalType}
            selectedBranch={selectedBranch}
            setSelectedBranch={setSelectedBranch}
            dataMode={dataMode}
            level={level}
            setLevel={setLevel}
            regionsList={regionsList}
            districtList={districtList}
            labels={labels}
            totalRevenuesBox={{
              company: totalRevenues.company,
              companyWithoutPDQ: totalRevenues.companyWithoutPDQ,
              region: { ...totalRevenues.region, ...totalRevenues.district },
              area: totalRevenues.area,
              subarea: totalRevenues.subarea,
              district: totalRevenues.district,
              ada: totalRevenues.ada,
            }}
            totalRevenues={totalRevenues}
            totalDataValues={{
              Area: areaTotalData,
              Region: regionTotalData,
              District: districtTotalData,
              Company: totalData,
              CompanyWithoutPDQ: totalDataWithoutPDQ,
              Subarea: subareaTotalData,
              ADA: adaTotalData,
            }}
            showClientsRanking={showClientsRanking}
            showBranchClientsRanking={showBranchClientsRanking}
            clientsData={clientsData}
            clientsDetailData={clientsDetailData}
            clientsDataMode={clientsDataMode}
            clientYear={clientYear}
            branchesData={branchesData}
            adaBranchData={adaBranchData}
            revenueData={totalRevenues.branch}
            sameMaxValue={sameMaxValue}
            pipMilestonesData={milestonesPipData}
            pipData={{
              region: regionsPipData,
              district: districtsPipData,
              branch: branchesPipData,
            }}
            executive={props.executive}
            wip={props.wip}
            showGoalData={showGoalData}
            sameClientRatio={sameClientRatio}
            chartInfo={chartInfo}
          />
          {!(showClientsRanking || showBranchClientsRanking) && (
            <CompanyBranchCharts
              dataMode={dataMode}
              branchesData={branchesData}
              adaBranchData={adaBranchData}
              sortOrder={orders.branch}
              sortValue={sortValue}
              totalType={totalType}
              selectedBranch={selectedBranch}
              level={level}
              labels={labels}
              branchesList={branchesList}
              revenueData={
                selectedBranch === "ADA_Branch"
                  ? totalRevenues.ada_branch
                  : totalRevenues.branch
              }
              setTotalType={setTotalType}
              setSelectedBranch={setSelectedBranch}
              setLevel={setLevel}
              sameMaxValue={sameMaxValue}
              showHouston={showHouston}
              pipMilestonesData={[
                ...milestonesPipData.legacy_branch,
                ...milestonesPipData.new_branch,
              ]}
              branchesPipData={branchesPipData}
              mergedBranchNames={mergedBranches}
              splitBranchesData={splitBranchesData}
              splitBranchRevenueData={totalRevenues.split_branch}
              executive={props.executive}
              wip={props.wip}
              showGoalData={showGoalData}
              chartInfo={chartInfo}
            />
          )}
        </div>
      </section>
      <Loader />
    </main>
  );
};
