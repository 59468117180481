/*
 * Copyright (C) 2025 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { GetRequest } from "../plugins/https";

export const APIFetchBranches = (
  location: string,
  salesRegion: string,
  regionSalesManager: string,
  manager: string
) => {
  return GetRequest(`/sales-pipeline/branches`, {
    params: { location, salesRegion, regionSalesManager, manager },
  });
};

export const APIFetchBranchManagers = (location: string,salesRegion:string,regionalSalesManager:string) => {
  return GetRequest(`/sales-pipeline/branch-managers`, {
    params: { location ,salesRegion,regionalSalesManager},
  });
};

export const APIFetchSalesManagers = (
  location: string,
  regionSalesManager: string,
  salesRegion: string
) => {
  return GetRequest(`/sales-pipeline/sales-managers`, {
    params: { regionSalesManager, salesRegion, location },
  });
};

export const APIFetchRegions = (
  location: string,
  manager: string,
  regionSalesManager: string
) => {
  return GetRequest(`/sales-pipeline/sales-regions`, {
    params: { location, manager, regionSalesManager },
  });
};

export const APIFetchRegionalSalesManagers = (
  location: string,
  manager: string,
  salesRegion: string
) => {
  return GetRequest(`/sales-pipeline/regional-sales-managers`, {
    params: { location, manager, salesRegion },
  });
};

export const APIFetchSalesActivityData = (
  location: string,
  manager: string,
  startDate: string,
  endDate: string,
  salesRegion: string,
  regionSalesManager: string
) => {
  return GetRequest(`/sales-pipeline/sales-activity`, {
    params: {
      location,
      manager,
      startDate,
      endDate,
      salesRegion,
      regionSalesManager,
    },
  });
};

export const APIFetchSalesFunnelData = (
  location: string,
  manager: string,
  timeline: string | null,
  startDate: string,
  endDate: string,
  salesRegion: string,
  regionSalesManager: string
) => {
  return GetRequest(`/sales-pipeline/sales-funnel`, {
    params: {
      location,
      manager,
      timeline,
      startDate,
      endDate,
      salesRegion,
      regionSalesManager,
    },
  });
};

export const APIFetchStageAccountsData = (
  location: string,
  manager: string,
  timeline: string | null,
  startDate: string,
  endDate: string,
  salesRegion: string,
  regionSalesManager: string
) => {
  return GetRequest(`/sales-pipeline/stage-accounts`, {
    params: {
      location,
      manager,
      timeline,
      startDate,
      endDate,
      salesRegion,
      regionSalesManager,
    },
  });
};

export const APIFetchSalesBucketData = (
  location: string,
  manager: string,
  startDate: string,
  endDate: string,
  salesRegion: string,
  regionSalesManager: string
) => {
  return GetRequest(`/sales-pipeline/bucket-data`, {
    params: {
      location,
      manager,
      startDate,
      endDate,
      salesRegion,
      regionSalesManager,
    },
  });
};

export const APIFetchKPIData = (
  location: string,
  manager: string,
  startDate: string,
  endDate: string,
  salesRegion: string,
  regionSalesManager: string
) => {
  return GetRequest(`/sales-pipeline/kpi`, {
    params: {
      location,
      manager,
      startDate,
      endDate,
      salesRegion,
      regionSalesManager,
    },
  });
};

export const APIFetchBDRData= (
  startDate: string,
  endDate: string,
) => {
  return GetRequest(`/sales-pipeline/bdr`, {
    params: {
      startDate,
      endDate,
    },
  });
};
