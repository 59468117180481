/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {
  APIGetAllRegions,
  APIGetAllRegionsExceptQcsa,
  APIGetCalendarWeekDatesByYear,
  APIGetCalendarWeeks,
  APIGetFinancialLocations,
  APIGetFinancialLocationsWip,
  APIGetLocationOrder,
} from "../../api/filter";
import { APIGetFinancialYTDComparisonLocations } from "../../api/financial-ytd-comparison";
import { groupByKey } from "../../utils/group";
import { LOCATION_LEVEL } from "../../utils/helper/constants";
import {
  SET_ALL_CALENDAR_WEEK_LABELS,
  SET_CALENDAR_WEEK_LABELS,
  SET_CURRENT_YEAR,
  SET_USER_HAS_NOTES_ACCESS,
  SET_USER_LOCATIONS,
} from "./actionTypes";

export const setWeeklyReportType = (payload: any) => {
  return {
    type: "SET_WEEKLY_REPORT",
    payload: payload,
  };
};

export const setWeekDate = (payload: any) => {
  return {
    type: "SET_WEEK",
    payload: payload,
  };
};

export const setCalendarWeeks = (payload: any) => {
  return {
    type: "SET_CALENDAR_WEEK_DATA",
    payload: payload,
  };
};

export const setRegion = (payload: any) => {
  return {
    type: "SET_REGION",
    payload: payload,
  };
};
export const setDistrict = (payload: any) => {
  return {
    type: "SET_DISTRICT",
    payload: payload,
  };
};

export const setHeaders = (payload: any) => {
  return {
    type: "SET_HEADERS",
    payload: payload,
  };
};

export const setSelectedSort = (payload: any) => {
  return {
    type: "SET_HEADERS",
    payload: payload,
  };
};

export const setLoading = (payload: any) => {
  return {
    type: "SET_LOADING",
    payload: payload,
  };
};

export const setAlertDialog = (payload: any) => {
  return {
    type: "SET_ALERT_DIALOG",
    payload: payload,
  };
};

export const getCalendarWeeks = () => async (dispatch: any) => {
  const res = await APIGetCalendarWeeks();
  dispatch(setCalendarWeeks(res.data.reverse()));
};

export const getCalendarWeekLabels = async (startyear: any) => {
  const { data } = await APIGetCalendarWeekDatesByYear(startyear, startyear);
  const labels = data.map((d: any) => d.WeekEndingDate);
  return {
    type: SET_CALENDAR_WEEK_LABELS,
    payload: labels,
  };
};

export const getAllCalendarWeeks = async (startyear: any, endyear: any) => {
  const { data } = await APIGetCalendarWeekDatesByYear(startyear, endyear);
  return {
    type: SET_ALL_CALENDAR_WEEK_LABELS,
    payload: data,
  };
};

export const getRegions = async () => {
  const res: any = await APIGetAllRegions();
  return setRegions(res);
};

export const getRegionsExceptQcsa = async () => {
  const res: any = await APIGetAllRegionsExceptQcsa();
  return setRegions(res);
};

export const getLocationOrder = async () => {
  const res = await APIGetLocationOrder();
  return {
    type: "SET_LOCATION_ORDER",
    payload: res.data.map((d: any) => d.Location),
  };
};

export const setUserHasNotesAccess = (data: any) => {
  return {
    type: SET_USER_HAS_NOTES_ACCESS,
    payload: data,
  };
};

export const setCurrentYear = (data: string) => {
  return {
    type: SET_CURRENT_YEAR,
    payload: data,
  };
};

export const setUserAccessibleLocations = async (
  data: any,
  year?: any,
  wip?: boolean,
  ytdComparison?: boolean
) => {
  try {
    const res = ytdComparison
      ? await APIGetFinancialYTDComparisonLocations(
          data.map((d: any) => d.name).join(","),
          LOCATION_LEVEL[
            data?.[0]?.level === "Location" ? "Company" : data?.[0]?.level
          ]
        )
      : wip
      ? await APIGetFinancialLocationsWip(
          data.map((d: any) => d.name).join(","),
          LOCATION_LEVEL[
            data?.[0]?.level === "Location" ? "Company" : data?.[0]?.level
          ],
          year
        )
      : await APIGetFinancialLocations(
          data?.name,
          LOCATION_LEVEL[data?.level === "Location" ? "Company" : data?.level],
          year
        );
    return {
      type: SET_USER_LOCATIONS,
      payload: res.data,
    };
  } catch (e) {
    return {
      type: SET_USER_LOCATIONS,
      payload: [],
    };
  }
};

const setRegions = (res: any) => {
  const resWithFixesSubArea = res.data.map((v: any) => ({
    ...v,
    ["Sub Area Name"]: v["Sub Area Name"] ?? "Ungrouped",
  }));
  const rawData = resWithFixesSubArea;
  // const regionTemp = Object.values(
  //   groupByKey(resWithFixesSubArea, "Location")
  // ).map((v: any) => ({
  //   Location: v[0].Location,
  //   Title: v[0].Title,
  //   SubareaName: v[0]["Sub Area Name"],
  // }));
  const regionTemp = Object.values(
    groupByKey(resWithFixesSubArea, "Region")
  ).map((v: any) => ({
    Region: v[0].Region,
    AreaName: v[0]["Areaname"],
    SubAreaName: v[0]["Sub Area Name"],
  }));
  const districtTemp = Object.values(
    groupByKey(resWithFixesSubArea, "District")
  ).map((v: any) => ({
    District: v[0].District,
    Region: v[0].Region,
    AreaName: v[0].Areaname,
    SubAreaName: v[0]["Sub Area Name"],
  }));
  const hierarchyTemp = groupByKey(resWithFixesSubArea, "Areaname");
  let subAreaHierarchy = getHierarchy(hierarchyTemp);
  const hierarchy = subAreaHierarchy;
  const areaTemp: any = Object.keys(hierarchyTemp) ?? [];
  const areaList = areaTemp;
  const subAreaList = Object.keys(
    groupByKey(resWithFixesSubArea, "Sub Area Name")
  );
  const regionsList = regionTemp ?? [];
  const branchesList = res.data.map((v: any) => v.Branch);
  return {
    type: "SET_REGIONS",
    payload: {
      allRegions: rawData,
      hierarchy: hierarchy,
      regionsList: regionsList,
      districtList: districtTemp,
      areaList: areaList,
      subAreaList: subAreaList,
      branchesList: branchesList,
    },
  };
};

const getHierarchy = (data: any) => {
  let subAreaHierarchy = {};
  Object.keys(data).forEach((v: any) => {
    let temp = groupByKey(data[v], "Sub Area Name");
    let subHier = {};
    for (let v in temp) {
      subHier = {
        ...subHier,
        [v]: Object.values(temp[v]).map((v: any) => ({
          District: v.District,
          Region: v.Region,
          SubAreaName: v["Sub Area Name"],
          Area: v.Areaname,
        })),
      };
    }
    subAreaHierarchy = {
      ...subAreaHierarchy,
      [v]: subHier,
    };
  });
  return subAreaHierarchy;
};
