/*
 * Copyright (C) 2023 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect, useState } from "react";
import { groupByKey } from "../../../utils/group";
import {
  percentConverter,
  sortByKeyDailyNumbers,
} from "../../../utils/helper/cellFormatter";
import { ContentLoader } from "../../common/Loader";
import { useSelector } from "react-redux";
import { DAY_OF_THE_WEEK } from "./utils/constants";

const days = DAY_OF_THE_WEEK;
const dayToday = new Date().getDay();
const initialHeaders = ["Calendar Week", "Start Date", "Day Of The Week"];
export const DailyNumberRegionTable = (props: any) => {
  const [branchHeaders, setBranchHeaders] = useState([] as any);
  const [headers, setHeaders] = useState([] as any);
  const [tableData, setTableData] = useState([] as any);
  const [currentDay, setCurrentDay] = useState(dayToday);

  const selectWeekDay = (e: any) => {
    if (props.weekDaysSelected.includes(e.target.value)) {
      props.setWeekDaysSelected(
        [...props.weekDaysSelected].filter((v) => v !== e.target.value)
      );
    } else {
      props.setWeekDaysSelected([...props.weekDaysSelected, e.target.value]);
    }
  };

  const sortOrder = useSelector(
    (state: any) => state.filterReducer.locationOrder
  );

  useEffect(() => {
    if (sortOrder && props.rawHeaders) sortHeaders();
  }, [sortOrder, props.rawHeaders, props.showCheckCuts]);

  useEffect(() => {
    if (props.data?.length > 0) {
      setCurrentDay(props.data[props.data?.length - 1].DayOfTheWeek);

      let exceptions: any = [];
      if (props.totalType === "Region") {
        exceptions = ["AreaTotal", "AreaTotal"];
      } else if (props.totalType === "Area Branch") {
        exceptions = ["AreaTotal", "RegionTotal"];
      } else if (props.totalType === "Area") {
        exceptions = [];
      }
      const filteredData = props.data.filter((v: any) => {
        return !exceptions.includes(v.level);
      });
      const val: any = groupByKey(
        sortByKeyDailyNumbers(filteredData, "StartDate", true),
        "StartDate"
      );
      setTableData(Object.values(val));
    }
  }, [props.data]);

  const sortHeaders = () => {
    const addedHeadersTemp = props.rawHeaders.filter(
      (v: any) =>
        ![
          "CompanyTotal",
          "Region Total",
          "Area Total",
          "VP Total",
          "ADA Company Total",
          "Area Branch Total",
          "All",
          "CalendarWeekCaption",
          "CalendarWeekKey",
          "CalendarYear",
          "StartDate",
          "MidnightDailyNumber",
          "DayOfTheWeek",
          "CheckCuts",
        ].includes(v) && v
    );
    addedHeadersTemp.sort(
      (x: any, y: any) => sortOrder.indexOf(x) - sortOrder.indexOf(y)
    );
    setBranchHeaders(addedHeadersTemp);
    let headersTemp = [...initialHeaders, ...addedHeadersTemp];
    if (props.totalType === "Company") {
      headersTemp = [...headersTemp, "Company Total", "MidNight Company Total"];
    } else if (props.totalType === "ADA Company") {
      headersTemp = [
        ...headersTemp,
        "ADA Company Total",
        "MidNight ADA Company Total",
      ];
    } else {
      headersTemp = [
        ...headersTemp,
        props.totalType === "Subarea" ? "VP Total" : props.totalType + " Total",
        "Company Total",
        "% of Company Total",
      ];
    }
    if (props.showCheckCuts) {
      headersTemp.push("Check Cuts");
      if (props.level === "Company" || props.level === "ADA")
        headersTemp.push("MidNight Company Total vs Check Cuts %");
    }
    setHeaders(headersTemp);
  };

  return (
    <section
      className={
        "daily-number-region-table my-4 relative w-[100%] flex flex-col-reverse md:flex-row gap-1"
      }
    >
      <div className="table-area relative">
        <ContentLoader loading={props.loading} />
        <div
          className={`table-header dnr-row w-fit ${
            props.level === "ADA" || props.level === "District"
              ? "xl:w-full"
              : "2xl:w-full"
          }  `}
        >
          {headers.map((v: string, key: any) => (
            <div className="dnr-col bold " key={key + "header-col"}>
              {v}
            </div>
          ))}
        </div>
        <div className="table-data">
          {tableData
            .filter((v: any) => {
              return (
                props.weekDaysSelected?.length === 0 ||
                props.weekDaysSelected?.length === 7 ||
                props.weekDaysSelected.includes(v[0].DayOfTheWeek)
              );
            })
            .map((v: any, key: number) => (
              <DailyNumberTableRow
                totalType={props.totalType}
                selectedBranch={props.selectedType}
                today={currentDay}
                key={key + "total-row"}
                data={v}
                headers={branchHeaders}
                level={props.level}
                showCheckCuts={props.showCheckCuts}
              />
            ))}
        </div>
      </div>
      <div className="w-[100vw-120px] md:w-[8vw] md:min-w-[150px] px-2">
        <div className="border-[1px] border-black p-2">
          <div className="font-bold pb-1">Week Day</div>
          <div className="flex flex-wrap flex-row justify-between md:flex-col gap-2">
            {days.map((v, key) => (
              <div
                className={"flex align-center filter-day font-semibold"}
                key={"weekday-" + key}
              >
                <div style={{ marginBottom: "-3px", marginRight: "3px" }}>
                  <input
                    type="checkbox"
                    value={v}
                    name={"name"}
                    checked={props.weekDaysSelected.includes(v)}
                    onChange={selectWeekDay}
                  />
                </div>
                <div>{v}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const DailyNumberTableRow = (props: any) => {
  const [percent, setPercent] = useState("0%");
  const [rowData, setrowData] = useState(props.data[0]);

  useEffect(() => {
    setrowData(props.data[0]);
  }, [props.data]);

  useEffect(() => {
    if (props.totalType === "Area Branch") {
      setPercent(
        percentConverter(
          (rowData["Area Branch Total"] ?? 0) / (rowData.CompanyTotal ?? 0)
        )
      );
    } else if (props.totalType === "Region") {
      setPercent(
        percentConverter(
          (rowData["Region Total"] ?? 0) / (rowData.CompanyTotal ?? 0)
        )
      );
    } else if (props.totalType === "Area") {
      setPercent(
        percentConverter(
          (rowData["Area Total"] ?? 0) / (rowData.CompanyTotal ?? 0)
        )
      );
    } else if (props.totalType === "Subarea") {
      setPercent(
        percentConverter(
          (rowData["VP Total"] ?? 0) / (rowData.CompanyTotal ?? 0)
        )
      );
    }
  }, [rowData]);
  return (
    <>
      {props?.data?.length > 0 && (
        <div
          className={`dnr-row w-fit ${
            props.level === "ADA" || props.level === "District"
              ? "xl:w-full"
              : "2xl:w-full"
          }
             ${
               new Date(rowData.StartDate) > new Date()
                 ? "blue"
                 : rowData?.DayOfTheWeek === props.today
                 ? "today "
                 : ""
             }
          `}
        >
          <div className="dnr-col">{rowData["CalendarWeekCaption"]}</div>
          <div className="dnr-col">{rowData["StartDate"]}</div>
          <div className="dnr-col">{rowData["DayOfTheWeek"]}</div>
          {props.headers.map((v: string, key: number) => (
            <div className={`dnr-col`} key={key + "table-row-col"}>
              {rowData[v] ?? 0}
            </div>
          ))}
          {props.totalType === "ADA Company" && (
            <div className="dnr-col">{rowData["ADA Company Total"] ?? 0}</div>
          )}

          {props.totalType === "Area Branch" && (
            <div className="dnr-col">{rowData["Area Branch Total"] ?? 0}</div>
          )}
          {props.totalType === "Region" && (
            <div className="dnr-col">{rowData["Region Total"] ?? 0}</div>
          )}
          {props.totalType === "Area" && (
            <div className="dnr-col">{rowData["Area Total"] ?? 0}</div>
          )}
          {props.totalType === "Subarea" && (
            <div className="dnr-col">{rowData["VP Total"] ?? 0}</div>
          )}
          {props.level !== "ADA" && (
            <div className="dnr-col"> {rowData.CompanyTotal ?? 0}</div>
          )}
          {props.level !== "Company" && props.level !== "ADA" && (
            <div className="dnr-col">{percent}</div>
          )}
          {props.level === "Company" && (
            <div className="dnr-col bg-gray">
              {rowData.MidnightDailyNumber ?? 0}
            </div>
          )}
          {props.level === "ADA" && (
            <div className="dnr-col bg-gray">
              {rowData.MidnightDailyNumber ?? 0}
            </div>
          )}
          {props.showCheckCuts && (
            <div className="dnr-col">{rowData.CheckCuts ?? ""}</div>
          )}
          {(props.level === "Company" || props.level === "ADA") &&
            props.showCheckCuts && (
              <div className="dnr-col">
                {rowData.MidnightDailyNumber && rowData.CheckCuts
                  ? (
                      (rowData.MidnightDailyNumber / rowData.CheckCuts) *
                      100
                    ).toFixed(2) + "%"
                  : 0}
              </div>
            )}
        </div>
      )}
    </>
  );
};
