/*
 * Copyright (C) 2024 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect, useState } from "react";
import BranchTopFocusSelector from "../components/modules/branchTopFocus/BranchTopFocusSelector";
import BranchTopFocusTables from "../components/modules/branchTopFocus/BranchTopFocusTables";
import BranchTopFocusChart from "../components/modules/branchTopFocus/BranchTopFocusChart";
import {
  APIFetchADABranches,
  APIFetchBarChartAndLineChart,
  APIFetchBarChartAndLineChartADA,
  APIFetchBarChartAndLineChartCompany,
  APIFetchBarChartLineChartADABranches,
  APIFetchBranch,
  APIFetchClientCommentsADA,
  APIFetchClientCommentsCompanyLevel,
  APIFetchClientCommentsMetrics,
  APIFetchEmployeePlaced,
  APIFetchEmployeePlacedADA,
  APIFetchEmployeePlacedCompanyLevel,
} from "../api/branch-top-focus";
import { groupByKey } from "../utils/group";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setLoading } from "../store/Filter/actions";
import { Loader } from "../components/common/Loader";
import HomeButton from "../components/common/HomeButton";
import {
  IChart,
  IClientComment,
  IEmployeePlaced,
} from "../types/branchTopFocus";

const BranchTopFocus = (props: any) => {
  const [areaList, setAreaList] = useState<string[]>([]);
  const [subAreaList, setSubAreaList] = useState<string[]>([]);
  const [regionsList, setRegionsList] = useState([]);
  const [districtsList, setDistrictssList] = useState([]);
  const [hierarchy, setHierarchy] = useState({} as any);
  const [level, setLevel] = useState(props.adaOnly ? "ADA" : "Company");
  const [selectedLevelBranch, setSelectedLevelBranch] = useState<string>(
    props.adaOnly ? "ADA_Branch" : "Company"
  );
  const [subAreaSelected, setSubAreaSelected] = useState<string | null>(null);
  const [areaSelected, setAreaSelected] = useState<string | null>(null);
  const [regionSelected, setRegionSelected] = useState<string | null>(null);
  const [districtSelected, setDistrictSelected] = useState<string | null>(null);
  const [branchesList, setBranchesList] = useState<string[]>([]);
  const [clientList, setClientList] = useState<string[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<string>("All");
  const [selectedClientName, setSelectedClientName] = useState<string>("All");
  const [clientComment, setClientComment] = useState<IClientComment[]>([]);
  const [employeePlaced, setEmployeePlaced] = useState<IEmployeePlaced[]>([]);
  const [chartData, setChartData] = useState<IChart[]>([]);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startdate: moment(new Date()).add("-29", "days").format("yyyy-MM-DD"),
    enddate: moment(new Date()).format("yyyy-MM-DD"),
  });
  const [chartAreaSelected, setChartAreaSelected] = useState(null);
  const [chartSubAreaSelected, setChartSubAreaSelected] = useState(null);
  const [chartRegionSelected, setChartRegionSelected] = useState(null);
  const [chartDistrictSelected, setChartDistrictSelected] = useState(null);
  const [barchartLoading, setBarchartLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(setLoading(true));
      if (level === "ADA") {
        await APIFetchClientCommentsADA().then((res) => {
          setClientComment(res.data);
        });
        await APIFetchEmployeePlacedADA().then((res) => {
          setEmployeePlaced(res.data);
        });
      } else {
        if (
          areaSelected ||
          subAreaSelected ||
          regionSelected ||
          districtSelected
        ) {
          await APIFetchClientCommentsMetrics(
            areaSelected ? areaSelected : "All",
            subAreaSelected ? subAreaSelected : "",
            regionSelected ? regionSelected : "",
            districtSelected ? districtSelected : ""
          ).then((res) => {
            setClientComment(res.data);
          });
          await APIFetchEmployeePlaced(
            areaSelected ? areaSelected : "All", //areaSelected is all when no area is selected to eliminate qcsa branch
            subAreaSelected ? subAreaSelected : "",
            regionSelected ? regionSelected : "",
            districtSelected ? districtSelected : ""
          ).then((res) => {
            setEmployeePlaced(res.data);
          });
        } else {
          await APIFetchClientCommentsCompanyLevel().then((res) => {
            setClientComment(res.data);
          });
          await APIFetchEmployeePlacedCompanyLevel().then((res) => {
            setEmployeePlaced(res.data);
          });
        }
      }

      dispatch(setLoading(false));
    })();
  }, [areaSelected, subAreaSelected, regionSelected, districtSelected, level]);

  useEffect(() => {
    document.title = "Branch Top Focus";
  }, []);

  useEffect(() => {
    level === "ADA"
      ? APIFetchADABranches().then((res: any) => {
          setBranchesList(res.data.map((d: any) => d.Branch));
        })
      : APIFetchBranch(
          chartDistrictSelected
            ? chartDistrictSelected
            : chartRegionSelected
            ? chartRegionSelected
            : chartSubAreaSelected
            ? chartSubAreaSelected
            : chartAreaSelected
            ? chartAreaSelected
            : districtSelected
            ? districtSelected
            : regionSelected
            ? regionSelected
            : subAreaSelected
            ? subAreaSelected
            : areaSelected
            ? areaSelected
            : "All"
        ).then((res: any) => {
          setBranchesList(res.data.map((d: any) => d.branch));
        });
  }, [
    areaSelected,
    subAreaSelected,
    regionSelected,
    districtSelected,
    chartAreaSelected,
    chartSubAreaSelected,
    chartRegionSelected,
    chartRegionSelected,
    level,
  ]);

  //API fetch for barchart and linechart
  useEffect(() => {
    (async () => {
      setBarchartLoading(true);
      if (level === "ADA") {
        selectedBranch === "All"
          ? APIFetchBarChartAndLineChartADA().then((res) => {
              selectedBranch === "All" &&
                setClientList(Object.keys(groupByKey(res.data, "ClientName")));
              setChartData(res.data);
            })
          : APIFetchBarChartLineChartADABranches(selectedBranch).then((res) => {
              selectedBranch === "All" &&
                setClientList(Object.keys(groupByKey(res.data, "ClientName")));
              setChartData(res.data);
            });
      } else {
        if (
          chartAreaSelected ||
          chartSubAreaSelected ||
          chartRegionSelected ||
          chartDistrictSelected ||
          selectedBranch !== "All"
        ) {
          await APIFetchBarChartAndLineChart(
            selectedBranch !== "All" ? selectedBranch : "",
            selectedBranch === "All" && chartAreaSelected
              ? chartAreaSelected
              : "All",
            selectedBranch === "All" && chartSubAreaSelected
              ? chartSubAreaSelected
              : "",
            selectedBranch === "All" && chartRegionSelected
              ? chartRegionSelected
              : "",
            selectedBranch === "All" && chartDistrictSelected
              ? chartDistrictSelected
              : ""
          ).then((res) => {
            selectedBranch === "All" &&
              // setBranchesList(Object.keys(groupByKey(res.data, "Branch")));
              setClientList(Object.keys(groupByKey(res.data, "ClientName")));
            setChartData(res.data);
          });
        } else {
          await APIFetchBarChartAndLineChartCompany().then((res) => {
            selectedBranch === "All" &&
              // setBranchesList(Object.keys(groupByKey(res.data, "Branch")));
              setClientList(Object.keys(groupByKey(res.data, "ClientName")));
            setChartData(res.data);
          });
        }
      }
      setBarchartLoading(false);
    })();
  }, [
    chartAreaSelected,
    chartSubAreaSelected,
    chartRegionSelected,
    chartDistrictSelected,
    selectedBranch,
    level,
  ]);

  //date filter
  const filteredDataByDate = (data: any) => {
    return selectedDateRange.startdate === null &&
      selectedDateRange.enddate === null
      ? data
      : data.filter(
          (d: any) =>
            d.date >= (selectedDateRange.startdate ?? "") &&
            d.date <= (selectedDateRange.enddate ?? "")
        );
  };

  //To count total values for each branch
  const countTotal = (data: any[]) => {
    return Object.entries(
      data.flat().reduce((counts: any, entry: any) => {
        const branch = entry.Branch;
        const count = entry.Total_no_of_clients || entry.EmployeePlaced || 0;
        counts[branch] = (counts[branch] || 0) + count;
        return counts;
      }, {})
    ).map(([branch, total]) => ({ branch, total }));
  };

  let totalClientCommentCounts = countTotal(
    Object.values(filteredDataByDate(clientComment))
  );
  let totalEmployeePlacedCounts = countTotal(
    Object.values(filteredDataByDate(employeePlaced))
  );

  return (
    <main className="h-[100vh] w-[100vw] ">
      <section className="w-[100%] h-[100%] flex">
        <div className="h-full flex-grow">
          <div className=" flex flex-col gap-2">
            <HomeButton adaOnly={props.adaOnly} />
            <div className="flex flex-col h-screen w-screen bg-[#e6f1f1] overflow-x-hidden">
              <div className="py-2  bg-[#cddfdb] w-screen  text-blue-900 text-lg font-bold text-center">
                Branch Top Focus
              </div>
              <BranchTopFocusSelector
                selectedLevelBranch={selectedLevelBranch}
                setSelectedLevelBranch={setSelectedLevelBranch}
                hierarchy={hierarchy}
                setHierarchy={setHierarchy}
                areaList={areaList}
                setAreaList={setAreaList}
                subAreaList={subAreaList}
                setSubAreaList={setSubAreaList}
                regionsList={regionsList}
                setRegionsList={setRegionsList}
                districtList={districtsList}
                setDistrictList={setDistrictssList}
                branchesList={branchesList}
                setBranchesList={setBranchesList}
                areaSelected={areaSelected}
                setAreaSelected={setAreaSelected}
                subAreaSelected={subAreaSelected}
                setSubAreaSelected={setSubAreaSelected}
                setRegionSelected={setRegionSelected}
                setDistrictSelected={setDistrictSelected}
                setSelectedBranch={setSelectedBranch}
                setSelectedClientName={setSelectedClientName}
                setLevel={setLevel}
                level={level}
                chartAreaSelected={chartAreaSelected}
                chartRegionSelected={chartRegionSelected}
                chartSubAreaSelected={chartSubAreaSelected}
                chartDistrictSelected={chartDistrictSelected}
                setChartAreaSelected={setChartAreaSelected}
                setChartRegionSelected={setChartRegionSelected}
                setChartSubAreaSelected={setChartSubAreaSelected}
                setChartDistrictSelected={setChartDistrictSelected}
                adaOnly={props.adaOnly}
                isAscendAdaAccess={props.isAscendAdaAccess}
              />
              <div className="flex flex-col justify-between gap-6 mx-[8vw] h-[75vh]">
                <BranchTopFocusTables
                  totalClientCommentCounts={totalClientCommentCounts}
                  totalEmployeePlacedCounts={totalEmployeePlacedCounts}
                  selectedDateRange={selectedDateRange}
                  setSelectedDateRange={setSelectedDateRange}
                  level={level}
                />
                <BranchTopFocusChart
                  level={level}
                  areaSelected={areaSelected}
                  subAreaSelected={subAreaSelected}
                  regionSelected={regionSelected}
                  districtSelected={districtSelected}
                  setAreaSelected={setAreaSelected}
                  setSubAreaSelected={setSubAreaSelected}
                  setRegionSelected={setRegionSelected}
                  setDistrictSelected={setDistrictSelected}
                  chartAreaSelected={chartAreaSelected}
                  chartSubAreaSelected={chartSubAreaSelected}
                  chartDistrictSelected={chartDistrictSelected}
                  chartRegionSelected={chartRegionSelected}
                  setChartAreaSelected={setChartAreaSelected}
                  setChartSubAreaSelected={setChartSubAreaSelected}
                  setChartDistrictSelected={setChartDistrictSelected}
                  setChartRegionSelected={setChartRegionSelected}
                  selectedLevelBranch={selectedLevelBranch}
                  setSelectedLevelBranch={setSelectedLevelBranch}
                  areaList={areaList}
                  subareaList={subAreaList}
                  regionsList={regionsList}
                  districtList={districtsList}
                  branchesList={branchesList}
                  clientList={clientList}
                  selectedBranch={selectedBranch}
                  setSelectedBranch={setSelectedBranch}
                  selectedClientName={selectedClientName}
                  setSelectedClientName={setSelectedClientName}
                  chartData={chartData}
                  barchartLoading={barchartLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Loader />
    </main>
  );
};

export default BranchTopFocus;
