/*
 * Copyright (C) 2025 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { useEffect, useState } from "react";
import HomeButton from "../components/common/HomeButton";
import SalesManagerSelector from "../components/modules/salesManager/SalesManagerSelector";
import { useDispatch, useSelector } from "react-redux";
import { MONTH_LIST } from "../utils/helper/constants";
import { Select } from "@mantine/core";
import { APIFetchData, APIFetchLatestMonthYear } from "../api/income-model";
import { IIncomeModelData } from "../types/incomeModel";
import { handleError } from "../utils/helper/handleError";
import { setLoading } from "../store/Filter/actions";
import { Loader } from "../components/common/Loader";
import { useFetch } from "../utils/hooks/useFetch";
import { groupByKey } from "../utils/group";
import IncomeModelTable from "../components/modules/incomeModel/IncomeModelTable";

const initialState = {
  branch: "All",
  levelBranch: "Company",
  level: "Company",
  area: null,
  subarea: null,
  region: null,
  district: null,
};

const IncomeModel = () => {
  const dispatch = useDispatch();
  const [areaList, setAreaList] = useState<string[]>([]);
  const [subAreaList, setSubAreaList] = useState<string[]>([]);
  const [regionsList, setRegionsList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [_level, setLevel] = useState<string>(initialState.level);
  const [hierarchy, setHierarchy] = useState({});
  const [selectedLevelBranch, setSelectedLevelBranch] = useState<string>(
    initialState.levelBranch
  );
  const [areaSelected, setAreaSelected] = useState(initialState.area);
  const [subAreaSelected, setSubAreaSelected] = useState(initialState.subarea);
  const [regionSelected, setRegionSelected] = useState(initialState.region);
  const [districtSelected, setDistrictSelected] = useState(
    initialState.district
  );
  const [_selectedBranch, setSelectedBranch] = useState<string>(
    initialState.branch
  );
  const [data, setData] = useState<Record<string, IIncomeModelData[]> | null>(
    null
  );

  const currentYear = useSelector(
    (state: any) => state.filterReducer.currentYear
  );
  const [selectedFilter, setSelectedFilter] = useState<{
    year: string | null;
    month: string | null;
  }>({ year: null, month: null });
  const [latestMonthYear, setLatestMonthYear] = useState<{
    year: string | null;
    month: string | null;
  }>({ year: null, month: null });

  const years = Array.from({ length: 2 }, (_, i) =>
    (currentYear - i).toString()
  );

  useEffect(() => {
    document.title = "Income Model";
  }, []);

  useFetch(
    APIFetchLatestMonthYear,
    [],
    (data) => {
      setLatestMonthYear({
        year: data[0]?.Calendaryear.toString(),
        month: data[0]?.LatestMonthName,
      });
      setSelectedFilter({
        year: data[0]?.Calendaryear.toString(),
        month: data[0]?.LatestMonthName,
      });
    },
    []
  );

  const fetchIncomeModelData = async () => {
    try {
      dispatch(setLoading(true));
      const res = await APIFetchData(
        selectedFilter.year ?? "",
        selectedFilter.month ?? "",
        selectedLevelBranch === "Company" ? "All" : selectedLevelBranch
      );
      const groupedSubareaData = groupByKey(res.data, "Sub Area Name");
      const groupedRegionData = groupByKey(res.data, "Region");
      const groupedDistrictData = groupByKey(res.data, "District");

      setData(
        districtSelected
          ? groupedDistrictData
          : areaSelected
          ? {
              ...groupedSubareaData,
              ...groupedRegionData,
              ...groupedDistrictData,
            }
          : {
              ...groupedRegionData,
              ...groupedDistrictData,
            }
      );
    } catch {
      handleError("Error fetching data");
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (selectedFilter.month && selectedFilter.year) {
      fetchIncomeModelData();
    }
  }, [selectedFilter, selectedLevelBranch]);

  //Divide table data and total data from main data
  const getFilteredData = (regionData: IIncomeModelData[]) => {
    const tableData = regionData.filter((data) => data.Branch);

    const regionTotalData = regionData.filter((data) => data.Branch === null);
    const regionWithDistrictData =
      regionTotalData.length > 1
        ? regionTotalData.filter((data) => data.District === null)
        : regionTotalData;
    const totalData =
      regionWithDistrictData.length > 1
        ? regionWithDistrictData.filter((data) => data.Region === null)
        : regionWithDistrictData;

    return { tableData, totalData };
  };

  const resetFilters = () => {
    setSelectedLevelBranch(initialState.levelBranch);
    setLevel(initialState.level);
    setAreaSelected(initialState.area);
    setSubAreaSelected(initialState.subarea);
    setRegionSelected(initialState.region);
    setDistrictSelected(initialState.district);
    setSelectedFilter({
      year: latestMonthYear.year,
      month: latestMonthYear.month,
    });
  };

  return (
    <main className="h-screen w-screen overflow-hidden flex flex-col bg-[#E6F1F1]  ">
      <Loader />
      <header className="flex justify-between bg-[#cddfdb] items-center">
        <HomeButton resetFilters={resetFilters} />
        <div className="pt-1 pb-3  w-screen  text-blue-900 text-lg font-bold pl-16">
          Income Model
        </div>
      </header>
      <section className="flex flex-col pb-2 px-2 md:max-xl:px-8 xl:px-20 gap-1 h-[93vh] xl:h-[96vh] overflow-y-auto">
        <SalesManagerSelector
          setLevel={setLevel}
          hierarchy={hierarchy}
          setHierarchy={setHierarchy}
          areaList={areaList}
          setAreaList={setAreaList}
          subAreaList={subAreaList}
          setSubAreaList={setSubAreaList}
          regionsList={regionsList}
          setRegionsList={setRegionsList}
          districtList={districtList}
          setDistrictList={setDistrictList}
          selectedLevelBranch={selectedLevelBranch}
          setSelectedLevelBranch={setSelectedLevelBranch}
          areaSelected={areaSelected}
          setAreaSelected={setAreaSelected}
          subAreaSelected={subAreaSelected}
          setSubAreaSelected={setSubAreaSelected}
          regionSelected={regionSelected}
          setRegionSelected={setRegionSelected}
          setDistrictSelected={setDistrictSelected}
          setSelectedBranch={setSelectedBranch}
          selectorColor={"bg-[#288CBF]"}
        />
        <div className="flex justify-between items-end">
          <div className="flex gap-2">
            <div className="py-1 bg-white  text-center text-xs xl:text-sm font-semibold border-[1px] border-stone-400 w-[70px] xl:w-[100px] rounded-sm">
              {selectedFilter.month}
            </div>
            <div className="py-1 bg-white  text-center text-xs xl:text-sm font-semibold border-[1px] border-stone-400 w-[70px] xl:w-[100px] rounded-sm">
              {selectedFilter.year}
            </div>
          </div>
          <div className="flex gap-2">
            <Select
              className="w-32 lg:w-40"
              value={selectedFilter.year}
              onChange={(value: string) =>
                setSelectedFilter((prev: any) => ({ ...prev, year: value }))
              }
              data={years}
              size="xs"
              label="Year"
            />
            <Select
              className="w-32 lg:w-40"
              value={selectedFilter.month}
              onChange={(value: string) =>
                setSelectedFilter((prev: any) => ({ ...prev, month: value }))
              }
              data={MONTH_LIST}
              size="xs"
              label="Month"
            />
          </div>
        </div>

        <div className="mt-4 flex flex-col gap-8 xl:gap-6">
          {data &&
            Object.entries(data)
              .filter(([region]) => region && region !== "null")
              .map(([region, regionData]) => {
                const { tableData, totalData } = getFilteredData(regionData);

                return (
                  <IncomeModelTable
                    key={region}
                    region={region}
                    tableData={tableData}
                    totalData={totalData}
                  />
                );
              })}
        </div>
      </section>
    </main>
  );
};

export default IncomeModel;
