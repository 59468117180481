import { Table } from "@mantine/core";
import {
  getMonthWiseSumOfData,
  getPerOverLastYear,
} from "../goalSetting/utils/tableFormulae";
import { TABLE_MONTH_HEADERS } from "../../../utils/mock-data/financial-report";
import {
  HIGHLIGHTED_PARTICULARS,
  INPUT_ROWS_WIP,
  ITEMS,
  NULL_CELLS,
  NULL_ITEMS,
  PerOverLYRows,
  YTDCALC_NULL_CELLS,
} from "../goalSetting/utils/constants";
import {
  formatAmount,
  formatGoalPercentValue,
} from "../../../utils/helper/cellFormatter";
import { Fragment } from "react/jsx-runtime";

const TableComponentWip = (props: any) => {
  const {
    tableHeaders,
    tableData,
    activeCell,
    setActiveCell,
    branchSelected,
    level,
    editedData,
    calcData,
    calcDataInputRows,
    highlight,
    setHighlight,
    handleChangeRowData,
    getHighlightStyle,
    handleHilight,
    handleInputChange,
  } = props;

  const handleFocus = (
    branchSelected: string,
    categories: string,
    items: string,
    header: string
  ) => {
    setActiveCell((prevState: any) => ({
      branch: branchSelected,
      category: categories,
      item: items,
      header: prevState.header ? [...prevState.header, header] : [header],
    }));
    handleHilight(branchSelected, categories, items, header);
  };

  const handleKeyDown = (
    e: any,
    branchSelected: string,
    categories: string,
    items: string,
    header: string
  ) => {
    if (e.key === "Delete") {
      handleInputChange(branchSelected, categories, items, header, "");
    } else if (e.key === "Tab") {
      handleChangeRowData(branchSelected, categories, items);
    } else if (e.key === "Enter") {
      handleChangeRowData(branchSelected, categories, items);
      setHighlight({
        branch: null,
        category: null,
        item: null,
        header: null,
      });
    }
  };

  const getClassName = (items: string, level: string, nullValueRows: any) => {
    return `flex justify-between sticky left-0 z-20  ${
      HIGHLIGHTED_PARTICULARS.includes(items.trim()) || nullValueRows
        ? "font-bold"
        : ""
    } ${
      items === ITEMS.STAFFINGMANAGER
        ? "bg-[#ffd0a3]"
        : items === ITEMS.INCOMEMODEL
        ? "bg-[#CCC0DA]"
        : HIGHLIGHTED_PARTICULARS.includes(items.trim()) && level !== "Branch"
        ? "bg-tertiary text-white"
        : "bg-white"
    } `;
  };
  const getFormattedValue = (
    level: string,
    data: any,
    header: string,
    calculatedRow: any
  ) => {
    const value = level === "Branch" ? calculatedRow[header] : data[header];
    const items = data.items;

    if (items === ITEMS.STAFFINGMANAGER) {
      return parseFloat(value).toFixed(2);
    } else if (items === ITEMS.INCOMEMODELPERC) {
      return formatGoalPercentValue(value);
    } else if (items === ITEMS.UNDERINCOMEMODEL && level !== "Branch") {
      return value.toFixed(2);
    } else {
      return formatAmount(value);
    }
  };

  return (
    <Table
      fontSize={11}
      verticalSpacing={3}
      withBorder
      withColumnBorders
      horizontalSpacing={2}
    >
      <thead>
        <tr>
          <th className="min-w-[140px] sticky left-0 top-0 z-30 bg-white"></th>
          {tableHeaders.map((header: string, index: number) => {
            return (
              <th
                key={index}
                className={`text-center sticky top-0 bg-white z-10 h-8 ${
                  header.length === 0 ? "min-w-[5px]" : "min-w-[65px]"
                }`}
              >
                {header}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="goal-table">
        {tableData && tableData.length !== 0 ? (
          tableData
            .filter((data: any) =>
              level === "Branch"
                ? isNaN(parseFloat(data.items)) &&
                  data.items !== ITEMS.ACQUISITION_EXPENSE
                : isNaN(parseFloat(data.items)) &&
                  !NULL_ITEMS.includes(data.items)
            )
            .map((data: any, index: number) => {
              const currentYTD = getMonthWiseSumOfData(
                data,
                TABLE_MONTH_HEADERS()
              );

              const perOverLastYear = getPerOverLastYear(
                currentYTD,
                data.lastyeargoal
              );

              const nullValueRows = data.items.includes(":");
              const nullValueCells = NULL_CELLS.includes(data.items);
              const nullValueCellsYTDCalc = YTDCALC_NULL_CELLS.includes(
                data.items
              );
              const calculatedRow: any =
                calcData.find(
                  (d: any) =>
                    d.items === data.items && d.Categories === data.Categories
                ) ?? "";
              const calculatedInputRow: any =
                calcDataInputRows.find(
                  (d: any) =>
                    d.items === data.items && d.Categories === data.Categories
                ) ?? "";

              const isCategoryChanged =
                index > 0 &&
                data.Categories !== tableData[index - 1].Categories;

              const perOverlyvalue =
                nullValueRows ||
                nullValueCells ||
                !PerOverLYRows.includes(data.items)
                  ? null
                  : level === "Branch"
                  ? INPUT_ROWS_WIP.includes(data.items.trim())
                    ? formatGoalPercentValue(perOverLastYear.toString())
                    : formatGoalPercentValue(calculatedRow["Peroverlastyear"])
                  : formatGoalPercentValue(data["Peroverlastyear"]);

              return (
                <Fragment key={index}>
                  {isCategoryChanged && (
                    <tr className="">
                      <td className="bg-white h-7" colSpan={12}></td>
                    </tr>
                  )}
                  <tr
                    className={` ${
                      level === "Branch" &&
                      HIGHLIGHTED_PARTICULARS.includes(data.items.trim())
                        ? "border-y-[3px] border-stone-400"
                        : "border-y-[0px]"
                    }`}
                  >
                    <td
                      key={data.items}
                      className={getClassName(data.items, level, nullValueRows)}
                      style={{ paddingLeft: 5 }}
                    >
                      {data.items}
                    </td>
                    {TABLE_MONTH_HEADERS().map(
                      (header: string, index: number) => {
                        const editedRow = editedData.find(
                          (d: any) =>
                            d.Branch === branchSelected &&
                            d.items === data.items &&
                            d.Categories === data.Categories
                        )?.[header];

                        return (
                          <td
                            key={index}
                            className={`${
                              data.items === ITEMS.UNDERINCOMEMODEL
                                ? "bg-white"
                                : data.items === ITEMS.NETSALES &&
                                  level === "Branch"
                                ? "bg-[#fceec5]"
                                : data.items === ITEMS.STAFFINGMANAGER
                                ? "bg-[#ffd0a3]"
                                : data.items === ITEMS.INCOMEMODEL
                                ? "bg-[#CCC0DA]"
                                : HIGHLIGHTED_PARTICULARS.includes(
                                    data.items.trim()
                                  ) && level !== "Branch"
                                ? "bg-tertiary text-white"
                                : "bg-white"
                            }`}
                            style={getHighlightStyle(
                              branchSelected,
                              data.Categories,
                              data.items,
                              header
                            )}
                          >
                            {INPUT_ROWS_WIP.includes(data.items) &&
                            level === "Branch" ? (
                              (activeCell.branch === data.Branch &&
                                activeCell.category === data.Categories &&
                                activeCell.item === data.items &&
                                activeCell.header?.includes(header)) ||
                              (highlight.branch === data.Branch &&
                                highlight.category === data.Categories &&
                                highlight.item === data.items &&
                                highlight.header === header) ? (
                                <input
                                  type="number"
                                  style={{ border: "none" }}
                                  className={`w-[65px]  ${
                                    data.items === ITEMS.UNDERINCOMEMODEL
                                      ? "bg-white"
                                      : "bg-[#fceec5]"
                                  }`}
                                  onFocus={() =>
                                    handleHilight(
                                      branchSelected,
                                      data.Categories,
                                      data.items,
                                      header
                                    )
                                  }
                                  value={
                                    editedRow !== undefined
                                      ? editedRow
                                      : data[header]
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      branchSelected,
                                      data.Categories,
                                      data.items,
                                      header,
                                      e.target.value
                                    )
                                  }
                                  onBlur={(e) => {
                                    handleChangeRowData(
                                      branchSelected,
                                      data.Categories,
                                      data.items
                                    );
                                    setHighlight({
                                      branch: null,
                                      category: null,
                                      item: null,
                                      header: null,
                                    });
                                  }}
                                  onKeyDown={(e) =>
                                    handleKeyDown(
                                      e,
                                      branchSelected,
                                      data.Categories,
                                      data.items,
                                      header
                                    )
                                  }
                                />
                              ) : (
                                <input
                                  style={{ border: "none" }}
                                  className={` w-[65px] ${
                                    data.items === ITEMS.UNDERINCOMEMODEL
                                      ? "bg-white"
                                      : "bg-[#fceec5]"
                                  } readonly `}
                                  onFocus={() =>
                                    data.items === ITEMS.NETSALES //Make input field readonly for netsales
                                      ? null
                                      : handleFocus(
                                          branchSelected,
                                          data.Categories,
                                          data.items,
                                          header
                                        )
                                  }
                                  onClick={() =>
                                    data.items === ITEMS.NETSALES
                                      ? null
                                      : handleFocus(
                                          branchSelected,
                                          data.Categories,
                                          data.items,
                                          header
                                        )
                                  }
                                  readOnly
                                  value={`${
                                    data.items === ITEMS.UNDERINCOMEMODEL
                                      ? data[header] ?? 0
                                      : formatAmount(data[header])
                                  }`}
                                />
                              )
                            ) : (
                              <div
                                onMouseDown={() =>
                                  handleHilight(
                                    branchSelected,
                                    data.Categories,
                                    data.items,
                                    header
                                  )
                                }
                              >
                                {nullValueRows
                                  ? null
                                  : getFormattedValue(
                                      level,
                                      data,
                                      header,
                                      calculatedRow
                                    )}
                              </div> //Month wise calculated values
                            )}
                          </td>
                        );
                      }
                    )}

                    <td
                      className={`${
                        data.items === ITEMS.INCOMEMODEL
                          ? "bg-[#CCC0DA]"
                          : HIGHLIGHTED_PARTICULARS.includes(
                              data.items.trim()
                            ) && level !== "Branch"
                          ? "bg-tertiary text-white"
                          : "bg-white"
                      }`}
                      style={getHighlightStyle(
                        branchSelected,
                        data.Categories,
                        data.items,
                        "YTD"
                      )}
                      onMouseDown={() =>
                        handleHilight(
                          branchSelected,
                          data.Categories,
                          data.items,
                          "YTD"
                        )
                      }
                    >
                      {nullValueRows || nullValueCells
                        ? null
                        : level === "Branch"
                        ? INPUT_ROWS_WIP.includes(data.items.trim())
                          ? formatAmount(calculatedInputRow["YTD"])
                          : formatAmount(calculatedRow["YTD"])
                        : formatAmount(data["YTD"])}
                    </td>
                    <td
                      className={`  ${
                        HIGHLIGHTED_PARTICULARS.includes(data.items.trim()) &&
                        level !== "Branch"
                          ? "bg-tertiary text-white"
                          : "bg-white"
                      }`}
                      style={getHighlightStyle(
                        branchSelected,
                        data.Categories,
                        data.items,
                        "YTD %"
                      )}
                      onMouseDown={() =>
                        handleHilight(
                          branchSelected,
                          data.Categories,
                          data.items,
                          "YTD %"
                        )
                      }
                    >
                      {nullValueRows || nullValueCells
                        ? null
                        : level === "Branch"
                        ? INPUT_ROWS_WIP.includes(data.items.trim())
                          ? formatGoalPercentValue(calculatedInputRow["YTD %"])
                          : formatGoalPercentValue(calculatedRow["YTD %"])
                        : formatGoalPercentValue(data["YTD %"])}
                    </td>
                    <td
                      className={` ${
                        HIGHLIGHTED_PARTICULARS.includes(data.items.trim()) &&
                        level !== "Branch"
                          ? "bg-tertiary text-white"
                          : perOverLastYear > 0
                          ? "text-green-500"
                          : perOverLastYear < 0
                          ? "text-red-500"
                          : "bg-white"
                      }`}
                      style={getHighlightStyle(
                        branchSelected,
                        data.Categories,
                        data.items,
                        "Peroverlastyear"
                      )}
                      onMouseDown={() =>
                        handleHilight(
                          branchSelected,
                          data.Categories,
                          data.items,
                          "Peroverlastyear"
                        )
                      }
                    >
                      {perOverlyvalue}
                    </td>
                    {level === "Branch" && (
                      <>
                        <td
                          className={`  ${
                            HIGHLIGHTED_PARTICULARS.includes(
                              data.items.trim()
                            ) && level !== "Branch"
                              ? "bg-tertiary text-white"
                              : "bg-white"
                          }`}
                          style={getHighlightStyle(
                            branchSelected,
                            data.Categories,
                            data.items,
                            "YTD_Amount_dollar"
                          )}
                          onMouseDown={() =>
                            handleHilight(
                              branchSelected,
                              data.Categories,
                              data.items,
                              "YTD_Amount_dollar"
                            )
                          }
                        >
                          {nullValueRows ||
                          nullValueCells ||
                          nullValueCellsYTDCalc
                            ? null
                            : level === "Branch"
                            ? INPUT_ROWS_WIP.includes(data.items.trim())
                              ? formatAmount(
                                  calculatedInputRow["YTD_Amount_dollar"]
                                )
                              : formatAmount(calculatedRow["YTD_Amount_dollar"])
                            : formatAmount(data["YTD_Amount_dollar"])}{" "}
                        </td>
                        <td
                          className={`  ${
                            HIGHLIGHTED_PARTICULARS.includes(
                              data.items.trim()
                            ) && level !== "Branch"
                              ? "bg-tertiary text-white"
                              : "bg-white"
                          }`}
                          style={getHighlightStyle(
                            branchSelected,
                            data.Categories,
                            data.items,
                            "YTD_Calc_perc"
                          )}
                          onMouseDown={() =>
                            handleHilight(
                              branchSelected,
                              data.Categories,
                              data.items,
                              "YTD_Calc_perc"
                            )
                          }
                        >
                          {" "}
                          {nullValueRows ||
                          nullValueCells ||
                          nullValueCellsYTDCalc
                            ? null
                            : level === "Branch"
                            ? INPUT_ROWS_WIP.includes(data.items.trim())
                              ? formatGoalPercentValue(
                                  calculatedInputRow["YTD_Calc_perc"]
                                )
                              : formatGoalPercentValue(
                                  calculatedRow["YTD_Calc_perc"]
                                )
                            : formatGoalPercentValue(data["YTD_Calc_perc"])}
                        </td>
                      </>
                    )}
                  </tr>
                </Fragment>
              );
            })
        ) : (
          <tr className="h-[80vh] text-center border-t-2">
            <td colSpan={tableHeaders.length}>No Data </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default TableComponentWip;
