import moment from "moment";

export const ColorPalette: Record<string, string> = {
  "LTS Stage 1: Leads": "#446FAD",
  "LTS Stage 2: Qualify": "#305d79",
  "LTS Stage 3: Company Research": "#224855",
  "SPM Stage 1: Discovery Appointment": "#2c2755",
  "SPM Stage 2: Sales Negotiations": "#554c22",
  "SPM Stage 3: Sales Closing Process": "#552243",
  "Sales Prospect": "#4e2c0b",
  "CMS Not Invoiced": "#4B527E",
  "CMS Invoiced": "#291611",
};

export const Timeline_Filters = [
  {
    label: "Daily",
    date: {
      startDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
  },
  {
    label: "Monthly",
    date: {
      startDate: moment().subtract(29, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
  },
  {
    label: "Weekly",
    date: {
      startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
  },
];

export const INVOICING_STAGE = 8;

export const Kpi_Table_Headers: { label: string; value: string }[] = [
  { label: "Manager", value: "Manager" },
  { label: "Branch", value: "Branch" },
  { label: "Presidents Club GP $", value: "PresidentGP" },
  { label: "Total Activity", value: "TotalActivity" },
  { label: "Leads", value: "Leads" },
  { label: "Qualify", value: "Qualify" },
  { label: "Company Research", value: "CompanyResearch" },
  { label: "Discovery Appointment", value: "DiscoveryAppointment" },
  { label: "Negotiations", value: "Negotiations" },
  { label: "Closing Process", value: "ClosingProcess" },
  { label: "Sales Prospect", value: "SalesProspect" },
  { label: "CMS Not Invoiced", value: "CMS_NotInvoiced" },
  { label: "CMS Invoiced", value: "CMS_Invoiced" },
];

export enum KPIHEADER {
  INVOICED = "CMS_Invoiced",
}

export const MANAGER_TABLES = [
  { label: "Sales Manager", value: "SalesManager" },
  { label: "Branch Manager", value: "BranchManager" },
];
