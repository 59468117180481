import { Popover, Table, Tooltip } from "@mantine/core";
import {
  IStageAccountRes,
  IStageWiseAccTrack,
} from "../../../types/salesPipeline";
import {
  formatAmount,
  formatPercentValue,
} from "../../../utils/helper/cellFormatter";
import ClientDetails from "./ClientDetails";
import { mapStageNumberToName } from "./utils/helper";
import { INVOICING_STAGE } from "./utils/constants";

const AccountTracker = (props: {
  selectedStage: number;
  adjustedFunnelData: any;
  stageWiseAcc: IStageWiseAccTrack[];
  invoicingClients: IStageAccountRes[] | null;
  openedPopup: number | null;
  setOpenedPopup: (data: number | null) => void;
}) => {
  const {
    selectedStage,
    adjustedFunnelData,
    stageWiseAcc,
    invoicingClients,
    openedPopup,
    setOpenedPopup,
  } = props;

  const STAGENAME_MAP = mapStageNumberToName(adjustedFunnelData);

  return (
    <div className="w-1 h-[90%] lg:h-[85%] flex flex-col" id="popup">
      {adjustedFunnelData?.map((data: any, index: number) => {
        const fillcolor = data.fill;
        return (
          <div className="relative flex-1" key={`Stage${index}`}>
            {selectedStage === data.StageOrder && (
              <div
                className={`absolute  z-20 ${
                  selectedStage === INVOICING_STAGE ? "-top-8" : "top-2 "
                } ${
                  data.TotalClientCount >= 30 ? "-left-[9vw]" : "-left-[11vw]"
                } flex`}
              >
                <div className="py-1 px-2 text-xs  max-h-[350px] w-[320px] bg-white rounded-lg shadow-md border-[1px] overflow-auto">
                  {selectedStage === INVOICING_STAGE ? (
                    <div>
                      <div
                        className={`border-b-[1px] border-[#888d95] text-center text-white font-semibold  bg-[${fillcolor}] sticky top-0 z-10`}
                        style={{ backgroundColor: fillcolor }}
                      >
                        {data.CommentStages}
                      </div>
                      <ClientDetails
                        clientDetails={invoicingClients}
                        tableFillColor={fillcolor}
                        isInvoicing={true}
                      />
                    </div>
                  ) : (
                    <div className=" rounded-sm  h-full overflow-auto">
                      <div
                        className={`border-b-[1px] border-[#888d95] text-center text-white font-semibold p-1 bg-[${fillcolor}] sticky top-0 z-10`}
                        style={{ backgroundColor: fillcolor }}
                      >
                        {data.CommentStages}
                      </div>
                      {stageWiseAcc.length > 0 ? (
                        <div>
                          {stageWiseAcc.map(
                            (stage: IStageWiseAccTrack, index: number) => (
                              <div className="h-8 lg:h-11 flex " key={index}>
                                <div className="w-[34%] font-bold text-sm flex items-center">
                                  <div
                                    onClick={() =>
                                      setOpenedPopup(
                                        openedPopup === index ? null : index
                                      )
                                    }
                                  >
                                    <Popover
                                      width={"500"}
                                      position="bottom-start"
                                      withArrow
                                      shadow="md"
                                      offset={{ mainAxis: 0, crossAxis: 50 }}
                                      onClose={() => setOpenedPopup(null)}
                                    >
                                      <Popover.Target>
                                        <Tooltip
                                          multiline
                                          w={220}
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            color: "black",
                                          }}
                                          position="top"
                                          color="#f5f5f5"
                                          label={
                                            <>
                                              Total number of clients that moved
                                              from{" "}
                                              <b>
                                                {STAGENAME_MAP[selectedStage] ??
                                                  ""}
                                              </b>{" "}
                                              to{" "}
                                              <b>
                                                {STAGENAME_MAP[
                                                  stage.nextStage
                                                ] ?? ""}
                                              </b>
                                            </>
                                          }
                                          withArrow
                                          arrowSize={7}
                                          transitionProps={{
                                            transition: "scale",
                                            duration: 400,
                                          }}
                                        >
                                          <button
                                            className={`py-2 px-2 rounded-md  ${
                                              openedPopup === index
                                                ? "bg-stone-300 "
                                                : "hover:bg-stone-100"
                                            }
                                    `}
                                          >
                                            {stage.numberOfAccounts}
                                          </button>
                                        </Tooltip>
                                      </Popover.Target>
                                      <Popover.Dropdown
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <ClientDetails
                                          clientDetails={stage.data}
                                          tableFillColor="#6a5179"
                                        />
                                      </Popover.Dropdown>
                                    </Popover>
                                  </div>
                                  <Tooltip
                                    multiline
                                    w={220}
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: 500,
                                      color: "black",
                                    }}
                                    position="top"
                                    color="#f5f5f5"
                                    label={
                                      <>
                                        Percentage of clients that moved from{" "}
                                        <b>
                                          {STAGENAME_MAP[selectedStage] ?? ""}
                                        </b>{" "}
                                        to{" "}
                                        <b>
                                          {STAGENAME_MAP[stage.nextStage] ?? ""}
                                        </b>
                                      </>
                                    }
                                    withArrow
                                    arrowSize={8}
                                    transitionProps={{
                                      transition: "scale",
                                      duration: 400,
                                    }}
                                  >
                                    <div className="font-medium ">
                                      (
                                      {formatPercentValue(
                                        stage.percentage.toString()
                                      )}
                                      )
                                    </div>
                                  </Tooltip>
                                </div>
                                <div className="flex justify-start items-start  w-[50px] relative">
                                  <div
                                    className={`absolute w-full h-full bottom-3 border-l-2  ${
                                      index === stageWiseAcc.length - 1
                                        ? "rounded-bl-lg"
                                        : ""
                                    } border-b-2`}
                                    style={{ borderColor: fillcolor }}
                                  ></div>
                                  <div
                                    className="absolute w-3 h-3 rotate-45 bottom-2 right-0 border-t-2 border-r-2"
                                    style={{ borderColor: fillcolor }}
                                  ></div>
                                </div>

                                <div className="flex justify-start  items-end p-2 pr-0 font-semibold text-xs w-[45%]">
                                  {STAGENAME_MAP[stage.nextStage] ?? ""}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div className="flex justify-center my-4">
                          No accounts tracked
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default AccountTracker;
