/*
 * Copyright (C) 2025 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import {
  SET_CGS_AREAS_LIST,
  SET_CGS_COMPANY_LIST,
  SET_CGS_DISTRICTS_LIST,
  SET_CGS_SUBAREAS_LIST,
} from "./actionTypes";

export const setCgsDistricts = (payload: any) => {
  return {
    type: SET_CGS_DISTRICTS_LIST,
    payload: payload,
  };
};
export const setCgsSubareas = (payload: any) => {
  return {
    type: SET_CGS_SUBAREAS_LIST,
    payload: payload,
  };
};
export const setCgsAreas = (payload: any) => {
  return {
    type: SET_CGS_AREAS_LIST,
    payload: payload,
  };
};
export const setCgsCompany = (payload: any) => {
  return {
    type: SET_CGS_COMPANY_LIST,
    payload: payload,
  };
};
