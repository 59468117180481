/*
 * Copyright (C) 2025 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const SET_CGS_DISTRICTS_LIST = "SET_CGS_DISTRICTS_LIST";
export const SET_CGS_SUBAREAS_LIST = "SET_CGS_SUBAREAS_LIST";
export const SET_CGS_AREAS_LIST = "SET_CGS_AREAS_LIST";
export const SET_CGS_COMPANY_LIST = "SET_CGS_COMPANY_LIST";
