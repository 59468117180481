export const INCOMEMODEL_HEADERS = [
    { label: "Revenue", value: "Revenue" },
    { label: "OP Income", value: "OperatingIncome" },
    { label: "Actual Margin", value: "Actual Margin" },
    { label: "Expected Margin", value: "Expected Margin" },
    { label: "+/-", value: "+/-" },
    { label: "Amount", value: "Amount" },
    { label: "Rank", value: "Rank" },
    { label: "Op Inc %", value: "Op Inc %" },
  ];

 export  const NON_SUMMING_HEADERS = ["+/-", "Rank"];

 export const AMOUNT_DATA_HEADERS = ["Revenue", "Amount", "OperatingIncome"];

 export const PERC_DATA_HEADERS = [
      "Actual Margin",
      "Expected Margin",
      "+/-",
      "Op Inc %",
    ];