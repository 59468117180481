import React, { useEffect, useState } from "react";
import { IIncomeModelData } from "../../../types/incomeModel";
import { Table } from "@mantine/core";
import {
  formatAmount,
  formatPercentValue,
} from "../../../utils/helper/cellFormatter";
import {
  AMOUNT_DATA_HEADERS,
  INCOMEMODEL_HEADERS,
  NON_SUMMING_HEADERS,
  PERC_DATA_HEADERS,
} from "./utils/constants";
import { getIcon, requestSort } from "../../../utils/helper/sortIcon";

const IncomeModelTable = (props: {
  region: string;
  tableData: IIncomeModelData[];
  totalData: IIncomeModelData[];
}) => {
  const { region, tableData, totalData } = props;
  const [sortedData, setSortedData] = useState(tableData);

  const getformattedData = (header: string, value: any) => {
    return AMOUNT_DATA_HEADERS.includes(header)
      ? formatAmount(value)
      : PERC_DATA_HEADERS.includes(header)
      ? formatPercentValue(value)
      : value;
  };
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string;
  } | null>({
    key: "+/-",
    direction: "desc",
  });

  useEffect(() => {
    if (sortConfig && tableData) {
      const sorted: any = requestSort(
        sortConfig.key ?? "",
        sortConfig.direction,
        tableData
      );
      setSortedData(sorted);
    }
  }, [sortConfig, tableData]);

  return (
    <div className="bg-white overflow-auto rounded-sm shadow-md">
      <Table verticalSpacing={1.5} horizontalSpacing={3} fontSize={11.5}>
        <thead className="bg-[#297faa] sticky top-0 z-10">
          <tr>
            <th className="!text-white !py-1 w-[11rem]">
              <div className=" flex items-center gap-1 ">
                {region}
                <i
                  className="material-icons cursor-pointer "
                  style={{ fontSize: "15px" }}
                  onClick={() =>
                    setSortConfig({
                      key: "Branch",
                      direction:
                        sortConfig === null || sortConfig.direction === "desc"
                          ? sortConfig?.key === "Branch"
                            ? "asc"
                            : "desc"
                          : "desc",
                    })
                  }
                >
                  {getIcon("Branch", sortConfig)}
                </i>
              </div>
            </th>
            {INCOMEMODEL_HEADERS.map(
              (header: { label: string; value: string }, index: number) => {
                return (
                  <th
                    className="!text-white !py-1 min-w-[5rem] md:min-w-[7rem] "
                    key={index}
                  >
                    <div className="flex justify-center items-center gap-1 ">
                      <span className="">{header.label}</span>
                      <i
                        className="material-icons cursor-pointer "
                        style={{ fontSize: "15px" }}
                        onClick={() =>
                          setSortConfig({
                            key: header.value,
                            direction:
                              sortConfig === null ||
                              sortConfig.direction === "desc"
                                ? sortConfig?.key === header.value
                                  ? "asc"
                                  : "desc"
                                : "desc",
                          })
                        }
                      >
                        {getIcon(header.value, sortConfig)}
                      </i>
                    </div>
                  </th>
                );
              }
            )}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((d: IIncomeModelData, index: number) => (
            <React.Fragment key={index}>
              <tr className="hover:bg-stone-100">
                <td>{d.Branch}</td>
                {INCOMEMODEL_HEADERS.map(
                  (header: { label: string; value: string }, index: number) => {
                    return (
                      <td className="text-center" key={index}>
                        {getformattedData(header.value, d[header.value])}
                      </td>
                    );
                  }
                )}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
        <tfoot
          className="sticky bottom-0 bg-white"
          style={{ outline: "1px solid #368ab3" }}
        >
          {totalData.map((d: IIncomeModelData, index: number) => (
            <React.Fragment key={index}>
              <tr>
                <th>Total</th>
                {INCOMEMODEL_HEADERS.map(
                  (header: { label: string; value: string }, index: number) => {
                    return (
                      <th className="!text-center" key={index}>
                        {NON_SUMMING_HEADERS.includes(header.value)
                          ? null
                          : getformattedData(header.value, d[header.value])}
                      </th>
                    );
                  }
                )}
              </tr>
            </React.Fragment>
          ))}
        </tfoot>
      </Table>
    </div>
  );
};

export default IncomeModelTable;
