import { useEffect } from "react";
import { handleError } from "../helper/handleError";

export const useFetch = (
  fetchFn: (...args: any[]) => Promise<any>,
  params: any[],
  setData: (data: any) => void,
  deps: any[]
) => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchFn(...params);
        setData(res.data);
      } catch (error) {
        handleError("Error fetching data");
      }
    };
    fetchData();
  }, deps);
};
