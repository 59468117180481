/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const EXECUTIVE_COMPANY_MENUS = [
  {
    path: "",
    label: "Dashboard",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/c40b3e9b-da49-4cdb-b1e3-7c69ccf9e162/ReportSection42bfb911359bdc6dfe94",
    active: false,
  },
  {
    path: "/daily-numbers",
    label: "Daily Number",
    link: "https://as-reports.gritfeat.com/daily-numbers",
    active: false,
  },
  {
    path: "",
    label: "Open Assignment",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/5afe3c33-e15d-4e22-b957-4fd570e5b2ef/ReportSection",
    active: false,
  },
  {
    path: "",
    label: "Staff Productivity",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/885e752d-acdc-45e1-aa51-657cc9a7220f/ReportSection",
    active: false,
  },
  {
    path: "",
    label: "Available Employees",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/60da792a-d41f-48b5-a002-80366a29f241/ReportSection20fd3d6f07cdd265814a?bookmarkGuid=Bookmark54318d2f4aabcb0b1c0b",
    active: false,
  },
  {
    path: "",
    label: "Top Clients",
    link: "https://as-reports.gritfeat.com/top-clients",
    active: false,
  },
  {
    path: "",
    label: "Comparison",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/16317061-27b7-4017-8d48-6511527e6b87/ReportSection",
    active: false,
  },
  {
    path: "/weekly-report",
    label: "Weekly Report",
    link: "https://as-reports.gritfeat.com/weekly-report",
    active: false,
  },
  {
    path: "",
    label: "Sales Activity",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/60da792a-d41f-48b5-a002-80366a29f241/ReportSectionab4bd0c53b3b1fcb7cfc?bookmarkGuid=Bookmark23a6e20635c0e07aa2eb",
    active: false,
  },
  {
    path: "/executive-company-summary",
    label: "Executive Company Summary",
    link: "https://as-reports.gritfeat.com/company-summary",
    active: false,
  },
  {
    path: "",
    label: "Branch Dashboard",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/03b19f1c-18a8-4c8f-b8f3-e3f238f61948/ReportSectionfdcec90ba28704f33c71",
    active: false,
  },
  {
    path: "/executive-pip-summary",
    label: "Executive PIP Summary",
    link: "https://as-reports.gritfeat.com/executive-pip-summary",
    active: false,
  },
];

export const COMPANY_MENUS = [
  {
    path: "",
    label: "Dashboard",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/c40b3e9b-da49-4cdb-b1e3-7c69ccf9e162/ReportSection42bfb911359bdc6dfe94",
    active: false,
  },
  {
    path: "/daily-numbers",
    label: "Daily Number",
    link: "https://as-reports.gritfeat.com/daily-numbers",
    active: false,
  },
  {
    path: "",
    label: "Open Assignment",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/5afe3c33-e15d-4e22-b957-4fd570e5b2ef/ReportSection",
    active: false,
  },
  {
    path: "",
    label: "Staff Productivity",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/885e752d-acdc-45e1-aa51-657cc9a7220f/ReportSection",
    active: false,
  },
  {
    path: "",
    label: "Available Employees",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/60da792a-d41f-48b5-a002-80366a29f241/ReportSection20fd3d6f07cdd265814a?bookmarkGuid=Bookmark54318d2f4aabcb0b1c0b",
    active: false,
  },
  {
    path: "",
    label: "Top Clients",
    link: "https://as-reports.gritfeat.com/top-clients",
    active: false,
  },
  {
    path: "",
    label: "Comparison",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/16317061-27b7-4017-8d48-6511527e6b87/ReportSection",
    active: false,
  },
  {
    path: "/weekly-report",
    label: "Weekly Report",
    link: "https://as-reports.gritfeat.com/weekly-report",
    active: false,
  },
  {
    path: "",
    label: "Sales Activity",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/60da792a-d41f-48b5-a002-80366a29f241/ReportSectionab4bd0c53b3b1fcb7cfc?bookmarkGuid=Bookmark23a6e20635c0e07aa2eb",
    active: false,
  },
  {
    path: "/company-summary",
    label: "Company Summary",
    link: "https://as-reports.gritfeat.com/company-summary",
    active: false,
  },
  {
    path: "",
    label: "Branch Dashboard",
    link: "https://app.powerbi.com/groups/d9341e4a-2be0-4eb6-8eb1-5a9ed297ac2a/reports/03b19f1c-18a8-4c8f-b8f3-e3f238f61948/ReportSectionfdcec90ba28704f33c71",
    active: false,
  },
];
export const ADA_COMPANY_MENUS = [
  {
    path: "/daily-numbers-ada",
    label: "Daily Number",
    link: "https://as-reports.gritfeat.com/daily-numbers-ada",
    active: false,
  },
  {
    path: "",
    label: "Open Assignment",
    link: "https://app.powerbi.com/groups/2d34754c-8567-4ee9-afb3-9ce9ccadbb5f/reports/230a984e-3e76-44f6-83e6-c86c03c2541c/ReportSectionff1d3977acb3a9ce47ee?experience=power-bi",
    active: false,
  },
  {
    path: "",
    label: "Staff Productivity",
    link: "https://app.powerbi.com/groups/2d34754c-8567-4ee9-afb3-9ce9ccadbb5f/reports/e382ef99-c94d-4cf5-b0d6-603c1b3dd9c1/ReportSection07f1bda9954d71a5db23?experience=power-bi",
    active: false,
  },
  {
    path: "/company-summary-ada",
    label: "Company Summary",
    link: "https://as-reports.gritfeat.com/company-summary-ada",
    active: false,
  },
];

// Company Summary

export const CS_BRANCHES_TOP = [
  {
    label: "Company",
  },
];

export const CS_BRANCHES_MID = [
  {
    label: "Art's",
  },
  {
    label: "Kia's",
  },
];

export const CS_BRANCHES_LOW = [
  {
    label: "AZ NV CA (Dolly)",
  },
  {
    label: "TX NM GA SC (Alex)",
  },
  {
    label: "TX AL LS (Chris)",
  },
  {
    label: "UT ID CO (Jake)",
  },
  {
    label: "UT IA IL KS (Tyler)",
  },
  {
    label: "WA IA NB (Lu Ann)",
  },
];

export const CHART_BRANCHES = [
  { label: "Art's" },
  { label: "Kia's" },
  { label: "AZ NV CA (Dolly)" },
  { label: "TX NM GA SC (Alex)" },
  { label: "TX AL LS (Chris)" },
  { label: "UT ID CO (Jake)" },
  { label: "UT IA IL KS (Tyler)" },
  { label: "WA IA NB (Lu Ann)" },
];

export const BRANCH_CHART_LABELS = [
  { label: "St. George" },
  { label: "Tolleson" },
  { label: "Tooele" },
  { label: "Tremonton" },
  { label: "Tucson" },
  { label: "Twin Falls" },
  { label: "Utah County" },
  { label: "West Valley" },
  { label: "Wichita" },
];

export const sortOptions = [
  {
    infoValue: "alphabetically",
    name: "Alphabetical",
    value: "AlphabeticalRank",
  },
  {
    infoValue: "by Projected Revenue",
    name: "Projected Revenue",
    value: "PRRank",
  },
  { infoValue: "by Revenue", name: "Revenue", value: "RevenueRank" },
  {
    infoValue: "by Trailing 4 Weeks Revenue",
    name: "Trailing 4 Weeks Revenue",
    value: "TWRank",
  },
  {
    infoValue: "by Trailing 4 Weeks GP",
    name: "Trailing 4 Weeks GP",
    value: "TWGPRank",
  },
  { infoValue: "by Gross Profit", name: "Gross Profit", value: "GPRank" },
  { infoValue: "by Net Income", name: "Net Income", value: "NIRank" },
  {
    infoValue: "by Operating Income",
    name: "Operating Income",
    value: "OIRank",
  },
];

export enum DATAMODE {
  NETINCOME = "NetIncome",
  OPERATINGINCOME = "OperatingIncome",
  REVENUE = "Revenue",
  GROSSPROFIT = "GrossProfit",
  GROSSMARGIN = "Gross Margin",
  GROWTHPERC = "Growth %",
}

export enum LABEL {
  VMS = "VMS",
}
export enum LEVEL {
  ADA = "ADA",
  COMPANY="Company"
}

export const chartColors = {
  TopClients: {
    GrossProfit: [
      {
        label: "Prior Year Gross Profit",
        color: "#1875B5",
      },
      { label: "Last Year Gross Profit", color: "#FF7F0E" },
      { label: "This Year Gross Profit", color: "#29A229" },
    ],
    Revenue: [
      {
        label: "Prior Year Revenue",
        color: "#1875B5",
      },
      { label: "Last Year Revenue", color: "#FF7F0E" },
      { label: "This Year Revenue", color: "#29A229" },
    ],
  },
  Revenue: [
    { label: "Previous Year", color: "#1875B5" },
    { label: "Last Year", color: "#FF7F0E" },
    { label: "Current Year", color: "#29A229" },
    { label: "Actual Goal", color: "#A66999" },
  ],
  GrossProfit: [
    { label: "Previous Year", color: "#1875B5" },
    { label: "Last Year", color: "#FF7F0E" },
    { label: "Current Year", color: "#29A229" },
    { label: "Actual Goal", color: "#A66999" },
  ],
  NetIncome: [
    { label: "Previous Year", color: "#1875B5" },
    { label: "Last Year", color: "#FF7F0E" },
    { label: "Current Year", color: "#29A229" },
  ],
  OperatingIncome: [
    { label: "Previous Year", color: "#1875B5" },
    { label: "Last Year", color: "#FF7F0E" },
    { label: "Current Year", color: "#29A229" },
  ],
};

export const pipChartColorsLegend = [
  { color: "#609DD4", label: "Plan Revenue" },
  { color: "#0AB156", label: "Actual Revenue PIP" },
  { color: "#FCF30A", label: "Original Goal" },
  { color: "#FB986B", label: "Last Year Revenue " },
  // { color: "#609DD4", label: "% to Plan PIP" },
  // { color: "#FB986B", label: "% to Last Year PIP" },
];

export const pipChartColorsLegendWIP = [
  { color: "#609DD4", label: "Plan Revenue" },
];

export const pipChartColors = [
  { color: "#609DD4", label: "Plan Revenue" },
  // { color: "#0AB156", label: "Actual Revenue PIP" },
  // { color: "#FCF30A", label: "Original Goal" },
  // { color: "#FB986B", label: "Last Year Revenue" },
  { color: "#609DD4", label: "Plan Revenue" },
  // { color: "#0AB156", label: "Actual Revenue PIP" },
  // { color: "#FCF30A", label: "Original Goal" },
  // { color: "#FB986B", label: "Last Year Revenue" },
  { color: "#609DD4", label: "Plan Revenue" },
  // { color: "#0AB156", label: "Actual Revenue PIP" },
  // { color: "#FCF30A", label: "Original Goal" },
  // { color: "#FB986B", label: "Last Year Revenue" },
];

export const branchChartColors = {
  Revenue: {
    InExp: [
      { label: "Previous Year", color: "#1875B5" },
      { label: "Last Year", color: "#FF7F0E" },
      { label: "Current Year", color: "#29A229" },
      { label: "Actual Goal", color: "#A66999" },
      { label: "12 Months", color: "#689FB0" },
    ],
    Exp: [
      { label: "Previous Year", color: "#1875B5" },
      { label: "Last Year", color: "#FF7F0E" },
      { label: "Current Year", color: "#29A229" },
      { label: "Actual Goal", color: "#A66999" },
    ],
  },
  GrossProfit: [
    { label: "Previous Year", color: "#1875B5" },
    { label: "Last Year", color: "#FF7F0E" },
    { label: "Current Year", color: "#29A229" },
    { label: "Actual Goal", color: "#A66999" },
  ],
  NetIncome: [
    { label: "Previous Year", color: "#1875B5" },
    { label: "Last Year", color: "#FF7F0E" },
    { label: "Current Year", color: "#29A229" },
  ],
  OperatingIncome: [
    { label: "Previous Year", color: "#1875B5" },
    { label: "Last Year", color: "#FF7F0E" },
    { label: "Current Year", color: "#29A229" },
  ],
};

export const clientChartColors = [
  { label: "Four Years Ago", color: "#A63E2E" }, // dark maroon
  { label: "Three Years Ago", color: "#A66999" }, // purple
  { label: "Previous Year", color: "#1875B5" }, //blue
  { label: "Last Year", color: "#FF7F0E" }, //coral
  { label: "Current Year", color: "#29A229" }, //green
];

export const sourceDoc = [
  { branch: "Overland Park", source: "138ce1b4-08d4-4950-886a-bf8297a37973" },
  { branch: "Austin", source: "f22bf4dd-db99-4c4a-a88f-23a100553e99" },
  { branch: "Brigham City", source: "7e68eb16-1129-4b48-afc8-7d0268445a07" },
  { branch: "Broomfield", source: "a552dcfa-c777-4f37-84b2-6a8ee7fc6ee1" },
  {
    branch: "Colorado Springs",
    source: "332134e7-5b3d-4aa0-b823-8f7b64b2d6fc",
  },
  { branch: "Dallas", source: "d7ea9471-9bd0-4aa6-a3e1-4ccaf15ca9ea" },
  { branch: "Inez", source: "ea6ff918-bd2a-4198-acee-4ca2ef72a732" },
  { branch: "Kingman", source: "fec604a1-cb7a-4052-a225-7c2d51dfddd2" },
  { branch: "Logan", source: "aff5178d-1979-44dd-8179-3bfc0f5cd5f3" },
  { branch: "Longview", source: "0ba07053-e626-4045-82f4-342026d632cc" },
  { branch: "Chehalis", source: "f50207bf-a049-4c57-a281-818c4a806c42" },
  { branch: "Spirit Lake", source: "369658a8-cc83-4b6e-b39c-a7892ee985fd" },
  { branch: "Tolleson", source: "86730d71-dfc0-4023-9a37-ea7600a36621" },
  { branch: "Tooele", source: "73a2f8ff-214d-4cad-ba95-bf0a0c110a63" },
  { branch: "West Valley", source: "c1d07427-2249-43c4-86ea-2632e6ed07e6" },
];
