/*
 * Copyright (C) 2025 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { clsx, Select } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APIGetFinancialYTDData } from "../api/financial-ytd-comparison";
import "../assets/scss/FinancialReport.scss";
import { Button } from "../components/common/Button";
import HomeButton from "../components/common/HomeButton";
import { Loader } from "../components/common/Loader";
import {
  setLoading,
  setUserAccessibleLocations,
} from "../store/Filter/actions";
import {
  IBranchData,
  IFinancialYTDComp,
  ILocations,
} from "../types/financials";
import {
  formatAmount,
  formatPercentValue,
} from "../utils/helper/cellFormatter";
import { HIGHLIGHTED_PARTICULARS } from "../utils/mock-data/financial-report";
import { MONTH_LIST } from "../utils/helper/constants";
import { handleError } from "../utils/helper/handleError";
import { APIFetchLatestMonthYear } from "../api/income-model";

const getHighlights = (particular: IFinancialYTDComp) => {
  return HIGHLIGHTED_PARTICULARS.includes(particular.Item.trim());
};

const rowClassName = (data: IFinancialYTDComp) =>
  clsx([
    "table-col",
    getHighlights(data)
      ? "bg-primary text-white font-bold"
      : data?.Item?.toString().includes(":")
      ? "bg-gray"
      : "",
  ]);

/**
 *
 * @param rowParticularName item name of current row
 * @param displayData displays data if the row name doesn't have ":" indicating it's a value
 * @returns string | number | null
 */
const checkCellType = (
  rowParticularName?: string | number,
  displayData?: string | number | null
) => {
  if (displayData === null) return;
  if (rowParticularName?.toString()?.includes(":")) {
    return null;
  } else {
    return displayData;
  }
};

const ColumnGap = () => <div className="w-8"></div>;

const EmptyCol = ({ number = 1 }: { number?: number }) => (
  <>
    {[...Array(number)].map((_, index) => (
      <div className="table-col" style={{ border: "none" }} key={index}></div>
    ))}
  </>
);

const formatData = (header: string, data?: string | number) => {
  if (data === null) return null;
  return header?.includes("%")
    ? formatPercentValue(`${data}`)
    : formatAmount(data);
};

enum FilterEnum {
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  Rolling3Months = "Rolling_3_Months",
  YTD = "YTD",
}

const FinancialYTDComparison = () => {
  const dispatch = useDispatch();
  const [newData, setNewData] = useState<IFinancialYTDComp[]>([]);
  const [branchSelected, setBranchSelected] = useState<IBranchData | null>(
    null
  );
  const [currentYearMonth, setCurrentYearMonth] = useState({
    year: "",
    month: "",
  });
  const [selectedYear, setSelectedYear] = useState<string>("");
  const [selectedMonth, setSelectedMonth] = useState<string>("");
  const filterButtons = [
    FilterEnum.Monthly,
    FilterEnum.Quarterly,
    FilterEnum.Rolling3Months,
    FilterEnum.YTD,
  ];
  const [selectedFilter, setSelectedFilter] = useState(FilterEnum.Monthly);
  const handleFilterItemChange = async (buttonType: FilterEnum) => {
    setSelectedFilter(buttonType);
  };
  const loading = useSelector((state: any) => state["filterReducer"].loading);
  const branches = useSelector(
    (state: any) => state.filterReducer.userLocations
  );
  const { financialLocation } = useSelector(
    (state: any) => state.financialReportReducer
  );

  const years = Array.from({ length: 3 }, (_, i) =>
    (+currentYearMonth.year - i).toString()
  );
  // const yearsToShow = years.filter(
  //   (year: string) =>
  //     year === selectedYear || year === (+selectedYear - 1).toString()
  // );

  useEffect(() => {
    document.title = "Financials YTD Yearly Comparison";
    const fetchLatestMonthYear = async () => {
      const res = await APIFetchLatestMonthYear();
      const year = res.data[0].Calendaryear.toString();
      const month = res.data[0].LatestMonthName;
      setCurrentYearMonth({
        year: year,
        month: month,
      });
      setSelectedYear(year);
      setSelectedMonth(month);
    };
    fetchLatestMonthYear();
  }, []);

  const getUserLocations = async () => {
    let hier = [];
    hier = financialLocation.filter((l: ILocations) => l.level === "Location");
    if (hier.length === 0) {
      hier = financialLocation.filter((l: ILocations) => l.level === "Area");
    }
    if (hier.length === 0) {
      hier = financialLocation.filter((l: ILocations) => l.level === "VP");
    }
    if (hier.length === 0) {
      hier = financialLocation.filter((l: ILocations) => l.level === "Region");
    }
    if (hier.length === 0) {
      hier = financialLocation.filter(
        (l: ILocations) => l.level === "AreaBranch"
      );
    }
    if (hier.length === 0) {
      hier = financialLocation.filter((l: ILocations) => l.level === "Branch");
    }
    if (hier.length > 0)
      dispatch(await setUserAccessibleLocations(hier, selectedYear, true));
  };

  const fetchFinancialsData = async (branch: string) => {
    try {
      dispatch(setLoading(true));
      const response = await APIGetFinancialYTDData(
        branch,
        selectedYear,
        selectedFilter.replace(/_/g, ""),
        selectedMonth
      );
      // setData(groupByKey(response.data, "Category"));
      setNewData(response.data);
    } catch (error) {
      handleError(`Financials ${selectedFilter} data cannot be fetched!`);
      setNewData([]);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (financialLocation && selectedYear) {
      getUserLocations();
    }
  }, [financialLocation, selectedYear]);

  useEffect(() => {
    if (branchSelected) {
      fetchFinancialsData(branchSelected?.locations ?? "All");
    }
  }, [branchSelected, selectedYear, selectedFilter, selectedMonth]);

  useEffect(() => {
    if (!branchSelected && branches?.length > 0) {
      setBranchSelected(branches[0]);
    }
  }, [branches]);

  const resetFilters = () => {
    setBranchSelected(branches?.[0]);
    setSelectedFilter(FilterEnum.Monthly);
    setSelectedYear(currentYearMonth.year);
    setSelectedMonth(currentYearMonth.month);
  };

  /**
   * used to determine months to display on columns 2 and 3
   */
  const [monthsKey, setMonthsKey] = useState<Array<string>>([]);
  const [differenceKeys, setDifferenceKeys] = useState<Array<string>>([]);
  const differenceKeyPrefixes = ["Difference", "% Difference", "% Change"];

  useEffect(() => {
    if (!newData || newData.length === 0) return;
    dispatch(setLoading(true));

    // Extract months (keys ending with ThisYear)
    setMonthsKey(
      Object.keys(newData?.[0] || {}).reduce((acc: string[], cur: string) => {
        if (cur.endsWith("ThisYear")) {
          const keys = cur.split("_");
          keys.pop();
          acc.push(keys.join("_"));
        }
        return acc;
      }, [])
    );
    setDifferenceKeys(
      Object.keys(newData?.[0] || {}).reduce((acc: string[], cur: string) => {
        const keyPrefix = cur.split("_")[0];
        if (differenceKeyPrefixes.includes(keyPrefix)) {
          acc.push(cur);
        }
        return acc;
      }, [])
    );
    dispatch(setLoading(false));
  }, [newData]);

  return (
    <main className="dashboard screen financial-report flex flex-col gap-4">
      <header className="flex flex-col relative">
        <HomeButton adaOnly={false} resetFilters={resetFilters} />
        <div className="text-base font-bold ml-16 mt-[0.4rem]">
          Financials Yearly YTD Comparison
        </div>

        <div className="flex justify-between items-center w-full">
          <div className="grow basis-1"></div>
          <div className="">
            {branchSelected && (
              <div className="text-sm font-medium p-1 px-4 rounded-sm bg-tertiary text-center text-white min-w-48 ">
                {branchSelected?.locations ?? "Alll"} -{" "}
                {selectedFilter.replace(/_/g, " ")}
              </div>
            )}
          </div>
          <div className=" grow basis-1 flex justify-end gap-2 items-end">
            {selectedFilter === FilterEnum.Monthly && (
              <Select
                className="w-32 lg:w-40"
                value={selectedMonth}
                onChange={(value: string) => setSelectedMonth(value)}
                data={MONTH_LIST}
                size="xs"
                label="Month"
              />
            )}
            <Select
              className="w-32 lg:w-40"
              value={selectedYear}
              onChange={(value: string) => setSelectedYear(value)}
              data={years}
              size="xs"
              label="Year"
            />
            <div className="flex gap-1 mb-0.5">
              {filterButtons.map((title) => (
                <Button
                  onClick={() => handleFilterItemChange(title)}
                  active={title === selectedFilter}
                  key={title}
                >
                  {title.replace(/_/g, " ")}
                </Button>
              ))}
            </div>
          </div>
        </div>
      </header>
      {branches.length > 0 && (
        <section className="flex gap-2 overflow-hidden">
          <div className="branches-list lg:w-[9%] w-[20%] items-center flex flex-col gap-2 overflow-y-auto border border-gray">
            <div className="font-bold sticky top-0 bg-white w-full text-center p-1">
              Select Branch
            </div>
            <div className="branches flex flex-col w-full h-screen">
              {branches.length > 0 ? (
                branches.map((branch: IBranchData) => {
                  return (
                    <div
                      key={branch.locations}
                      className={`branch-menu cursor-pointer border border-1 border-gray  ${
                        branchSelected?.locations === branch.locations
                          ? "bg-tertiary text-white"
                          : "hover:bg-stone-100"
                      } px-0 lg:px-3 py-1 ${
                        branch.flag === 1 ? "font-bold" : ""
                      }`}
                      onClick={() => setBranchSelected(branch)}
                    >
                      <div
                        style={{
                          paddingLeft:
                            branch.locations.split(" ").pop() === "Combined" &&
                            branch.SubArea === "VP"
                              ? (7 - (branch.level - 4)) * 4 + "px"
                              : branch.locations.split(" ").pop() === "Combined"
                              ? (7 - (branch.level - 1)) * 4 + "px"
                              : (7 - branch.level) * 4 + "px",
                        }}
                      >
                        {branch.locations}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="flex flex-col items-center justify-center h-full text-sm text-gray-500">
                  <span className="text-center">
                    You are not assigned to any branches.
                  </span>
                </div>
              )}
            </div>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <section className="financial-table">
              {/* Table Headers - year*/}
              <div
                className="financial-table-headers flex text-bold bg-white w-fit lg:min-w-[100%]"
                style={{ border: "none" }}
              >
                {/* Empty header for particulars */}
                <div
                  className="table-col particulars"
                  style={{ border: "none" }}
                ></div>

                <ColumnGap />

                <EmptyCol
                  number={
                    selectedFilter === FilterEnum.Quarterly
                      ? 2
                      : selectedFilter === FilterEnum.YTD ||
                        selectedFilter === FilterEnum.Monthly ||
                        selectedFilter === FilterEnum.Rolling3Months
                      ? 1
                      : 0
                  }
                />

                {/* ThisYear Header */}
                <div
                  className={`table-col ${
                    selectedFilter === FilterEnum.Rolling3Months
                      ? "!min-w-[250px]"
                      : ""
                  }`}
                  style={{ border: "none" }}
                >
                  <div className="text-center border-[1px] py-1 border-stone-400 rounded-sm shadow-md text-[0.8rem]">
                    {selectedFilter === FilterEnum.Rolling3Months
                      ? "This Rolling 3 Months"
                      : selectedYear}
                  </div>
                </div>

                <EmptyCol
                  number={
                    selectedFilter === FilterEnum.Rolling3Months ||
                    selectedFilter === FilterEnum.YTD ||
                    selectedFilter === FilterEnum.Monthly
                      ? 1
                      : selectedFilter === FilterEnum.Quarterly
                      ? 2
                      : 0
                  }
                />
                <ColumnGap />

                <EmptyCol
                  number={
                    selectedFilter === FilterEnum.Quarterly
                      ? 2
                      : selectedFilter === FilterEnum.Rolling3Months ||
                        selectedFilter === FilterEnum.YTD ||
                        selectedFilter === FilterEnum.Monthly
                      ? 1
                      : 0
                  }
                />

                {/* LastYear Header */}
                <div
                  className={`table-col ${
                    selectedFilter === FilterEnum.Rolling3Months
                      ? "!min-w-[250px]"
                      : ""
                  }`}
                  style={{ border: "none" }}
                >
                  <div className="text-center border-[1px] py-1  border-stone-400 rounded-sm shadow-md text-[0.8rem]">
                    {selectedFilter === FilterEnum.Rolling3Months
                      ? "Last Rolling 3 Months"
                      : +selectedYear - 1}
                  </div>
                </div>

                <EmptyCol
                  number={
                    selectedFilter === FilterEnum.Quarterly
                      ? 2
                      : selectedFilter === FilterEnum.Rolling3Months ||
                        selectedFilter === FilterEnum.YTD ||
                        selectedFilter === FilterEnum.Monthly
                      ? 1
                      : 0
                  }
                />
                {selectedFilter === FilterEnum.Rolling3Months && <ColumnGap />}
                <ColumnGap />

                <EmptyCol
                  number={
                    selectedFilter === FilterEnum.YTD ||
                    selectedFilter === FilterEnum.Monthly
                      ? 5
                      : selectedFilter === FilterEnum.Rolling3Months
                      ? 2
                      : 3
                  }
                />
              </div>
              {/* Second level header - */}
              <div
                className="financial-table-headers flex text-bold bg-white w-fit lg:min-w-[100%]"
                style={{ top: 40, border: "none" }}
              >
                {/* Empty header for particulars */}
                <div className="table-col particulars separate-col"></div>

                {/* Headers for current and previous year */}
                {[+selectedYear, +selectedYear - 1].map((year, index) => (
                  <React.Fragment key={index}>
                    <ColumnGap />
                    {monthsKey.map((header, i) => (
                      <div
                        className="table-col separate-col"
                        key={`${year}-${i}`}
                      >
                        {header.replace(/_/g, " ")}
                      </div>
                    ))}
                  </React.Fragment>
                ))}

                <ColumnGap />
                {/* Headers for difference columns */}
                {differenceKeyPrefixes.map((key, index) => {
                  return (
                    <div className="table-col separate-col" key={index}>
                      {key}
                    </div>
                  );
                })}
              </div>

              {/* Table Data */}
              {loading ? (
                <div>
                  <Loader />
                </div>
              ) : newData && newData.length > 0 ? (
                <div className="financial-table-data w-fit lg:min-w-[100%]">
                  {newData?.map((data, index) => (
                    <div className="financial-table-row" key={`data-${index}`}>
                      {/* Table particular */}
                      <div
                        className={`table-col particulars block  ${
                          getHighlights(data)
                            ? "bg-primary text-white font-bold"
                            : data.Item.includes(":")
                            ? "text-bold"
                            : ""
                        }  `}
                      >
                        <div className="flex items-center">{data.Item}</div>
                      </div>
                      <ColumnGap />
                      {/* This Year rows */}
                      {monthsKey?.map((month, index) => (
                        <>
                          <div
                            className={clsx([
                              "table-col",
                              getHighlights(data)
                                ? "bg-primary text-white font-bold"
                                : data?.Item?.toString().includes(":")
                                ? "bg-gray"
                                : "",
                            ])}
                            key={`${index}-${month}-ThisYear`}
                          >
                            {checkCellType(
                              data.Item,
                              formatData(month, data[`${month}_ThisYear`])
                            )}
                          </div>
                        </>
                      ))}
                      <ColumnGap />
                      {/* Last Year rows */}
                      {monthsKey?.map((month, index) => (
                        <div
                          className={clsx([
                            "table-col",
                            getHighlights(data)
                              ? "bg-primary text-white font-bold"
                              : data?.Item?.toString()?.includes(":")
                              ? "bg-gray"
                              : "",
                          ])}
                          key={`${index}-${month}-LastYear`}
                        >
                          {checkCellType(
                            data.Item,
                            formatData(month, data[`${month}_LastYear`])
                          )}
                        </div>
                      ))}
                      {/* Difference tab */}
                      <ColumnGap />

                      <div className={rowClassName(data)}>
                        {checkCellType(
                          data.Item,
                          formatAmount(data?.[differenceKeys?.[0]]?.toString())
                        )}
                      </div>
                      <div className={rowClassName(data)}>
                        {data?.[differenceKeys?.[1]] !== null &&
                          checkCellType(
                            data.Item,
                            formatPercentValue(
                              data?.[differenceKeys?.[1]]?.toString()
                            )
                          )}
                      </div>
                      <div className={rowClassName(data)}>
                        {checkCellType(
                          data.Item,
                          formatPercentValue(
                            data?.[differenceKeys?.[2]]?.toString()
                          )
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center h-full text-lg text-gray-500">
                  No data available for the selected filter.
                </div>
              )}
            </section>
          )}
        </section>
      )}
    </main>
  );
};

export default FinancialYTDComparison;
