import { clsx, Popover, Table } from "@mantine/core";
import { useEffect, useState } from "react";
import { getIcon, requestSort } from "../../../utils/helper/sortIcon";
import {
  Kpi_Table_Headers,
  KPIHEADER,
  MANAGER_TABLES,
} from "./utils/constants";
import {
  IBDRRes,
  IKpiData,
  IStageAccountRes,
} from "../../../types/salesPipeline";
import { formatAmount } from "../../../utils/helper/cellFormatter";
import ClientDetails from "./ClientDetails";
import { handleGroupAndSumData } from "./utils/helper";

const KpiTable = (props: {
  kpiData: IKpiData[] | null;
  bdrData: IBDRRes[] | null;
  loading: boolean;
  invoicingClients: IStageAccountRes[] | null;
}) => {
  const { kpiData, bdrData, loading, invoicingClients } = props;
  const sumedBdrData = handleGroupAndSumData(bdrData, "FullName", ["GP"]);

  return (
    <div className="flex flex-col gap-8 py-6">
      {MANAGER_TABLES.map(
        (managerType: { label: string; value: string }, index: number) => {
          return (
            <SalesMetricsTable
              key={index}
              managerType={managerType}
              kpiData={
                kpiData?.filter(
                  (d: IKpiData) => d.Title === managerType.label
                ) ?? []
              }
              loading={loading}
              invoicingClients={invoicingClients}
            />
          );
        }
      )}
      <div className="flex flex-col justify-center items-center gap-2">
        <div className="flex justify-center">
          <span className="bg-[#3D788B] text-white text-[0.8rem] py-[0.2rem] px-8 font-semibold rounded-md shadow-lg ">
            BDR Table
          </span>
        </div>
        <div className="max-h-[280px] bg-white rounded-lg shadow-lg overflow-auto w-[60%]">
          <Table
            fontSize={11}
            verticalSpacing={3}
            withColumnBorders
            horizontalSpacing={3}
          >
            <thead className="bg-[#3D788B] sticky top-0">
              <tr>
                <th className={`!p-1 !text-white w-[50%]`}>BDR Name</th>
                <th className={`!p-1 !text-white !text-center w-[50%]`}>
                  Gross Profit $
                </th>
              </tr>
            </thead>
            <tbody>
              {sumedBdrData && Object.keys(sumedBdrData).length > 0 ? (
                Object.values(sumedBdrData)?.map((data: any, index: number) => {
                  return (
                    <tr
                      className="last:border-b-[1px] font-semibold hover:bg-stone-100 "
                      key={index}
                    >
                      <td>{data.FullName}</td>
                      <td className="text-center">
                        <Popover
                          width={300}
                          position="bottom-end"
                          shadow="md"
                          offset={{ mainAxis: 4, crossAxis: 50 }}
                        >
                          <Popover.Target>
                            <div
                              className={clsx({
                                "cursor-pointer font-bold hover:text-[#3D788B]":
                                  Number(data.TotalGP) > 0,
                              })}
                            >
                              {formatAmount(data.TotalGP)}
                            </div>
                          </Popover.Target>
                          {Number(data.TotalGP) > 0 && (
                            <Popover.Dropdown>
                              <ClientDetails
                                clientDetails={data.data}
                                tableFillColor="#291611"
                                isInvoicing={true}
                              />
                            </Popover.Dropdown>
                          )}
                        </Popover>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={2} className="h-28 text-center text-slate-600">
                    No data
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

const SalesMetricsTable = (props: {
  managerType: { label: string; value: string };
  kpiData: IKpiData[] | null;
  loading: boolean;
  invoicingClients: IStageAccountRes[] | null;
}) => {
  const { managerType, kpiData, loading, invoicingClients } = props;

  const [sortedKPIData, setSortedKPIData] = useState<IKpiData[] | null>(
    kpiData
  );
  const [openedClientsPopup, setOpenedClientsPopup] = useState<string | null>(
    null
  );
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string;
  } | null>({
    key: "Manager",
    direction: "asc",
  });

  useEffect(() => {
    if (sortConfig && kpiData) {
      const sorted: any = requestSort(
        sortConfig.key ?? "",
        sortConfig.direction,
        kpiData
      );
      setSortedKPIData(sorted);
    }
  }, [sortConfig, kpiData]);

  return (
    <div className="flex flex-col  gap-2 min-h-[100px]">
      <div className="flex justify-center">
        <span className="bg-[#3D788B] text-white text-[0.8rem] py-[0.2rem] px-8 font-semibold rounded-md shadow-lg ">
          Sales Metrics By {managerType.label}
        </span>
      </div>
      <div className="min-h-32 max-h-[280px] bg-white rounded-lg shadow-lg overflow-auto">
        <Table
          fontSize={11}
          verticalSpacing={2}
          withColumnBorders
          horizontalSpacing={1}
        >
          <thead className="bg-[#3c788b] sticky top-0">
            <tr className="text-sm">
              {Kpi_Table_Headers.filter((header) =>
                sortedKPIData?.some((data) => data.hasOwnProperty(header.value))
              ).map(
                (header: { label: string; value: string }, index: number) => {
                  return (
                    <th
                      key={index}
                      className={`!p-1 !text-white  ${
                        header.value === "Branch" || header.value === "Manager"
                          ? "min-w-24 lg:min-w-28"
                          : "!text-center min-w-20"
                      }`}
                    >
                      <div className="flex items-center ">
                        <span className="flex-grow">
                          {header.value === "Manager"
                            ? managerType.label
                            : header.label}
                        </span>
                        <i
                          className="material-icons cursor-pointer self-end"
                          style={{ fontSize: "16px" }}
                          onClick={() =>
                            setSortConfig({
                              key: header.value,
                              direction:
                                sortConfig === null ||
                                sortConfig.direction === "desc"
                                  ? sortConfig?.key === header.value
                                    ? "asc"
                                    : "desc"
                                  : "desc",
                            })
                          }
                        >
                          {getIcon(header.value, sortConfig)}
                        </i>
                      </div>
                    </th>
                  );
                }
              )}
            </tr>
          </thead>
          <tbody>
            {sortedKPIData && !loading ? (
              sortedKPIData.length > 0 ? (
                sortedKPIData.map((data: IKpiData, index: number) => {
                  const kpiIndex = `KPI${index}`;
                  const invoicingData: IStageAccountRes[] =
                    invoicingClients?.filter(
                      (d: IStageAccountRes) => d.Manager === data.SalesManager
                    ) ?? [];

                  return (
                    <tr
                      className="last:border-b-[1px] font-semibold hover:bg-stone-100 "
                      key={kpiIndex}
                    >
                      {Kpi_Table_Headers.filter((header) =>
                        data.hasOwnProperty(header.value)
                      ).map(
                        (
                          header: { label: string; value: string },
                          index: number
                        ) => {
                          return (
                            <td
                              key={index}
                              className={`!px-1 ${
                                openedClientsPopup === kpiIndex &&
                                header.value === KPIHEADER.INVOICED
                                  ? "bg-[#d1dee1]"
                                  : ""
                              } ${
                                header.value === "Branch" ||
                                header.value === "Manager"
                                  ? ""
                                  : "!text-center"
                              }`}
                              onClick={() =>
                                setOpenedClientsPopup(
                                  openedClientsPopup !== kpiIndex &&
                                    header.value === KPIHEADER.INVOICED &&
                                    Number(data[header.value]) > 0
                                    ? kpiIndex
                                    : null
                                )
                              }
                            >
                              {header.value === KPIHEADER.INVOICED ? (
                                <Popover
                                  width={280}
                                  position="bottom-end"
                                  withArrow
                                  shadow="md"
                                  offset={2}
                                  onClose={() => setOpenedClientsPopup(null)}
                                >
                                  <Popover.Target>
                                    <div
                                      className={clsx({
                                        "cursor-pointer font-bold hover:text-[#3D788B]":
                                          Number(data[header.value]) > 0,
                                      })}
                                    >
                                      {data[header.value]}
                                    </div>
                                  </Popover.Target>
                                  {Number(data[header.value]) > 0 && (
                                    <Popover.Dropdown>
                                      <ClientDetails
                                        clientDetails={invoicingData}
                                        tableFillColor="#291611"
                                        isInvoicing={true}
                                      />
                                    </Popover.Dropdown>
                                  )}
                                </Popover>
                              ) : header.value === "PresidentGP" ? (
                                formatAmount(data[header.value])
                              ) : (
                                data[header.value]
                              )}
                            </td>
                          );
                        }
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={Kpi_Table_Headers.length}
                    className="h-32 text-center text-slate-600"
                  >
                    No data for selected filter
                  </td>
                </tr>
              )
            ) : (
              <tr className="h-32 text-xs text-slate-600 text-center">
                <td colSpan={Kpi_Table_Headers.length}>Loading Table...</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default KpiTable;
