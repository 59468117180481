import { Popover, Table } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import MonthWiseBarChart from "./MonthWiseBarChart";
import {
  APIClientCommentTooltip,
  APIClientCommentTooltipBarChart,
  APIClientCommentTooltipBarChartADA,
} from "../../../api/branch-top-focus";
import { ContentLoader } from "../../common/Loader";
import { groupByKey } from "../../../utils/group";
import { useMediaQuery } from "@mantine/hooks";
import { ITooltip } from "../../../types/branchTopFocus";

export const TableBarChart = (props: any) => {
  const {
    tableHeader,
    totalCounts,
    selectedDateRange,
    showTooltip,
    setShowTooltip,
    level,
  } = props;
  const [activeBranch, setActiveBranch] = useState<any>(null);
  const [tooltipData, setTooltipData] = useState<ITooltip[]>([]);
  const [tooltipBarchartData, setTooltipBarchartData] = useState([]);
  const [tooltipLoading, setTooltipLoading] = useState(false);
  const buttonRef = useRef<any>(null);
  const [sortCountDesc, setSortCountDesc] = useState<boolean | null>(true);
  const [sortBranchDesc, setSortBranchDesc] = useState<boolean | null>(null);
  const extraLargeScreen = useMediaQuery("(min-width: 1536px)");
  const largeScreen = useMediaQuery("(min-width: 648px)");

  const months: any = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };

  useEffect(() => {
    if (showTooltip) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showTooltip]);

  const handleClickOutside = (e: any) => {
    if (!buttonRef.current?.contains(e.target)) {
      setShowTooltip(false);
      setActiveBranch(null);
    }
  };

  useEffect(() => {
    const fetchTooltipData = async () => {
      if (activeBranch) {
        setTooltipLoading(true);

        try {
          const startDate = selectedDateRange.startdate
            ? selectedDateRange.startdate
            : "";
          const endDate = selectedDateRange.enddate
            ? selectedDateRange.enddate
            : "";
          const tooltipTableResponse = await APIClientCommentTooltip(
            activeBranch,
            startDate,
            endDate
          );
          setTooltipData(tooltipTableResponse.data);
          const tooltipBarchartResponse =
            level === "ADA"
              ? await APIClientCommentTooltipBarChartADA(
                  activeBranch,
                  startDate,
                  endDate
                )
              : await APIClientCommentTooltipBarChart(
                  activeBranch,
                  startDate,
                  endDate
                );
          setTooltipBarchartData(tooltipBarchartResponse.data);
        } catch (error) {
          console.log(error);
        } finally {
          setTooltipLoading(false);
        }
      }
    };

    fetchTooltipData();
  }, [activeBranch, selectedDateRange]);

  const monthNumber = Object.keys(
    groupByKey(tooltipBarchartData, "MonthNumber")
  );
  const selectedMonth = monthNumber.map((num) => months[parseInt(num)]);

  const maxTotal =
    totalCounts && totalCounts.length > 0
      ? Math.max(...totalCounts.map((d: any) => d.total))
      : 0;

  const popupTableheaders = [
    "Client Name",
    " Total % of client visits",
    "Total % of QBR",
    "Total for current month",
    selectedMonth?.join(", "),
  ];

  const showTable = (tableBranch: any, close?: boolean) => {
    if (tableBranch === activeBranch) {
      setShowTooltip(false);
      setActiveBranch(null);
    } else {
      setShowTooltip(true);
      setActiveBranch(tableBranch);
    }
  };
  totalCounts.sort((a: any, b: any) =>
    sortCountDesc === null
      ? 0
      : sortCountDesc
      ? b.total - a.total
      : a.total - b.total
  );
  totalCounts.sort((a: any, b: any) =>
    sortBranchDesc === null
      ? 0
      : sortBranchDesc
      ? b.branch.localeCompare(a.branch)
      : a.branch.localeCompare(b.branch)
  );
  const highlightCell = (rank: any) => {
    return rank >= 1 && rank <= 5 ? "bg-[#edf6ff]" : "";
  };

  return (
    <div className="bg-white w-full h-[190px] overflow-y-scroll overflow-x-auto ">
      <Table verticalSpacing={1} horizontalSpacing={1} withColumnBorders>
        <thead className="bg-[#2c90c3] sticky top-0 z-20">
          <th className="p-1 text-xs text-left text-white">
            <div className="flex items-center gap-1">
              Branch
              <i
                className="material-icons cursor-pointer"
                style={{ fontSize: "16px" }}
                onClick={() => {
                  setSortBranchDesc(
                    sortBranchDesc === null || sortBranchDesc ? false : true
                  );
                  setSortCountDesc(null);
                }}
              >
                {sortBranchDesc === null
                  ? "unfold_more"
                  : sortBranchDesc
                  ? "keyboard_arrow_down"
                  : "keyboard_arrow_up"}
              </i>
            </div>
          </th>
          <th className="p-1 text-xs text-left text-white ">
            <div className="flex items-center gap-1">
              {tableHeader}{" "}
              <i
                className="material-icons cursor-pointer"
                style={{ fontSize: "16px" }}
                onClick={() => {
                  setSortCountDesc(
                    sortCountDesc === null || sortCountDesc ? false : true
                  );
                  setSortBranchDesc(null);
                }}
              >
                {sortCountDesc === null
                  ? "unfold_more"
                  : sortCountDesc
                  ? "keyboard_arrow_down"
                  : "keyboard_arrow_up"}
              </i>{" "}
            </div>
          </th>
        </thead>
        <tbody>
          {totalCounts.length > 0 ? (
            totalCounts.map((d: any, index: any) => {
              // {Object.entries((key: any,value:any) => {
              const currentBranch = d.branch;
              return (
                <tr className=" text-xs last:border-b-2" key={index}>
                  <td
                    className="w-[30%] text-xs"
                    style={{ paddingLeft: "5px", fontSize: "12px" }}
                  >
                    {d.branch}
                  </td>
                  <td className="flex justify-between h-6 ">
                    <div
                      className="bg-[#dbedf6]  font-medium relative"
                      style={{
                        width: maxTotal === 0 ? 0 : (d.total / maxTotal) * 500,
                      }}
                    >
                      <div className="absolute left-10 text-xs">{d.total}</div>
                    </div>
                    {tableHeader === "Total Number of Clients" && (
                      <Popover
                        clickOutsideEvents={["mouseup", "touchend"]}
                        width={extraLargeScreen ? 800 : largeScreen ? 600 : 320}
                        position="right-start"
                        opened
                        zIndex={100}
                      >
                        <Popover.Target>
                          <i
                            className="material-icons cursor-pointer text-blue-400 hover:text-blue-300 sticky right-0"
                            onClick={(e: any) => {
                              e.stopPropagation();
                              showTable(d.branch);
                            }}
                            // onMouseEnter={() => {
                            //   setActiveBranch(currentBranch);
                            //   setShowTooltip(true);
                            // }}
                            ref={buttonRef}
                          >
                            {activeBranch === currentBranch && showTooltip
                              ? "play_arrow"
                              : "arrow_drop_down"}
                          </i>
                        </Popover.Target>
                        {activeBranch === currentBranch && showTooltip && (
                          <Popover.Dropdown>
                            <div className="h-[440px]  overflow-y-scroll bg-white">
                              <Table verticalSpacing={0} withColumnBorders>
                                <thead
                                  className="bg-[#aacbda] sticky top-0 z-20 cursor-pointer"
                                  key={index}
                                >
                                  <tr>
                                    {popupTableheaders.map((h: any) => (
                                      <th
                                        style={{
                                          color: "black",
                                          fontSize: 11,
                                          padding: 1,
                                          textAlign: "center",
                                        }}
                                      >
                                        {h}
                                      </th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody>
                                  {tooltipData?.map((d: ITooltip) => {
                                    return (
                                      <tr className="border-b-2 ">
                                        <td
                                          style={{
                                            fontSize: "11px",
                                            width: "16%",
                                          }}
                                          className={highlightCell(d.rank)}
                                        >
                                          {d.clientname}
                                        </td>
                                        <td
                                          style={{
                                            fontSize: "11px",
                                            width: "16%",
                                          }}
                                          className={highlightCell(d.rank)}
                                        >
                                          {d.ClientVisit}
                                        </td>
                                        <td
                                          style={{
                                            fontSize: "11px",
                                            width: "16%",
                                          }}
                                          className={highlightCell(d.rank)}
                                        >
                                          {d.QBR}
                                        </td>
                                        <td
                                          style={{
                                            fontSize: "11px",
                                            width: "16%",
                                          }}
                                          className={highlightCell(d.rank)}
                                        >
                                          {d.CurrentMonth}
                                        </td>
                                        <td
                                          width={200}
                                          className={highlightCell(d.rank)}
                                        >
                                          {tooltipBarchartData.filter(
                                            (b: any) =>
                                              b.clientname === d.clientname
                                          ).length === 0 ? (
                                            <div className="h-10 flex justify-center items-center text-slate-500 text-xs">
                                              --
                                            </div>
                                          ) : (
                                            <MonthWiseBarChart
                                              months={months}
                                              data={tooltipBarchartData.filter(
                                                (b: any) =>
                                                  b.clientname === d.clientname
                                              )}
                                            />
                                          )}
                                        </td>

                                        {/* <td
                                        onClick={() => showChart(currentClient)}
                                        className={`cursor-pointer ${
                                          showBarChart &&
                                          activeClient === "Client"
                                            ? "bg-blue-100"
                                            : ""
                                        }`}
                                      >
                                        {showBarChart &&
                                          activeClient === currentClient && (
                                            <div className="absolute w-[400px] left-[90%] bg-white p-2 rounded-lg shadow-md">
                                              <MonthWiseBarChart />
                                            </div>
                                          )}
                                      </td> */}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </div>
                            <div className="text-center text-[0.7rem] py-[0.1rem]">
                              Note: The clients and totals mentioned here are
                              unique regardless of the total number of clients
                              in the client comment metrics table.
                            </div>
                            <ContentLoader loading={tooltipLoading} />
                          </Popover.Dropdown>
                        )}
                      </Popover>
                    )}
                    {/* {showTooltip && activeBranch === currentBranch && (
                    <div className="absolute bg-slate-100 border-2 border-red-300  w-[20rem] p-5 z-30">
                      <div className="flex justify-between">
                        <span
                          className="cursor-pointer"
                          onClick={() => showTable(null, true)}
                        >
                          <i className="material-icons">close</i>
                        </span>
                      </div>
                      table
                    </div>
                  )} */}
                  </td>
                </tr>
              );
            })
          ) : (
            <div className="text-xs text-slate-500">No data</div>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableBarChart;
