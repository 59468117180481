/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { combineReducers } from "redux";
import { filterReducer } from "./Filter/reducer";
import { financialReportReducer } from "./FinancialReport/reducer";
import { arAgingReducer } from "./ArAging/reducer";
import { dailyNumberReducer } from "./DailyNumbers/reducer";
import { companySummaryReducer } from "./CompanySummary/reducer";
import { weeklyReportReducer } from "./WeeklyReport/reducer";
import { pipSummaryReducer } from "./PipSummary/reducer";
import { cgsReducer } from "./CustomGoalSetting/reducer";

export default combineReducers({
  filterReducer,
  financialReportReducer,
  arAgingReducer,
  dailyNumberReducer,
  companySummaryReducer,
  weeklyReportReducer,
  pipSummaryReducer,
  cgsReducer,
});
