import SalesTable from "./SalesTable";
import { groupByKey } from "../../../utils/group";
import GPTable from "./GPTable";
import { MONTH } from "./utlis/constants";

const SalesManagerTables = (props: any) => {
  const {
    filteredGpData,
    selectedYear,
    setSelectedYear,
    branchesList,
    areaSelected,
    subAreaSelected,
    regionSelected,
    districtSelected,
    selectedBranch,
    setSelectedBranch,
  } = props;

  const monthName = Object.keys(groupByKey(filteredGpData, "MonthName"));
  const sortedMonth: string[] = monthName.sort(
    (a: string, b: string) => MONTH[a] - MONTH[b]
  );

  return (
    <div className="mx-16 flex flex-col h-[82vh] gap-6">
      <GPTable
        filteredGpData={filteredGpData}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        months={sortedMonth}
      />
      {/* <GrossProfit13WeeksTable
        areaSelected={areaSelected}
        subAreaSelected={subAreaSelected}
        regionSelected={regionSelected}
      /> */}
      <SalesTable
        branchesList={branchesList}
        areaSelected={areaSelected}
        subAreaSelected={subAreaSelected}
        regionSelected={regionSelected}
        districtSelected={districtSelected}
        selectedBranch={selectedBranch}
        setSelectedBranch={setSelectedBranch}
      />
    </div>
  );
};

export default SalesManagerTables;
