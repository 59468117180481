/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

export const getMaxRoundedValue = (value: number) => {
  if (value === 0) return 10; // Handle edge case for zero
  //Add up certain amount to make max number a little higher than actual according to magnitude
  const magnitude = Math.pow(10, Math.floor(Math.log10(value)));
  value += magnitude / 10;
  //Round off the number checking exponential
  const exp = value.toExponential();
  const [num, exponent] = exp.split("e");
  if (Number(exponent) <= 3) {
    return Number(Math.ceil(parseFloat(num)) + "e" + exponent);
  }
  return Number((parseFloat(num) + 0.1).toFixed(1) + "e" + exponent);
};

export const getMinRoundedValue = (value: number) => {
  if (value === 0) return 0; // Handle edge case for zero
  //Substract certain amount to make max number a little higher than actual according to magnitude
  const magnitude = Math.pow(10, Math.floor(Math.log10(Math.abs(value))));
  value -= magnitude;
  //Round off the number checking exponential
  const exp = value.toExponential();
  const [num, exponent] = exp.split("e");
  if (Number(exponent) <= 3) {
    return Number(Math.floor(parseFloat(num)) + "e" + exponent);
  }
  return Number((parseFloat(num) - 0.1).toFixed(1) + "e" + exponent);
};
