/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import ReactApexChart from "react-apexcharts";
import { Component, useEffect, useState } from "react";
import { getWeekData } from "./utils/weekdata";
import { formatAmount } from "../../../utils/helper/cellFormatter";
import { useLabelFormatter } from "../../../utils/hooks/useLabelFormatter";
import { useSelector } from "react-redux";
import { getMaxRoundedValue, getMinRoundedValue } from "./utils/operations";
import { clientChartColors } from "./utils/constants";

const ClientSummaryChart = (props) => {
  const labelFormatter = useLabelFormatter();

  //Show full year data
  const weekLabels = useSelector((state) => state.filterReducer.weekLabels);

  //Show three months data
  const threeMonthsweekLabels = props.weeksClientData
    ? props.weeksClientData
        .filter((data) => data.Metrics === "This Year")
        .map((data) => data.WeekEndingDate)
    : [];

  const weekNumbers = props.weeksClientData
    ? props.weeksClientData
        .filter((data) => data.Metrics === "This Year")
        .map((data) => data.CalendarWeekNumber)
    : [];

  const years = props.data ? Object.keys(props.data) : [];

  let maxValue;
  let minValue;
  if (props.data) {
    const allData = Object.values(props.data)
      ?.flatMap((data) => data?.map((data) => data[props.dataMode]))
      ?.filter((d) => d);

    maxValue = getMaxRoundedValue(Math.max(...allData));
    minValue = getMinRoundedValue(Math.min(...allData));
  }

  const getChartType = (data) => {
    if (!data) return "line";
    const years = Object.keys(data);
    if (years.length > 1) {
      return "line";
    } else {
      const weekNo = data[years[0]]
        .map((d) => d.CalendarWeekNumber)
        .sort((a, b) => a - b);

      // Check if the week numbers are continuous
      const isContinuousWeek = () => {
        for (let i = 1; i < weekNo.length; i++) {
          if (weekNo[i] === weekNo[i - 1] + 1) {
            return true;
          }
        }
        return false;
      };

      return isContinuousWeek() ? "line" : "scatter";
    }
  };

  const chartType = getChartType(props.data) ?? "line";

  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: chartType,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: clientChartColors
      .filter(({ label }) => years.includes(labelFormatter(label.toString())))
      .map(({ color }) => color),

    title: {},
    legend: {
      position: "top",
      show: false,
      horizontalAlign: "right",
      tooltipHoverFormatter: function (val, opts) {
        return val;
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          fontSize: "7px",
        },
        formatter: (v) => formatAmount(v),
      },
      max: props.sameClientRatio
        ? props.dataMode === "Revenue"
          ? props.totalData.RevSARMaxValueClients
          : props.totalData.GPSARMaxValueClients
        : maxValue,
      min: props.sameClientRatio
        ? props.dataMode === "Revenue"
          ? props.totalData.RevSARMinValueClients
          : props.totalData.GPSARMinValueClients
        : minValue,
      tickAmount: 5,
    },
    stroke: {
      width: 1,
      dashArray: 0,
      curve: "straight",
    },
    markers: {
      size: chartType === "line" ? 1 : 2,
      strokeWidth: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      labels: {
        style: {
          fontSize: "5px",
        },
      },
      categories: weekLabels,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  });

  useEffect(() => {
    if (props.data && Object.keys(props.data).length > 0) {
      setChartData();
    }
  }, [props.data, props.dataMode]);

  const setChartData = () => {
    // const series = Object.keys(tempChartData).map((key, i) => {
    //   return {
    //     name: key,
    //     data: [
    //       ...getWeekDataForClients(
    //         { ...tempChartData, ...props.data }[key],
    //         props.dataMode,
    //         key,
    //       ),
    //     ],
    //   };
    // });
    const series = Object.keys(props.data).map((key, i) => {
      return {
        name: key,
        data: [...getWeekData({ ...props.data }[key], props.dataMode)],
      };
    });

    setOptions({
      ...options,
      stroke: {
        width: 1,
        dashArray: 0,
        curve: "straight",
      },
      colors: clientChartColors
        .filter(({ label }) => years.includes(labelFormatter(label)))
        .map(({ color }) => color),

      markers: {
        size: chartType === "line" ? 1 : 2,
        strokeWidth: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        labels: {
          style: {
            fontSize: "5px",
          },
        },
        categories: weekLabels,
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            fontSize: "7px",
          },
          formatter: (v) => formatAmount(v),
        },
        max: props.sameClientRatio
          ? props.dataMode === "Revenue"
            ? props.totalData.RevSARMaxValueClients
            : props.totalData.GPSARMaxValueClients
          : maxValue,
        min: props.sameClientRatio
          ? props.dataMode === "Revenue"
            ? props.totalData.RevSARMinValueClients
            : props.totalData.GPSARMinValueClients
          : minValue,
        tickAmount: 5,
      },
    });
    setSeries(series);
  };

  useEffect(() => {
    setOptions({
      ...options,
      xaxis: {
        labels: {
          style: {
            fontSize: "5px",
          },
        },
        categories: weekLabels,
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            fontSize: "7px",
          },
          formatter: (v) => formatAmount(v),
        },
        max: props.sameClientRatio
          ? props.dataMode === "Revenue"
            ? props.totalData.RevSARMaxValueClients
            : props.totalData.GPSARMaxValueClients
          : maxValue,
        min: props.sameClientRatio
          ? props.dataMode === "Revenue"
            ? props.totalData.RevSARMinValueClients
            : props.totalData.GPSARMinValueClients
          : minValue,
        tickAmount: 5,
      },
    });
  }, [props.sameClientRatio]);

  return (
    <div>
      <div className="chart-header">
        <div className="flex items-center justify-center w-full">
          <div>
            <div className="chart-header-title text-center">
              <div className="flex gap-2 items-center">
                <span>{props.name.label}</span>
                {props.isVMS && (
                  <i className="material-icons text-[1.2rem]">star_border</i>
                )}
              </div>
              <div>Rank: {props.name.rank}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="chart-area">
        <div className="chart-legend-area">
          <div className="colors-area">
            {clientChartColors.map((v, key) => (
              <div className="color-box" key={key}>
                <div
                  className="color-circle"
                  style={{ backgroundColor: `${v.color}` }}
                ></div>
                <div className={"color-name"}>{labelFormatter(v.label)}</div>
              </div>
            ))}
          </div>
        </div>
        <div className={"h-full flex-grow"}>
          <ApexChart options={options} series={series} type={chartType} />
        </div>
      </div>
    </div>
  );
};

class ApexChart extends Component {
  render() {
    return (
      <>
        <ReactApexChart
          options={this.props.options}
          series={this.props.series}
          type={this.props.type}
          height={250}
        />
      </>
    );
  }
}

export default ClientSummaryChart;
