import { ITableHeader } from "../../../../types/salesManager";

export const TABLE_HEADERS: string[] = [
  "Branch",
  "Sales Manager",
  "Sales Cold Call",
  "Sales Prospect",
  "Sales Appointment",
  "Sales Tour",
  "Sales Risk",
  "Sales Proposal",
  "Sales Credit",
  "Sales Won",
  "Step %",
  "Total Comments",
];

export const SALES_VALUES: Record<number, { label: string; color: string }> = {
  1: { label: "Sales Cold Call", color: "bg-[#7ee3a5]" },
  2: { label: "Sales Prospect", color: "bg-[#d2d1d1]" },
  3: { label: "Sales Appointment", color: "bg-[#7ee3a5]" },
  4: { label: "Sales Tour", color: "bg-[#7ee3a5]" },
  5: { label: "Sales Risk", color: "bg-[#d2d1d1]" },
  6: { label: "Sales Proposal", color: "bg-[#7ee3a5]" },
  7: { label: "Sales Credit", color: "bg-[#d2d1d1]" },
  8: { label: "Sales Won", color: "bg-[#d2d1d1]" },
};

export const MONTH: Record<string, number> = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

export const GP_TABLE_HEADER: ITableHeader[] = [
  { label: "MTD", value: "MTD", bgColor: "bg-[#bfeebe]" },
  { label: "QGP", value: "QGP", bgColor: "bg-[#c0b1fc]" },
  { label: "NQGP", value: "NQGP", bgColor: "bg-[#d9cafc]" },
  // { label: "13 Weeks", value: "13 weeks GP", bgColor: "bg-[#d8d7d9]" },
  { label: "YTD", value: "YTD", bgColor: "bg-[#c4e3ff]" },
];

export const GP_INITIAL_HEADER: string[] = [
  "Sales Manager",
  "Branch(s)",
  "Tenure (Year,Month,Day)",
];
