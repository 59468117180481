/*
 * Copyright (C) 2022 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { Route, Routes } from "react-router";
import { WeeklyReport } from "../pages/WeeklyReport";
import AuthenticatedLayout from "../layouts/AuthenticatedLayout";
import { ArAgingDashboard } from "../pages/ArAgingDashboard";
import { Profile } from "../pages/Profile";
import { CompanySummaryReport } from "../pages/CompanySummaryReport";
import { Landing } from "../pages/Landing";
import { DailyNumberReport } from "../pages/DailyNumberReport";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Forbidden } from "../pages/Forbidden";
import { getAllUserReports, getUserGroups } from "../api/user";
import { useEffect, useState } from "react";
import { loginRequest } from "../config/authConfig";
import { REPORT_NAMES } from "../utils/helper/constants";
import { DemoCalendar } from "../pages/DemoCalendar";
import { ClientSegmentation } from "../pages/ClientSegmentation";
import { PipSummaryReport } from "../pages/PipSummaryReport";
import { setCurrentYear, setUserHasNotesAccess } from "../store/Filter/actions";
import { useDispatch } from "react-redux";
import { KearlKurveReport } from "../pages/KearlKurveReport";
import { FinancialReportWip } from "../pages/FinancialReportWip";
import { TopClients } from "../pages/TopClients";
import { setFinancialLocation } from "../store/FinancialReport/actions";
import { EmployeeRedployment } from "../pages/EmployeeRedeployment";
import { Top20Clients } from "../pages/Top20Clients";
import SalesActivity from "../pages/SalesActivity";
import { setPipBranches, setPipRegions } from "../store/PipSummary/actions";
import BranchTopFocus from "../pages/BranchTopFocus";
import SalesManager from "../pages/SalesManager";
import GoalSetting from "../pages/GoalSetting";
import ClientRetention from "../pages/ClientRetention";
import BonusReviewReport from "../pages/BonusReviewReport";
import { APIGetCurrentYear } from "../api/filter";
import GoalSettingWip from "../pages/GoalSettingWip";
import ArClerkAssignment from "../components/modules/arAging/ArClerkAssignment";
import { FinancialReport } from "../pages/FinancialReport";
import ProtectedRoute from "./ProtectedRoute";
import FinancialYTDComparison from "../pages/FinancialYTDComparison";
import SalesPipeline from "../pages/SalesPipeline";
import IncomeModel from "../pages/IncomeModel";
import {
  setCgsAreas,
  setCgsCompany,
  setCgsDistricts,
  setCgsSubareas,
} from "../store/CustomGoalSetting/action";

export const GritRoutes = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const { accounts, instance } = useMsal();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (accounts[0]) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };

      instance.acquireTokenSilent(request).then((response) => {
        getUserGroups(response.accessToken).then(async (res) => {
          const groups = res.value.map((group: any) => group.id);
          const userHasNotesAccess = groups.includes(
            process.env.REACT_APP_COMPANY_SUMMARY_NOTES_GROUP_ID
          );
          dispatch(setUserHasNotesAccess(userHasNotesAccess));
          localStorage.setItem("username", accounts[0].username);
          const reportAccess = await getAllUserReports({
            groups: "'" + groups.join("','") + "'",
            username: accounts[0].username,
            name: accounts[0].name,
          });
          setReports(reportAccess.data);
          const location = res.value
            .filter((g: any) => g.displayName.startsWith("Location"))
            .map((g: any) => {
              const location = g.displayName.split(" | ");
              return {
                level: location?.[0].split("_")?.[1] ?? location?.[0],
                name: location?.[1],
              };
            });
          dispatch(setFinancialLocation(location));
          const pipRegions = res.value
            .filter((g: any) => g.displayName.startsWith("PIP_Region"))
            .map((g: any) => {
              const location = g.displayName.split(" | ");
              return location?.[1];
            });
          const pipBranches = res.value
            .filter((g: any) => g.displayName.startsWith("PIP_Branch"))
            .map((g: any) => {
              const location = g.displayName.split(" | ");
              return location?.[1];
            });
          const cgsDistricts = res.value
            .filter((g: any) => g.displayName.startsWith("CGS_AreaBranch"))
            .map((g: any) => {
              const location = g.displayName.split(" | ");
              return location?.[1];
            });
          const cgsSubareas = res.value
            .filter((g: any) => g.displayName.startsWith("CGS_VP"))
            .map((g: any) => {
              const location = g.displayName.split(" | ");
              return location?.[1];
            });
          const cgsAreas = res.value
            .filter(
              (g: any) =>
                g.displayName.includes("CGS_Area") &&
                !g.displayName.startsWith("CGS_AreaBranch")
            )
            .map((g: any) => {
              const location = g.displayName.split(" | ");
              return location?.[1];
            });
          const cgsCompany = res.value
            .filter((g: any) => g.displayName.startsWith("CGS_Company"))
            .map((g: any) => {
              const location = g.displayName.split(" | ");
              return location?.[1];
            });
          dispatch(setCgsDistricts(cgsDistricts));
          dispatch(setCgsSubareas(cgsSubareas));
          dispatch(setCgsAreas(cgsAreas));
          dispatch(setCgsCompany(cgsCompany));
          dispatch(setPipRegions(pipRegions));
          dispatch(setPipBranches(pipBranches));
          setLoading(false);
        });
      });
    }
  }, [isAuthenticated, accounts, instance]);

  useEffect(() => {
    APIGetCurrentYear().then((res) => {
      dispatch(setCurrentYear(res.data[0][""]));
    });
  }, []);

  const hasReportAccess = (report_name: any) => {
    const exists = reports.filter(
      (report: any) => report.ReportName === report_name
    );
    return exists.length > 0;
  };

  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute loading={loading} hasAccess={true}>
            <Landing loading={loading} reports={reports} />
          </ProtectedRoute>
        }
        path={""}
      />
      <Route
        element={
          <ProtectedRoute
            loading={loading}
            hasAccess={hasReportAccess(REPORT_NAMES.AR_AGING_REPORT)}
          >
            <ArAgingDashboard />
          </ProtectedRoute>
        }
        path={"/ar-aging/*"}
      />
      <Route
        element={
          <ProtectedRoute
            loading={loading}
            hasAccess={hasReportAccess(REPORT_NAMES.AR_AGING_REPORT)}
          >
            <ArAgingDashboard wip={true} />
          </ProtectedRoute>
        }
        path={"/ar-aging-wip/*"}
      />
      <Route
        element={
          <ProtectedRoute
            loading={loading}
            hasAccess={hasReportAccess(REPORT_NAMES.AR_AGING_REPORT)}
          >
            <ArClerkAssignment wip={true} />
          </ProtectedRoute>
        }
        path={"/ar-view-wip"}
      />
      <Route
        element={
          <ProtectedRoute
            loading={loading}
            hasAccess={hasReportAccess(REPORT_NAMES.FINANCIAL_REPORT)}
          >
            <FinancialReportWip test={true} />
          </ProtectedRoute>
        }
        path={"/financial-report-test"}
      />
      <Route
        element={
          <ProtectedRoute
            loading={loading}
            hasAccess={hasReportAccess(REPORT_NAMES.FINANCIAL_REPORT)}
          >
            <FinancialReportWip wip={true} />
          </ProtectedRoute>
        }
        path={"/financial-report-wip"}
      />
      <Route
        element={
          <ProtectedRoute
            loading={loading}
            hasAccess={hasReportAccess(REPORT_NAMES.FINANCIAL_REPORT)}
          >
            <FinancialReport />
          </ProtectedRoute>
        }
        path={"/financial-report"}
      />
      {hasReportAccess(REPORT_NAMES.ADASTAFF_INC) ? (
        <>
          <Route
            element={
              <ProtectedRoute loading={loading} hasAccess={true}>
                <CompanySummaryReport adaOnly={true} />
              </ProtectedRoute>
            }
            path={"/company-summary-ada"}
          />
          <Route
            element={
              <ProtectedRoute loading={loading} hasAccess={false}>
                <Forbidden />
              </ProtectedRoute>
            }
            path={"/company-summary"}
          />
        </>
      ) : (
        <>
          <Route
            element={
              <ProtectedRoute
                loading={loading}
                hasAccess={hasReportAccess(REPORT_NAMES.COMPANY_SUMMARY_REPORT)}
              >
                <CompanySummaryReport
                  isAdaAccess={hasReportAccess(REPORT_NAMES.ASCEND_ADA_REPORTS)}
                />
              </ProtectedRoute>
            }
            path={"/company-summary"}
          />
          <Route
            element={
              <ProtectedRoute
                loading={loading}
                hasAccess={hasReportAccess(REPORT_NAMES.ASCEND_ADA_REPORTS)}
              >
                <CompanySummaryReport adaOnly={true} />
              </ProtectedRoute>
            }
            path={"/company-summary-ada"}
          />
        </>
      )}
      <Route
        element={
          <ProtectedRoute
            loading={loading}
            hasAccess={hasReportAccess(REPORT_NAMES.COMPANY_SUMMARY_REPORT)}
          >
            <CompanySummaryReport wip={true} />
          </ProtectedRoute>
        }
        path={"/company-summary-wip"}
      />
      <Route
        element={
          <ProtectedRoute loading={loading} hasAccess={true}>
            <CompanySummaryReport wip={true} executive={true} />
          </ProtectedRoute>
        }
        path={"/executive-company-summary-wip"}
      />
      <Route
        element={
          <ProtectedRoute
            loading={loading}
            hasAccess={hasReportAccess(REPORT_NAMES.PIP_SUMMARY)}
          >
            <CompanySummaryReport executive={true} />
          </ProtectedRoute>
        }
        path={"/executive-company-summary"}
      />
      {hasReportAccess(REPORT_NAMES.ADASTAFF_INC) ? (
        <>
          <Route
            element={
              <ProtectedRoute loading={loading} hasAccess={true}>
                <DailyNumberReport adaOnly={true} />
              </ProtectedRoute>
            }
            path={"/daily-numbers-ada"}
          />
          <Route
            element={
              <ProtectedRoute loading={loading} hasAccess={false}>
                <Forbidden />
              </ProtectedRoute>
            }
            path={"/daily-numbers"}
          />
        </>
      ) : (
        <>
          <Route
            element={
              <ProtectedRoute
                loading={loading}
                hasAccess={hasReportAccess(REPORT_NAMES.DAILY_NUMBERS)}
              >
                <DailyNumberReport
                  isAscendAdaAccess={hasReportAccess(
                    REPORT_NAMES.ASCEND_ADA_REPORTS
                  )}
                />
              </ProtectedRoute>
            }
            path={"/daily-numbers"}
          />
          <Route
            element={
              <ProtectedRoute
                loading={loading}
                hasAccess={hasReportAccess(REPORT_NAMES.ASCEND_ADA_REPORTS)}
              >
                <DailyNumberReport adaOnly={true} />
              </ProtectedRoute>
            }
            path={"/daily-numbers-ada"}
          />
        </>
      )}
      <Route
        element={
          <ProtectedRoute
            loading={loading}
            hasAccess={hasReportAccess(REPORT_NAMES.PIP_SUMMARY)}
          >
            <PipSummaryReport executive={true} />
          </ProtectedRoute>
        }
        path={"/executive-pip-summary"}
      />
      <Route
        element={
          <ProtectedRoute loading={loading} hasAccess={true}>
            <PipSummaryReport />
          </ProtectedRoute>
        }
        path={"/pip-summary"}
      />
      <Route
        element={
          <ProtectedRoute loading={loading} hasAccess={true}>
            <KearlKurveReport isAscendAdaAccess={false} />
          </ProtectedRoute>
        }
        path={"kearl-kurve"}
      />
      <Route
        element={
          <ProtectedRoute loading={loading} hasAccess={true}>
            <TopClients isAscendAdaAccess={false} />
          </ProtectedRoute>
        }
        path={"/top-clients"}
      />
      <Route
        element={
          <ProtectedRoute loading={loading} hasAccess={true}>
            <TopClients wip={true} />
          </ProtectedRoute>
        }
        path={"/top-clients-wip"}
      />
      <Route
        element={
          <ProtectedRoute
            loading={loading}
            hasAccess={hasReportAccess(REPORT_NAMES.COMPANY_SUMMARY_REPORT)}
          >
            <CompanySummaryReport goal={true} wip={true} />
          </ProtectedRoute>
        }
        path={"/company-summary-goal"}
      />
      <Route
        element={
          <ProtectedRoute
            loading={loading}
            hasAccess={hasReportAccess(REPORT_NAMES.WEEKLY_REPORT)}
          >
            <WeeklyReport isAscendAdaAccess={false} />
          </ProtectedRoute>
        }
        path={"/weekly-report"}
      />
      <Route
        element={
          <ProtectedRoute
            loading={loading}
            hasAccess={hasReportAccess(REPORT_NAMES.COMPANY_SUMMARY_REPORT)}
          >
            <EmployeeRedployment />
          </ProtectedRoute>
        }
        path={"/employee-redeployment"}
      />
      <Route
        element={
          <ProtectedRoute
            loading={loading}
            hasAccess={hasReportAccess(REPORT_NAMES.COMPANY_SUMMARY_REPORT)}
          >
            <SalesActivity />
          </ProtectedRoute>
        }
        path={"/sales-activity"}
      />
      <Route
        element={
          <ProtectedRoute
            loading={loading}
            hasAccess={hasReportAccess(REPORT_NAMES.COMPANY_SUMMARY_REPORT)}
          >
            <BranchTopFocus isAscendAdaAccess={false} />
          </ProtectedRoute>
        }
        path={"/branch-top-focus"}
      />
      <Route
        element={
          <ProtectedRoute loading={loading} hasAccess={true}>
            <ClientSegmentation isAscendAdaAccess={false} />
          </ProtectedRoute>
        }
        path={"/client-segmentation"}
      />
      <Route
        element={
          <ProtectedRoute
            loading={loading}
            hasAccess={hasReportAccess(REPORT_NAMES.SALES_MANAGER)}
          >
            <SalesManager />
          </ProtectedRoute>
        }
        path={"/sales-manager"}
      />
      <Route
        element={
          <ProtectedRoute
            loading={loading}
            hasAccess={hasReportAccess(REPORT_NAMES.GOAL_SETTING)}
          >
            <GoalSetting />
          </ProtectedRoute>
        }
        path={"/goal-setting"}
      />
      <Route
        element={
          <ProtectedRoute
            loading={loading}
            hasAccess={hasReportAccess(REPORT_NAMES.GOAL_SETTING)}
          >
            <GoalSettingWip />
          </ProtectedRoute>
        }
        path={"/goal-setting-wip"}
      />
      <Route
        element={
          <ProtectedRoute
            loading={loading}
            hasAccess={hasReportAccess(REPORT_NAMES.COMPANY_SUMMARY_REPORT)}
          >
            <ClientRetention />
          </ProtectedRoute>
        }
        path={"/client-retention"}
      />
      <Route
        element={
          <ProtectedRoute
            loading={loading}
            hasAccess={hasReportAccess(REPORT_NAMES.COMPANY_SUMMARY_REPORT)}
          >
            <BonusReviewReport />
          </ProtectedRoute>
        }
        path={"/bonus-review"}
      />
      <Route
        element={
          <ProtectedRoute
            loading={loading}
            hasAccess={hasReportAccess(REPORT_NAMES.COMPANY_SUMMARY_REPORT)}
          >
            <Top20Clients />
          </ProtectedRoute>
        }
        path={"/top-20-clients"}
      />
      <Route
        element={
          <ProtectedRoute
            loading={loading}
            hasAccess={hasReportAccess(REPORT_NAMES.FINANCIAL_REPORT)}
          >
            <FinancialYTDComparison />
          </ProtectedRoute>
        }
        path={"/financial-ytd-comparison"}
      />
      <Route
        element={
          <ProtectedRoute
            loading={loading}
            hasAccess={hasReportAccess(REPORT_NAMES.COMPANY_SUMMARY_REPORT)}
          >
            <SalesPipeline />
          </ProtectedRoute>
        }
        path={"/sales-pipeline/*"}
      />
      <Route
        element={
          <ProtectedRoute
            loading={loading}
            hasAccess={hasReportAccess(REPORT_NAMES.COMPANY_SUMMARY_REPORT)}
          >
            <IncomeModel />
          </ProtectedRoute>
        }
        path={"/income-model"}
      />
      <Route element={<AuthenticatedLayout />} path={"/login"} />
      <Route
        element={isAuthenticated ? <Profile /> : <AuthenticatedLayout />}
        path={"/profile"}
      />
      <Route
        element={isAuthenticated ? <DemoCalendar /> : <AuthenticatedLayout />}
        path={"/calendar"}
      />
    </Routes>
  );
};
