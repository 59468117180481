import { Table } from "@mantine/core";
import { ISalesActivity } from "../../../types/salesPipeline";
import React, { useEffect, useState } from "react";
import { getIcon, requestSort } from "../../../utils/helper/sortIcon";

const SalesActivity = (props: {
  tableData: Record<string, ISalesActivity> | null;
}) => {
  const { tableData } = props;
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const [sortedTableData, setSortedTableData] = useState<
    ISalesActivity[] | null
  >(tableData ? Object.values(tableData) : null);
  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string;
  } | null>({
    key: "TotalCommentCount",
    direction: "desc",
  });

  const changeCollapseState = (key: string) => {
    const expand = { ...expanded, [key]: !expanded[key] };
    setExpanded(expand);
  };

  useEffect(() => {
    if (sortConfig && tableData) {
      const sorted: any = requestSort(
        sortConfig.key ?? "",
        sortConfig.direction,
        Object.values(tableData)
      );
      setSortedTableData(sorted);
    }
  }, [sortConfig, tableData]);

  return (
    <div className="h-[50%] lg:flex-1 lg:h-full  flex flex-col gap-2 min-w-[38%]">
      <div className="flex justify-center">
        <span className="bg-[#446FAD] text-white text-xs xl:text-sm py-1 px-10 font-semibold rounded-lg shadow-lg">
          Sales Activities
        </span>
      </div>
      <div className="bg-white h-[100%] rounded-lg shadow-lg overflow-auto">
        <Table
          verticalSpacing={1}
          horizontalSpacing={1}
          withColumnBorders
          className="rounded-lg"
          fontSize={12}
        >
          <thead className="bg-[#446FAD] sticky top-0 z-10">
            <tr className="text-sm">
              <th className="!p-1 !text-white min-w-[200px] xl:min-w-[300px]">
                Branch
              </th>
              <th className="!p-1 !text-white min-w-[120px] !text-center flex items-center">
                <span className="flex-grow">Comment Count</span>
                <i
                  className="material-icons cursor-pointer self-end"
                  style={{ fontSize: "20px" }}
                  onClick={() =>
                    setSortConfig({
                      key: "TotalCommentCount",
                      direction:
                        sortConfig === null || sortConfig.direction === "desc"
                          ? sortConfig?.key === "TotalCommentCount"
                            ? "asc"
                            : "desc"
                          : "desc",
                    })
                  }
                >
                  {getIcon("TotalCommentCount", sortConfig)}
                </i>
              </th>
            </tr>
          </thead>
          <tbody>
            {/* Branch row */}
            {sortedTableData ? (
              sortedTableData.length > 0 ? (
                sortedTableData.map((data: ISalesActivity, index: number) => {
                  const jobTitle = data.jobTitle;
                  const branchKey = `branch-${data.Branch}`;

                  return (
                    <React.Fragment key={`Branch ${index}`}>
                      <tr className="hover:bg-stone-200 last:border-b-[1px]">
                        <td className="flex items-center gap-2">
                          <i
                            className={`material-icons cursor-pointer text-[1.3rem] rounded-md ${
                              data.TotalCommentCount > 0
                                ? "hover:bg-[#cacdd2]"
                                : ""
                            } `}
                            onClick={() => {
                              if (data.TotalCommentCount > 0) {
                                changeCollapseState(branchKey);
                              }
                            }}
                          >
                            {expanded[branchKey]
                              ? "keyboard_arrow_down"
                              : "keyboard_arrow_right"}
                          </i>
                          <span className="font-bold">{data.Branch}</span>
                        </td>
                        <td className="text-center font-bold">
                          {expanded[branchKey] ? null : data.TotalCommentCount}
                        </td>
                      </tr>

                      {/* JobTitle row */}
                      {expanded[branchKey] &&
                        Object.values(jobTitle).map(
                          (jobTitleData, index: number) => {
                            const comments = jobTitleData.comments;
                            const jobtitleKey = `jobtitle-${branchKey}${jobTitleData.JobTitle}`;

                            return (
                              <React.Fragment key={`Jobtitle ${index}`}>
                                <tr className="last:border-b-[1px] hover:bg-stone-200 font-semibold">
                                  <td className="flex items-center gap-2 ml-4">
                                    <i
                                      className="material-icons cursor-pointer text-[1.3rem] hover:bg-[#cacdd2] rounded-md"
                                      onClick={() => {
                                        changeCollapseState(jobtitleKey);
                                      }}
                                    >
                                      {expanded[jobtitleKey]
                                        ? "keyboard_arrow_down"
                                        : "keyboard_arrow_right"}
                                    </i>
                                    <span>{jobTitleData.JobTitle}</span>
                                  </td>
                                  <td className="text-center">
                                    {expanded[jobtitleKey]
                                      ? null
                                      : jobTitleData.TotalCommentCount}
                                  </td>
                                </tr>

                                {/* Comment Stage row */}
                                {expanded[jobtitleKey] &&
                                  Object.values(comments).map(
                                    (commentsData, index: number) => {
                                      const clients = commentsData.clients;
                                      const commentsKey = `comment-${branchKey}${commentsData.CommentStages}`;

                                      return (
                                        <React.Fragment
                                          key={`Comment ${index}`}
                                        >
                                          <tr className="last:border-b-[1px] hover:bg-stone-200 font-semibold">
                                            <td className="flex items-center gap-2 ml-8">
                                              <i
                                                className="material-icons cursor-pointer text-[1.3rem] hover:bg-[#cacdd2] rounded-md"
                                                onClick={() => {
                                                  changeCollapseState(
                                                    commentsKey
                                                  );
                                                }}
                                              >
                                                {expanded[commentsKey]
                                                  ? "keyboard_arrow_down"
                                                  : "keyboard_arrow_right"}
                                              </i>
                                              <span>
                                                {commentsData.CommentStages}
                                              </span>
                                            </td>
                                            <td className="text-center">
                                              {expanded[commentsKey]
                                                ? null
                                                : commentsData.TotalCommentCount}
                                            </td>
                                          </tr>
                                          {/* Client row */}
                                          {expanded[commentsKey] &&
                                            Object.values(clients).map(
                                              (clientsData, index: number) => {
                                                return (
                                                  <tr
                                                    className="last:border-b-[1px] hover:bg-stone-200"
                                                    key={`Client-${index}`}
                                                  >
                                                    <td className="flex items-center gap-2 ml-[4.2rem]">
                                                      <span>
                                                        {clientsData.ClientName}
                                                      </span>
                                                    </td>
                                                    <td className="text-center">
                                                      {
                                                        clientsData.TotalCommentCount
                                                      }
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                              </React.Fragment>
                            );
                          }
                        )}
                    </React.Fragment>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={2} className="h-32 text-center text-slate-600">
                    No data for selected filters
                  </td>{" "}
                </tr>
              )
            ) : (
              <tr className="h-32 text-xs text-slate-600 text-center">
                <td colSpan={2}>Loading Table...</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default SalesActivity;
