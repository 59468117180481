import { GetRequest } from "../plugins/https";

export const APIGetFinancialYTDComparisonLocations = (
  name: string,
  level: string
) => {
  return GetRequest(
    `/financial-comparison/branches?locationName=${name}&locationLevel=${level}`
  );
};

export const APIGetFinancialYTDData = (
  branch: string,
  year: string,
  filter: string,
  month: string
) => {
  // return GetRequest(`/financial-comparison/data?branch=${branch}`);
  return GetRequest(
    `/financial-comparison/data?branch=${branch}&year=${year}&filter=${filter}&month=${month}`
  );
};
