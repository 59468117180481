/*
 * Copyright (C) 2025 GritFeat. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { CloseButton, Select } from "@mantine/core";
import HomeButton from "../components/common/HomeButton";
import { CustomDatePicker } from "../components/common/CustomDatePicker";
import { useEffect, useMemo, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import SalesFunnel from "../components/modules/salesPipeline/SalesFunnnel";
import SalesActivity from "../components/modules/salesPipeline/SalesActivity";
import {
  APIFetchBDRData,
  APIFetchBranches,
  APIFetchBranchManagers,
  APIFetchKPIData,
  APIFetchRegionalSalesManagers,
  APIFetchRegions,
  APIFetchSalesActivityData,
  APIFetchSalesBucketData,
  APIFetchSalesFunnelData,
  APIFetchSalesManagers,
  APIFetchStageAccountsData,
} from "../api/sales-pipeline";
import moment from "moment";
import {
  IBDRRes,
  IBucketData,
  IKpiData,
  ISalesActivity,
  ISalesFunnelRes,
  IStageAccountRes,
} from "../types/salesPipeline";
import { getAggregatedData } from "../components/modules/salesPipeline/utils/helper";
import { sortByKeyDailyNumbers } from "../utils/helper/cellFormatter";
import { useDispatch } from "react-redux";
import { setLoading } from "../store/Filter/actions";
import { Loader } from "../components/common/Loader";
import { handleError } from "../utils/helper/handleError";
import SalesManagerSelector from "../components/modules/salesManager/SalesManagerSelector";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import KpiTable from "../components/modules/salesPipeline/KpiTable";
import BucketVisual from "../components/modules/salesPipeline/BucketVisual";
import { INVOICING_STAGE } from "../components/modules/salesPipeline/utils/constants";
import { useFetch } from "../utils/hooks/useFetch";

const initialState = {
  branch: "All",
  salesRegion: "All",
  regionalManager: "All",
  manager: { branchManager: "All", salesManager: "All" },
  levelBranch: "Company",
  level: "Company",
  area: null,
  subarea: null,
  region: null,
  district: null,
  timeline: null,
  dateRange: {
    startdate: moment().startOf("year").format("YYYY-MM-DD"),
    enddate: moment().format("YYYY-MM-DD"),
  },
};

const SalesPipeline = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure();
  const [isFunnelLoading, setIsFunnelLoading] = useState<boolean>(false);
  const [isKPITableLoading, setIsKpiTableLoading] = useState<boolean>(false);
  const [branchesList, setBranchesList] = useState<string[]>([]);
  const [salesRegionList, setSalesRegionList] = useState<string[]>([]);
  const [regionManagerList, setRegionManagerList] = useState<string[]>([]);
  const [branchManagerList, setBranchManagerList] = useState<string[]>([]);
  const [salesManagerList, setSalesManagerList] = useState<string[]>([]);
  const [areaList, setAreaList] = useState<string[]>([]);
  const [subAreaList, setSubAreaList] = useState<string[]>([]);
  const [regionsList, setRegionsList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [_level, setLevel] = useState<string>(initialState.level);
  const [hierarchy, setHierarchy] = useState({});
  const [areaSelected, setAreaSelected] = useState(initialState.area);
  const [subAreaSelected, setSubAreaSelected] = useState(initialState.subarea);
  const [regionSelected, setRegionSelected] = useState(initialState.region);
  const [districtSelected, setDistrictSelected] = useState(
    initialState.district
  );
  const [selectedBranch, setSelectedBranch] = useState<string>(
    initialState.branch
  );
  const [selectedSalesRegion, setSelectedSalesRegion] = useState<string>(
    initialState.salesRegion
  );
  const [selectedRegionManager, setSelectedRegionManager] = useState<string>(
    initialState.regionalManager
  );
  const [selectedLevelBranch, setSelectedLevelBranch] = useState<string>(
    initialState.levelBranch
  );
  const [selectedTimeline, setSelectedTimeline] = useState<string | null>(
    initialState.timeline
  );
  const [selectedDateRange, setSelectedDateRange] = useState(
    initialState.dateRange
  );
  const [selectedFilter, setSelectedFilter] = useState<{
    branchManager: string | null;
    salesManager: string | null;
  }>(initialState.manager);
  const [salesFunnelData, setSalesFunnelData] = useState<
    ISalesFunnelRes[] | null
  >(null);
  const [salesBucketData, setSalesBucketData] = useState<IBucketData[] | null>(
    null
  );
  const [kpiData, setKpiData] = useState<IKpiData[] | null>(null);
  const [bdrData, setBdrData] = useState<IBDRRes[] | null>(null);
  const [salesActivityData, setSalesActivityData] = useState<Record<
    string,
    ISalesActivity
  > | null>(null);
  const [stageAccounts, setStageAccounts] = useState<IStageAccountRes[] | null>(
    null
  );
  const [invoicingClients, setInvoiceClients] = useState<
    IStageAccountRes[] | null
  >(null);
  const isKpiView: boolean = params["*"]?.includes("kpi") || false;

  const selectedLocation = useMemo(
    () =>
      selectedBranch !== "All"
        ? selectedBranch
        : districtSelected ||
          regionSelected ||
          subAreaSelected ||
          areaSelected ||
          "All",
    [
      selectedBranch,
      districtSelected,
      regionSelected,
      subAreaSelected,
      areaSelected,
    ]
  );

  const selectedManager = useMemo(
    () => selectedFilter.branchManager ?? selectedFilter.salesManager ?? "All",
    [selectedFilter.branchManager, selectedFilter.salesManager]
  );

  //Fetch Sales Region Slicer
  useFetch(
    APIFetchRegions,
    [selectedLocation, selectedManager, selectedRegionManager],
    (data) =>
      setSalesRegionList(
        data.map((data: { salesRegion: string }) => data.salesRegion)
      ),
    [selectedLocation, selectedManager, selectedRegionManager]
  );

  //Fetch Region Sales Manager Slicer
  useFetch(
    APIFetchRegionalSalesManagers,
    [selectedLocation, selectedManager, selectedSalesRegion],
    (data) =>
      setRegionManagerList(
        data.map(
          (data: { RegionalSalesManager: string }) => data.RegionalSalesManager
        )
      ),
    [selectedLocation, selectedManager, selectedSalesRegion]
  );

  //Fetch Branch Slicer
  useFetch(
    APIFetchBranches,
    [
      districtSelected ||
        regionSelected ||
        subAreaSelected ||
        areaSelected ||
        "All",
      selectedSalesRegion,
      selectedRegionManager,
      selectedManager,
    ],
    (data) =>
      setBranchesList(data.map((d: { Branch: string }) => d.Branch).sort()),
    [
      areaSelected,
      subAreaSelected,
      regionSelected,
      districtSelected,
      selectedSalesRegion,
      selectedRegionManager,
      selectedFilter,
    ]
  );

  //Fetch Branch Manager Slicer
  useFetch(
    APIFetchBranchManagers,
    [selectedLocation, selectedSalesRegion, selectedRegionManager],
    (data) =>
      setBranchManagerList(
        data.map((d: { BranchManager: string }) => d.BranchManager).sort()
      ),
    [selectedLocation, selectedSalesRegion, selectedRegionManager]
  );

  //Fetch Sales Manager Slicer
  useFetch(
    APIFetchSalesManagers,
    [selectedLocation, selectedRegionManager, selectedSalesRegion],
    (data) =>
      setSalesManagerList(
        data.map((d: { SalesManager: string }) => d.SalesManager).sort()
      ),
    [selectedLocation, selectedRegionManager, selectedSalesRegion]
  );

  //Fetch sales activity table
  const fetchSalesActivity = async () => {
    try {
      dispatch(setLoading(true));
      const res = await APIFetchSalesActivityData(
        selectedLocation,
        selectedManager,
        selectedDateRange.startdate,
        selectedDateRange.enddate,
        selectedSalesRegion,
        selectedRegionManager
      );
      setSalesActivityData(getAggregatedData(res.data));
    } catch (error) {
      handleError("Error fetching sales activites data");
    } finally {
      dispatch(setLoading(false));
    }
  };

  //Fetch Sales prospect/Bucket visual data
  const fetchBucketData = async () => {
    try {
      const res = await APIFetchSalesBucketData(
        selectedLocation,
        selectedManager,
        selectedDateRange.startdate,
        selectedDateRange.enddate,
        selectedSalesRegion,
        selectedRegionManager
      );
      setSalesBucketData(res.data);
    } catch (error) {
      handleError("Error fetching sales bucket data");
    }
  };

  //Fetch Kpi table data
  const fetchKPIData = async () => {
    try {
      setIsKpiTableLoading(true);
      const res = await APIFetchKPIData(
        selectedLocation,
        selectedManager,
        selectedDateRange.startdate,
        selectedDateRange.enddate,
        selectedSalesRegion,
        selectedRegionManager
      );
      setKpiData(res.data);
    } catch (error) {
      handleError("Error fetching KPI data");
    } finally {
      setIsKpiTableLoading(false);
    }
  };

  //Fetch BDR Data
  useFetch(
    APIFetchBDRData,
    [selectedDateRange.startdate, selectedDateRange.enddate],
    (data) => setBdrData(data),
    [selectedDateRange]
  );

  //Fetch Sales funnel data
  const fetchSalesFunnel = async () => {
    try {
      setIsFunnelLoading(true);
      const res = await APIFetchSalesFunnelData(
        selectedLocation,
        selectedManager,
        selectedTimeline,
        selectedDateRange.startdate,
        selectedDateRange.enddate,
        selectedSalesRegion,
        selectedRegionManager
      );
      const sumedData = res.data.reduce(
        (acc: Record<string, any>, element: Record<string, any>) => {
          const key = `${element["CommentStages"]}`;
          if (!acc[key]) {
            acc[key] = {
              CommentStages: element.CommentStages,
              Branch: element.Branch,
              Manager: element.Manager,
              StageOrder: element.StageOrder,
              TotalClientCount: 0,
            };
          }
          acc[key].TotalClientCount += element.ClientCount || 0;
          return acc;
        },
        {}
      );

      setSalesFunnelData(
        sortByKeyDailyNumbers(Object.values(sumedData ?? {}), "StageOrder")
      );
    } catch (error) {
      handleError("Error fetching sales funnel");
    } finally {
      setIsFunnelLoading(false);
    }
  };

  const fetchStageAccountsData = async () => {
    const res = await APIFetchStageAccountsData(
      selectedLocation,
      selectedManager,
      selectedTimeline,
      selectedDateRange.startdate,
      selectedDateRange.enddate,
      selectedSalesRegion,
      selectedRegionManager
    );
    setStageAccounts(res.data);
    const invoiceStageClients = res.data.filter(
      (data: IStageAccountRes) => data.NextStage === INVOICING_STAGE
    );
    setInvoiceClients(sortByKeyDailyNumbers(invoiceStageClients, "ClientName"));
  };

  useEffect(() => {
    document.title = "Sales Pipeline Overview";
  }, []);

  useEffect(() => {
    fetchSalesActivity();
    fetchBucketData();
    fetchKPIData();
  }, [
    selectedDateRange,
    selectedManager,
    selectedLocation,
    selectedSalesRegion,
    selectedRegionManager,
  ]);

  useEffect(() => {
    fetchSalesFunnel();
    fetchStageAccountsData();
  }, [
    selectedManager,
    selectedTimeline,
    selectedDateRange,
    selectedLocation,
    selectedSalesRegion,
    selectedRegionManager,
  ]);

  const resetFilters = () => {
    if (isKpiView) {
      navigate(-1);
    } else {
      setSelectedFilter(initialState.manager);
      setSelectedLevelBranch(initialState.levelBranch);
      setAreaSelected(initialState.area);
      setSubAreaSelected(initialState.subarea);
      setRegionSelected(initialState.region);
      setDistrictSelected(initialState.district);
      setSelectedBranch(initialState.branch);
      setSelectedSalesRegion(initialState.salesRegion);
      setSelectedRegionManager(initialState.regionalManager);
      setSelectedTimeline(initialState.timeline);
      setSelectedDateRange(initialState.dateRange);
    }
  };

  return (
    <main className="h-screen w-screen overflow-hidden flex flex-col bg-[#E6F1F1] gap-1 ">
      <Loader />
      <header className="flex justify-between bg-[#cddfdb] items-center">
        <HomeButton resetFilters={resetFilters} />
        <div className="pt-1 pb-3  w-screen  text-blue-900 text-lg font-bold pl-16">
          Sales Pipeline Overview Report
        </div>
      </header>
      <section className="flex flex-col px-1 md:max-2xl:px-4 2xl:px-12 gap-2 overflow-y-auto">
        <div className="h-[160px] relative">
          {!isKpiView && (
            <Link to="kpi">
              <button className="absolute right-0 text-[0.7rem] font-semibold p-2 w-[120px] rounded-md shadow-lg bg-[#3D788B] text-white hover:bg-[#305967]">
                Sales Metrics
              </button>
            </Link>
          )}
          <SalesManagerSelector
            setLevel={setLevel}
            hierarchy={hierarchy}
            setHierarchy={setHierarchy}
            areaList={areaList}
            setAreaList={setAreaList}
            subAreaList={subAreaList}
            setSubAreaList={setSubAreaList}
            regionsList={regionsList}
            setRegionsList={setRegionsList}
            districtList={districtList}
            setDistrictList={setDistrictList}
            selectedLevelBranch={selectedLevelBranch}
            setSelectedLevelBranch={setSelectedLevelBranch}
            areaSelected={areaSelected}
            setAreaSelected={setAreaSelected}
            subAreaSelected={subAreaSelected}
            setSubAreaSelected={setSubAreaSelected}
            regionSelected={regionSelected}
            setRegionSelected={setRegionSelected}
            setDistrictSelected={setDistrictSelected}
            setSelectedBranch={setSelectedBranch}
          />
        </div>
        <div className="filters flex justify-end gap-2 items-center">
          <Select
            label="Sales Region"
            data={["All", ...salesRegionList]}
            size="xs"
            className="w-32 lg:w-40"
            value={selectedSalesRegion}
            onChange={(val: string) => {
              setSelectedSalesRegion(val);
              setSelectedFilter((prev: any) => ({
                ...prev,
                branchManager: val === "All" ? "All" : null,
              }));
            }}
            searchable
          />
          <Select
            label="Regional Sales Manager"
            data={["All", ...regionManagerList]}
            size="xs"
            className="w-36 lg:w-40"
            value={selectedRegionManager}
            onChange={(val: string) => {
              setSelectedRegionManager(val);
              setSelectedFilter((prev: any) => ({
                ...prev,
                branchManager: val === "All" ? "All" : null,
              }));
            }}
            searchable
          />
          <Select
            label="Branch"
            data={["All", ...branchesList]}
            size="xs"
            className="w-32 lg:w-40"
            value={selectedBranch}
            onChange={(val: string) => {
              setSelectedBranch(val);
              setSelectedFilter({
                branchManager: "All",
                salesManager: "All",
              });
              setSelectedRegionManager("All");
              setSelectedSalesRegion("All");
            }}
            searchable
          />

          <Select
            label="Branch Manager"
            data={["All", ...branchManagerList]}
            placeholder="None"
            size="xs"
            className="w-32 lg:w-40"
            value={selectedFilter.branchManager}
            onChange={(val: string) => {
              setSelectedFilter((prev: any) => ({
                ...prev,
                branchManager: val,
                salesManager: val === "All" ? "All" : null,
              }));
              setSelectedRegionManager("All");
              setSelectedSalesRegion("All");
            }}
            searchable
          />

          <Select
            label="Sales Manager"
            data={["All", ...salesManagerList]}
            placeholder="None"
            size="xs"
            className="w-32 lg:w-40"
            value={selectedFilter.salesManager}
            onChange={(val: string) => {
              setSelectedFilter((prev: any) => ({
                ...prev,
                salesManager: val,
                branchManager: val === "All" ? "All" : null,
              }));
              setSelectedRegionManager("All");
              setSelectedSalesRegion("All");
            }}
            searchable
          />
          <div className="relative">
            <div>
              <Select
                id="redeployment-date"
                className="w-[12rem] 2xl:w-[14rem] "
                size="xs"
                data={[]}
                label="Date"
                onClick={() => {
                  opened ? close() : open();
                }}
                placeholder={
                  selectedDateRange.startdate !== "" &&
                  selectedDateRange.enddate !== ""
                    ? selectedDateRange.startdate +
                      "  to  " +
                      selectedDateRange.enddate
                    : "All"
                }
              />
              {selectedDateRange.startdate !== "" &&
                selectedDateRange.enddate !== "" && (
                  <div
                    className=" absolute right-2 top-7 bg-[#fff] rounded-md cursor-pointer"
                    onClick={() => setSelectedDateRange(initialState.dateRange)}
                  >
                    <CloseButton />
                  </div>
                )}
            </div>
            {opened && (
              <div className="absolute z-20 right-0">
                <CustomDatePicker
                  close={close}
                  selectedDate={selectedDateRange}
                  setSelectedDate={setSelectedDateRange}
                />
              </div>
            )}
          </div>
        </div>
        <Routes>
          <Route
            element={
              <div className="flex flex-col lg:flex-row lg:gap-6 gap-16 h-[70vh] p-2 lg:pb-4">
                <SalesFunnel
                  funnelData={salesFunnelData}
                  selectedTimeline={selectedTimeline}
                  setSelectedTimeline={setSelectedTimeline}
                  selectedDateRange={selectedDateRange}
                  stageAccounts={stageAccounts}
                  invoicingClients={invoicingClients}
                  loading={isFunnelLoading}
                />
                <BucketVisual
                  bucketData={salesBucketData}
                  selectedDateRange={selectedDateRange}
                />
                <SalesActivity tableData={salesActivityData} />
              </div>
            }
            path="/"
          />
          <Route
            element={
              <KpiTable
                kpiData={kpiData}
                bdrData={bdrData}
                loading={isKPITableLoading}
                invoicingClients={invoicingClients}
              />
            }
            path={"/kpi"}
          />
        </Routes>
      </section>
    </main>
  );
};

export default SalesPipeline;
