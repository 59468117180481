export const DN_BRANCHES_TOP = [
  {
    label: "Ascend Staffing",
  },
];

export const DN_BRANCHES_MID = [
  {
    label: "Art's",
  },
  {
    label: "Kia's",
  },
];

export const DN_BRANCHES_LOW = [
  {
    label: "AZ NV CA (Dolly)",
  },
  {
    label: "TX NM GA SC (Alex)",
  },
  {
    label: "TX AL LS (Chris)",
  },
  {
    label: "UT ID CO (Jake)",
  },
  {
    label: "UT IA IL KS (Tyler)",
  },
  {
    label: "WA IA NB (Lu Ann)",
  },
];

export const DAY_OF_THE_WEEK = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
